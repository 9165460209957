/**
 * Component for our Graph on a combination of
 * Refunds + Store Credit + Exchanges.
 * 
 * @component
 */

import React from "react";
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Spin,
  message,
  Tooltip,
  Icon,
} from "antd";
import axios from "axios";
import moment from "moment";
import { Area } from "@ant-design/charts";
import Upgrade from "../../../../../components/Upgrade";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";

const { RangePicker } = DatePicker;

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: undefined,
      data: [],
    };
  }

  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }

  async handleSubmit() {
    this.setState({
      loading: true,
    });


    if (!this.props.startDate || !this.props.endDate) {
      this.setState({
        loading: false,
      });
      return message.error("Select a start and end date.", 4);
    }
    try {
      const response = await axios.post(
        "/api/analytics/compare-overview-by-date?",
        {
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        }
      );

      if (response.data.error) {
        return message.error("Error fetching analytics data from server.", 4);
      }

      return this.setState({
        data: response.data.data,
        loading: false,
      });
    } catch (err) {
      message.error("Error fetching analytics data.", 4);
    }
  }
  onChange(dates, dateStrings) {
    // syntax: function(dates: [moment, moment], dateStrings: [string, string])
    this.props.updateDate(dateStrings)
    this.setState(
      {
        checkboxDisabled: false,
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  render() {
    const { date } = this.state;
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      const tooLate = date && current.diff(date, "days") > 45;
      const tooEarly = date && date.diff(current, "days") > 45;
      return tooEarly || tooLate;
    };

    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="pageTitle">
              <Tooltip title="Shows a comparison for the number of Exchange, Refund and Store-Credit items requested per day.">
                <Icon
                  style={{ fontSize: "16px", alignItems: "center" }}
                  type="info-circle"
                />
              </Tooltip>{" "}
              Overview
            </h1>
          </Col>
        </Row>
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Spin spinning={this.state.loading}>
                  <Row type="flex" justify="center" align="middle">
                    <RangePicker
                      defaultValue={[moment(this.props.startDate), moment(this.props.endDate)]}
                      ranges={{
                        Today: [moment(), moment()],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      onChange={this.onChange.bind(this)}
                      disabledDate={disabledDate}
                      onCalendarChange={(dateArray) => {
                        const endDateSelected = dateArray[1] != null;
                        if (endDateSelected) {
                          return this.setState({
                            date: undefined,
                          });
                        }

                        const startDateSelected = dateArray[0];
                        if (startDateSelected) {
                          this.setState({
                            date: dateArray[0],
                          });
                        }
                      }}
                    />
                  </Row>
                </Spin>
              </Col>
            </Row>

            <Divider style={{ marginTop: 30, marginBottom: 30 }}>
              Select a start and end date
            </Divider>

            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 20 }}
            >
              <Col span={24}>
                <Area
                  data={this.state.data}
                  xField="date"
                  yField="volume"
                  xAxis={{ tickCount: 5 }}
                  smooth={true}
                  seriesField={"type"}
                  color={["#6897a7", "#8bc0d6", "#60d7a7", "#0000FF"]}
                  xAxis={{
                    type: "time",
                    mask: "YYYY-MM-DD",
                  }}
                  yAxis={{
                    label: {
                      formatter: function formatter(v) {
                        return ""
                          .concat(v)
                          .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                            return "".concat(s, ",");
                          });
                      },
                    },
                    tickInterval: 1,
                  }}
                  legend={{ position: "top" }}
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
              <Upgrade
                title="Build advanced rules & scenarios for your returns."
                description="Upgrade to a higher plan to tailor returns to your business processes and goals based on order data and customer actions."
                faqLink="https://support.apphub.com/en/articles/6324885-overview-of-advanced-rules"
                videoId="DwAxUUS7A0o"
              />
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Overview;
