/**
 * Component for our Exchange Orders to let the
 * user create and view them. 
 * 
 * @component
 */

import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm,
  message,
  Checkbox,
} from "antd";
import axios from "axios"
import VideoPopup from "../../../../components/VideoPopup";
 
class ReturnDetailsExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCreateExchangeOrder: false,
      exchangeOrderRestockAll: false,
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }
      const { exchangeOrderRestock } = response.data;
      this.setState({
        exchangeOrderRestockAll: exchangeOrderRestock,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }


  toggleRestockAll = () => {
    this.setState({
      exchangeOrderRestockAll: !this.state.exchangeOrderRestockAll,
    });
  }

  createExchangeOrder = async(returnObject, orderType) => {
    try {
      this.setState({
        loadingCreateExchangeOrder: true,
      });

      const { exchangeOrderRestockAll } = this.state;

      const response = await axios.post("/api/returns/exchangeOrder", {
        returnId: returnObject.id,
        orderType,
        exchangeOrderRestockAll,
      });

      if (response.data.status === "error") {
        this.setState({
          loadingCreateExchangeOrder: false,
        });
        this.props.handleGetReturn()
        return message.error(response.data.error, 10);
      }
      this.props.handleGetReturn()

      this.setState({
        loadingCreateExchangeOrder: false,
      });

      return message.success("Exchange order created successfully.", 5);

    } catch(err) {
      this.setState({
        loadingCreateExchangeOrder: false,
      });
      return message.error("Error while creating an exchange order. Try again.", 4);
    }
  }
  render() {
    return(
      <React.Fragment>
        {
          !this.props.featureExchangeOrders ?
            (
              <React.Fragment>
                <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
                  <Col span={9}>
                    <strong>Exchange</strong>
                  </Col>
                  <Col span={15}>
                    <Tooltip
                      title="Exchange orders are available on the Premium-Plan"
                      placement="bottom"
                    >
                      <Button size="small" disabled={true}>Create Exchange-Order</Button>
                    </Tooltip> <Tooltip
                      title="Exchange orders are available on the Premium-Plan"
                      placement="bottom"
                    >
                      <Button size="small" disabled={true}>Create Draft-Order</Button>
                    </Tooltip> <VideoPopup videoId="yYHrl4TBGQQ"/>
                  </Col>
                </Row>
              </React.Fragment>
            ) :
            (
              this.props.returnObject.exchangeOrderType ?
                (
                  <React.Fragment>
                    <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
                      <Col span={9}>
                        <strong>Exchange</strong>
                      </Col>
                      <Col span={15}>
                        <Button
                          size="small"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            this.props.returnObject.exchangeOrderType === "draft" ?
                              (this.props.storeShopifyUrl + "/admin/draft_orders/" +  this.props.returnObject.exchangeOrderId) :
                              (this.props.storeShopifyUrl + "/admin/orders/" +  this.props.returnObject.exchangeOrderId)
                          }
                        >
                  View Exchange Order on Shopify
                        </Button>
                      </Col>
                    </Row>
                  </React.Fragment>
                )
                : (
                  this.props.orderIncludesExchangeItem ?
                    (
                      <React.Fragment>
                        <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
                          <Col span={9}>
                            <strong>Exchange</strong>
                          </Col>
                          <Col span={15}>
                            <Popconfirm
                              title={
                                <React.Fragment>
                                  <p>
                              Create an exchange order and mark it as paid based on the exchange-items in this return?
                                  </p>
                                  <p>
                                    <Checkbox
                                      checked={this.state.exchangeOrderRestockAll}
                                      onChange={this.toggleRestockAll.bind(this)}
                                    >
                                  Restock original items
                                    </Checkbox>
                                  </p>
                                </React.Fragment>
                              }
                              onConfirm={() => this.createExchangeOrder( this.props.returnObject, "order")}
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                            >
                              <Button 
                                size="small" 
                                disabled={this.state.loadingCreateExchangeOrder}
                                loading={this.state.loadingCreateExchangeOrder}
                              >
                          Create Exchange-Order
                              </Button>
                            </Popconfirm>
                            <Popconfirm
                              title={
                                <React.Fragment>
                                  <p>
                                Create a draft order that can be edited afterwards based on the exchange-items in this return?
                                  </p>
                                  <p>
                                    <Checkbox
                                      checked={this.state.exchangeOrderRestockAll}
                                      onChange={this.toggleRestockAll.bind(this)}
                                    >
                                  Restock original items
                                    </Checkbox>
                                  </p>
                                </React.Fragment>
                              }
                              onConfirm={() => this.createExchangeOrder( this.props.returnObject, "draft")}
                              okText="Yes"
                              cancelText="No"
                              placement="bottom"
                            >
                              <Button
                                size="small"
                                disabled={this.state.loadingCreateExchangeOrder}
                                loading={this.state.loadingCreateExchangeOrder}
                              >
                          Create Draft-Order
                              </Button>
                            </Popconfirm>
                            <VideoPopup videoId="yYHrl4TBGQQ"/>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ) :
                    (
                      <React.Fragment>
                        <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
                          <Col span={9}>
                            <strong>Exchange</strong>             
                          </Col>
                          <Col span={15}>
                            <Tooltip
                              title="There are no exchange items in this order"
                              placement="bottom"
                            >
                              <Button size="small" disabled={true}>Create Exchange-Order</Button>
                            </Tooltip> <Tooltip
                              title="There are no exchange items in this order"
                              placement="bottom"
                            >
                              <Button size="small" disabled={true}>Create Draft-Order</Button>
                            </Tooltip> <VideoPopup videoId="yYHrl4TBGQQ"/>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                )
            )
        }
      </React.Fragment>
    )
  }
}
 
export default ReturnDetailsExchange;