/**
 * Component to display our Audit-Log feature in
 * the dashboard with all its Activities.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Table, message, DatePicker, Row, Col, Spin, Icon } from "antd";
import moment from "moment";
import Upgrade from "../../components/Upgrade";
import { SubscriptionContext } from "../../contexts/SubscriptionContext";
const { RangePicker } = DatePicker;

const prettyType = {
  system: "System",
  refund: "Refund",
  return: "Return",
  note: "Note",
  order: "Order",
  email: "Email",
  label: "Label",
  shipment: "Shipment",
};

const prettySubtype = {
  analytics: "Analytics",
  "api-key": "API Key",
  "brand-settings": "Brand Settings",
  "email-settings": "Email Settings",
  "return-settings": "Return Settings",
  translations: "Translations",
  "store-credit": "Store Credit",
  payment: "Payment",
  "draft-order": "Draft Order",
  "exchange-order": "Exchange Order",
  Pending: "Pending",
  Approved: "Approved",
  Shipped: "Shipped",
  Received: "Received",
  Resolved: "Resolved",
  Rejected: "Rejected",
  "tracking-shipped": "Tracking Status: Shipped",
  "tracking-delivered": "Tracking Status: Delivered",
};
const prettyEmailSubtypes = {
  Pending: "Pending",
  Approved: "Approved",
  ApprovedLabel: "Approved Label",
  ApprovedLabelError: "Approved Label Error",
  ApprovedLabelManual: "Approved Manual Label",
  Shipped: "Shipped",
  Received: "Received",
  Resolved: "Resolved",
  Rejected: "Rejected",
  StoreCredit: "Store-Credit",
};
const prettyAction = {
  created: "Created",
  viewed: "Viewed",
  updated: "Updated",
  sent: "Sent",
  bounced: "Bounced",
};
const columns = [
  {
    title: "Type",
    dataIndex: "type",
    render: (text) => <span>{prettyType[text]}</span>,
  },
  {
    title: "Subtype",
    dataIndex: "subtype",
    render: (text, record) => {
      let displayText;
      if (record.type === "email") {
        displayText = prettyEmailSubtypes[text];
      } else if (text) {
        displayText = prettySubtype[text];
      } else {
        displayText = "---";
      }
      return <span>{displayText}</span>;
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (text) => <span>{prettyAction[text]}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => {
      if (text === "success") {
        return (
          <span>
            <Icon type="check-circle" style={{ color: "green" }} />
          </span>
        );
      } else if (text === "failed") {
        return (
          <span>
            <Icon type="close-circle" style={{ color: "red" }} />
          </span>
        );
      }
    },
  },
  {
    title: "Message",
    dataIndex: "message",
    render: (text) => {
      text = text ? text : "---";
      return <span>{text}</span>;
    },
  },
  {
    title: "Return",
    dataIndex: "Return.returnNumber",
    render: (text) => {
      text = text ? text : "---";
      return <span>{text}</span>;
    },
  },
  {
    title: "Order",
    dataIndex: "Return.orderName",
    render: (text) => {
      text = text ? text : "---";
      return <span>{text}</span>;
    },
  },
  {
    title: "IP",
    dataIndex: "ip",
    render: (text) => {
      text = text ? text : "---";
      return <span>{text}</span>;
    },
  },
  {
    title: "Timestamp",
    dataIndex: "createdAt",
    render: (text) => {
      return <span>{moment(text).format("MMM DD, YYYY hh:mm:ss A")}</span>;
    },
  },
];

class AuditLog extends React.Component {
  static contextType = SubscriptionContext;

  state = {
    data: [],
    loading: true,
    startDate: null,
    endDate: null,
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10,
  };

  onChange(dates, dateStrings) {
    this.setState(
      {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        currentPage: 1, // to reset the active page
      },
      () => {
        this.fetchActivityLog();
      }
    );
  }
  triggerPagination = async (paginationObject) => {
    const currentPage = paginationObject.current;
    const { pageSize } = paginationObject;
    this.setState(
      {
        currentPage,
        pageSize,
      },
      () => {
        this.fetchActivityLog();
      }
    );
  };

  async fetchActivityLog() {
    const { currentPage, pageSize, startDate, endDate } = this.state;
    try {
      const response = await axios.get("/api/activities?sort=desc", {
        params: {
          currentPage,
          pageSize,
          startDate,
          endDate,
        },
      });

      if (response.data == null) {
        return;
      }
      this.setState({
        data: response.data.activities,
        totalRecords: response.data.totalRecords,
      });
      this.setState({ loading: false });
    } catch (err) {
      message.error("Error retrieving your audit log. Try again.", 5);
    }
  }

  async componentDidMount() {
    this.fetchActivityLog();
  }

  render() {
    const { loading, currentPage, totalRecords, data } = this.state;

    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="pageTitle">Audit Log</h1>
          </Col>
        </Row>
        {
          this.context && !this.context.featureAuditLog
            ? (
              <Upgrade
                title={<span>Identify workflow-errors early on<br />& complement your compliance framework within the company.</span>}
                description="Upgrade to the Plus Plan to access the Audit-Log."
              />
            )
            : (
              <React.Fragment>
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <Spin spinning={loading}>
                      <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        <RangePicker
                          ranges={{
                            Today: [moment(), moment()],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                          onChange={this.onChange.bind(this)}
                        />
                      </Row>
                    </Spin>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(_, index) => index}
                  pagination={{
                    simple: true,
                    defaultCurrent: currentPage,
                    total: totalRecords,
                  }}
                  onChange={(e) => this.triggerPagination(e)}
                />
              </React.Fragment>
            )
        }
      </div>
    );
  }
}

export default AuditLog;
