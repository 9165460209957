/**
 * Component for our Manual-Returns feature
 * ("Multichannel Return") that is only
 * available to selected accounts. Adds a button
 * "Edit manual RMA" to our Return details for each
 * Return.
 * 
 * @component
 */

import React, { Component } from "react";
import { Drawer, Button } from "antd";
import ReturnDetailsManualReturnForm from "./ReturnDetailsManualReturnForm";

class ReturnDetailsManualReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false
    };
  }

  showDrawer() {
    this.setState({
      drawerVisible: true
    });
  }

    hideDrawer = () => {
      this.setState({
        drawerVisible: false
      });
    }

    render() {
      return(
        <React.Fragment>
          <Button onClick={this.showDrawer.bind(this)} style={{marginLeft: 8}}>Edit manual RMA</Button>
          {
            this.state.drawerVisible
              ? (
                <Drawer
                  title="Edit Multichannel-RMA"
                  onClose={this.hideDrawer}
                  visible={this.state.drawerVisible}
                  width="40%"
                >
                  <ReturnDetailsManualReturnForm
                    hideDrawer={this.hideDrawer.bind(this)}
                    handleGetReturn={this.handleGetReturn}
                    returnObject={this.props.returnObject}
                  />
                </Drawer>
              )
              : null
          }
        </React.Fragment>
      )
    }
}

export default ReturnDetailsManualReturn;