/**
 * Component for our main Analytics Dashboard displaying
 * different KPIs on a Return- or Item-Level.
 * 
 * @component
 */

import React from "react";
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Spin,
  Card,
  Checkbox,
  Tooltip,
  Icon,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;

const pluralize = (word, count) => count === 1 ? word : `${word}s`;

/**
 * Formats seconds into days and hours
 * @param {number | string} seconds 
 * @returns {string} Formatted time in days and hours
 */
const formatTime = (seconds) => {
  if(Number.isNaN(seconds)) {
    return seconds;
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);

  if(!days) {
    return `${hours} ${pluralize('hour', hours)}`;
  }

  if(!hours) {
    return `${days} ${pluralize('day', days)}`;
  }

  return `${days} ${pluralize('day', days)} + ${hours} ${pluralize('hour', hours)}`;
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: undefined,
      startDate: moment().subtract(1, "month"),
      endDate: moment(),
      adjustForOrderDate: false,
      includeOpenOrders: true,
      checkboxDisabled: false,
      numberOfTotalReturns: "-",
      numberOfTotalProducts: "-",
      averageProductsPerReturn: "-",
      totalReturnValue: "-",
      averageReturnValue: "-",
      numberOfOrders: "-",
      returnRate: "-",
      currency: "-",
      numberOfCompletedReturns: "-",
      valueOfCompletedReturns: "-",
      numberOfRefundToOriginal: "-",
      valueOfRefundToOriginal: "-",
      rateOfRefundToOriginal: "-",
      numberOfRefundToExchange: "-",
      valueOfRefundToExchange: "-",
      rateOfRefundToExchange: "-",
      numberOfRefundToCredit: "-",
      valueOfRefundToCredit: "-",
      rateOfRefundToCredit: "-",
      numberOfAdvancedExchange: "-",
      valueOfAdvancedExchange: "-",
      rateOfAdvancedExchange: "-",
      averageTimeToReturn: "-",
      shortestTimeToReturn: "-",
      longestTimeToReturn: "-",
    };
  }

  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }
  async handleSubmit() {
    this.setState({
      loading: true,
    });

    const {
      startDate,
      endDate,
      adjustForOrderDate,
      includeOpenOrders,
    } = this.state;

    if (!startDate || !endDate) {
      this.setState({
        loading: false,
      });
      return message.error("Select a start and end date.", 4);
    }
    try {
      const response = await axios.post("/api/analytics", {
        startDate,
        endDate,
        adjustForOrderDate,
        includeOpenOrders,
      });

      if (response.data.error) {
        return message.error("Error fetching analytics data from server.", 4);
      }

      return this.setState({
        ...response.data,
        loading: false,
      });
    } catch (err) {
      message.error("Error fetching analytics data.", 4);
    }
  }

  onChange(dates, dateStrings) {
    // syntax: function(dates: [moment, moment], dateStrings: [string, string])
    this.setState(
      {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        checkboxDisabled: false,
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  toggleAdjustToOrderDate() {
    this.setState(
      {
        adjustForOrderDate: !this.state.adjustForOrderDate,
      },
      () => {
        const validDateEntered =
          this.state.startDate != null && this.state.endDate != null;

        if (validDateEntered) {
          this.handleSubmit();
        }
      }
    );
  }

  toggleIncludeOpenOrders() {
    this.setState(
      {
        includeOpenOrders: !this.state.includeOpenOrders,
      },
      () => {
        const validDateEntered =
          this.state.startDate != null && this.state.endDate != null;

        if (validDateEntered) {
          this.handleSubmit();
        }
      }
    );
  }

  render() {
    const { date } = this.state;
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      const tooLate = date && current.diff(date, "days") > 45;
      const tooEarly = date && date.diff(current, "days") > 45;
      return tooEarly || tooLate;
    };

    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="pageTitle">Analytics Dashboard</h1>
          </Col>
        </Row>

        <Row type="flex" justify="center" align="middle">
          <Col>
            <Spin spinning={this.state.loading} indicator={<span> </span>}>
              <Row type="flex" justify="center" align="middle">
                <RangePicker
                  defaultValue={[this.state.startDate, this.state.endDate]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month"),
                      moment().subtract(1, "month").endOf("month"),
                    ],
                  }}
                  onChange={this.onChange.bind(this)}
                  disabledDate={disabledDate}
                  onCalendarChange={(dateArray) => {
                    const endDateSelected = dateArray[1] != null;
                    if (endDateSelected) {
                      return this.setState({
                        date: undefined,
                      });
                    }

                    const startDateSelected = dateArray[0];
                    if (startDateSelected) {
                      this.setState({
                        date: dateArray[0],
                      });
                    }
                  }}
                />
              </Row>
              <Row type="flex" justify="center" align="middle" style={{ paddingTop: 5 }}>
                <Checkbox
                  onChange={this.toggleAdjustToOrderDate.bind(this)}
                  checked={this.state.adjustForOrderDate}
                  disabled={this.state.checkboxDisabled}
                >
                  Adjust for Order Date
                  <Tooltip title="Include returns only for orders with an order date within the specified timeframe. Otherwise all returns for the specified timeframe will be included.">
                    {" "}
                    <Icon type="info-circle" />
                  </Tooltip>
                </Checkbox>
                <Checkbox
                  onChange={this.toggleIncludeOpenOrders.bind(this)}
                  checked={this.state.includeOpenOrders}
                  disabled={this.state.checkboxDisabled}
                >
                  Include Open Orders
                </Checkbox>
              </Row>
            </Spin>
          </Col>
        </Row>

        <Spin
          spinning={this.state.loading}
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={10} style={{ marginTop: "20px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Return Rate"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of orders with a subsequent return divided by the number of orders."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.returnRate}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Return Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <a href="/dashboard/analytics/trends/return-volume">Graph</a>
                    {" "}
                    <Tooltip
                      placement="bottom"
                      title="Number of Returns created."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfTotalReturns}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Order Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of Orders received."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfOrders}
              </Card>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Return Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of returned items."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.totalReturnValue} {this.state.currency}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Average return value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of returned items divided by the number of returns."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.averageReturnValue} {this.state.currency}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Completed Return Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of returned items that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.valueOfCompletedReturns} {this.state.currency}
              </Card>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Returned Items"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items returned."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfTotalProducts}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Average items per return"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items returned divided by the number of returns."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.averageProductsPerReturn}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Completed Return Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of returns that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfCompletedReturns}
              </Card>
            </Col>
          </Row>

          <Divider style={{ marginTop: 20, marginBottom: 20 }}>
            Item based Metrics
          </Divider>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Refund Rate"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Refund divided by number of items returned."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.rateOfRefundToOriginal}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Refund Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <a href="/dashboard/analytics/trends/refund-volume">Graph</a>
                    {" "}
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Refund."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfRefundToOriginal}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Refund Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of items selected for Refund."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.valueOfRefundToOriginal} {this.state.currency}
              </Card>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Exchange Rate"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Exchange divided by number of items returned."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.rateOfRefundToExchange}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Exchange Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <a href="/dashboard/analytics/trends/exchange-volume">Graph</a>
                    {" "}
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Exchange."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfRefundToExchange}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Exchange Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of items selected for Exchange."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.valueOfRefundToExchange} {this.state.currency}
              </Card>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Store Credit Rate"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Store-Credit divided by number of items returned."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.rateOfRefundToCredit}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Store Credit Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <a href="/dashboard/analytics/trends/store-credit-volume">Graph</a>
                    {" "}
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Store-Credit."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfRefundToCredit}
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Store Credit Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Value of items selected for Store-Credit."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.valueOfRefundToCredit} {this.state.currency}
              </Card>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Advanced Exchange Rate"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Advanced-Exchange divided by number of items returned."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.rateOfAdvancedExchange}
              </Card>
            </Col>

            <Col span={8}>
              <Card
                title="Advance Exchange Volume"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Link to="/dashboard/analytics/trends/advanced-exchange-volume">Graph </Link>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Advance-Exchange."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.numberOfAdvancedExchange}
              </Card>
            </Col>

            <Col span={8}>
              <Card
                title="Advanced Exchange Value"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Number of items selected for Advanced-Exchange."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {this.state.valueOfAdvancedExchange} {this.state.currency}
              </Card>
            </Col>

          </Row>
          <Row gutter={10} style={{ marginTop: "10px", textAlign: "center" }}>
            <Col span={8}>
              <Card
                title="Average Time-to-Return"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Avareage time from Order Date to Return Requested date."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {formatTime(this.state.averageTimeToReturn)}
              </Card>
            </Col>

            <Col span={8}>
              <Card
                title="Shortest Time-to-Return"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Shortest time from Order Date to Return Requested date."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {formatTime(this.state.shortestTimeToReturn)}
              </Card>
            </Col>

            <Col span={8}>
              <Card
                title="Longest Time-to-Return"
                bordered={true}
                hoverable={true}
                extra={
                  <React.Fragment>
                    <Tooltip
                      placement="bottom"
                      title="Longest time from Order Date to Return Requested date."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </React.Fragment>
                }
              >
                {formatTime(this.state.longestTimeToReturn)}
              </Card>
            </Col>

          </Row>
        </Spin>

        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ paddingTop: 20 }}
        >
          <Col>
            <h3>
              Want to see another Metric in the dashboard? Let us know{" "}
              <a
                href="mailto:hello@richcommerce.co?subject=Please%20add%20this%20Number%20to%20the%20dashboard"
                target="_blank"
              >
                here
              </a>
              .
            </h3>
          </Col>
        </Row>

        <Divider style={{ marginTop: 30, marginBottom: 30 }} />

        <Row type="flex" justify="center" align="top">
          <Col>
            <p>
              <Icon type="exclamation-circle" /> If you encounter issues on this
              Analytics-Page make sure to disable any ad-blockers in your browser.
            </p>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}

export default Dashboard;
