/**
 * Component for user's Subscription with Chargebee.
 * 
 * @component
 */

import React, { Component } from 'react';
import { Row, Col, Button, Alert, Statistic, Card } from 'antd';
import axios from "axios";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";

class AccountCustom extends Component {

  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      chargebee: undefined,
    };
  }

  async componentDidMount () {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    script.async = true;
    document.body.appendChild(script);
    let siteName = "rich";

    script.onload = () => this.setState({
      chargebee: window.Chargebee.init({
        site: siteName
      })
    });
  }

  handleCustomerPortal = async (e) => {
    e.preventDefault();

    let response = await axios.post("/api/integrations/chargebee/session");
    this.state.chargebee.setPortalSession(response.data);

    this.state.chargebee.createChargebeePortal().open({
      visit() {
      }
    });
  }

  render() {
    return(
      <React.Fragment>
        {
          !this.context.directBilling
            ? null
            : (
              <React.Fragment>
                <Row type="flex" justify="start" align="top">
                  <Col>
                    <h1 className="pageTitle">Your Account</h1>
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="middle">
                  <Col span={14}>
                    <Alert
                      message="Custom-Plan active"
                      description={
                        <React.Fragment>
                          <p>Your Subscription is active.</p>
                          <p>If you require additional features or a larger monthly returns volume - get in touch with our <Button type="primary" size="small" href="mailto:hello@richcommerce.co" target="_blank" rel="noopener">Sales</Button></p>
                        </React.Fragment>
                      }
                      type="success"
                      showIcon
                    />
                  </Col>
                </Row>
                  
                <Row type="flex" justify="space-around" align="middle" style={{ textAlign: "center", marginTop: 20 }}>
                  <Col span={24}>
                    <Row type="flex" justify="space-around" align="top">
                      <Col span={14}>
                        <p>Your current plan: <strong>Custom Plan</strong></p>
                        <Button onClick={this.handleCustomerPortal.bind(this)}>Update Billing Details</Button>
                        <Card style={{ textAlign: "center", marginTop: 20 }}>
                          <Statistic
                            title="Returns in this billing period"
                            value={this.context.usageChargeCount}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            )
        }
      </React.Fragment>
    )
  }

}

export default AccountCustom;
