/**
 * Component for our Analytics Customer pages
 * including navigation and routing for different
 * component.
 * @component
 */

import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Row, Col, Layout, Menu, message } from "antd";
import Comments from "./Comments";
import axios from "axios"
import Stats from "./Stats";
import ReturnProducts from "./ReturnProducts";
import MostReturn from "./top/MostReturn";
import ErrorRedirect from "../../../../routes/ErrorRedirect";

const { Content } = Layout;

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      customerNameEmail: "",
      matchingCustomers: [],
    };
  }

  render() {
    const updateCustomerDetails = (customerId, shippingName) => {
      if (shippingName != null){
        this.setState({
          customerId,
          customerNameEmail: shippingName,
        });
      } else {
        const customerDetails = this.state.matchingCustomers.find(
          (value) => value.customerId === customerId
        );
        this.setState({
          customerId,
          customerNameEmail: `${customerDetails.shippingName} ${customerDetails.customerEmail}`,
        });
      }
    };

    const onSearch = async (value) => {
      if (value.length > 3) {
        try {
          const responses = await axios.post("/api/analytics/customers", {
            value,
          });
          this.setState({
            matchingCustomers: responses.data.data,
          });
        } catch (err) {
          return message.error("something went wrong please try again", 5);
        }
      } else {
        return this.setState({
          matchingCustomers: [],
        });
      }
    };
    const { location } = this.props;
    return (
      <Row>
        <Col span={4}>
          <Menu
            mode="inline"
            onClick={this.handleClick}
            defaultSelectedKeys={["/dashboard/analytics/customer"]}
            defaultOpenKeys={["customer-menu"]}
            selectedKeys={[location.pathname]}
          >
            <Menu.ItemGroup key="Top" title="Top">
              <Menu.Item key="/dashboard/analytics/customer/most-returned">
                <Link to="/dashboard/analytics/customer/most-returned">
                  <span>Most Returned</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="Customer" title="Customer">
              <Menu.Item key="/dashboard/analytics/customer">
                <Link to="/dashboard/analytics/customer">
                  <span>Stats</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/customer/products">
                <Link to="/dashboard/analytics/customer/products">
                  <span>Returned Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/customer/comments">
                <Link to="/dashboard/analytics/customer/comments">
                  <span>Comments</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Col>
        <Col span={20}>
          <Content
            style={{
              paddingLeft: 24,
              background: "#fff",
              minHeight: 600,
            }}
          >
            <Switch>
              <Route
                exact
                path="/dashboard/analytics/customer"
                render={() => (
                  <Stats
                    customerId={this.state.customerId}
                    onSearch={onSearch}
                    matchingCustomers={this.state.matchingCustomers}
                    customerNameEmail={this.state.customerNameEmail}
                    updateCustomerDetails={updateCustomerDetails}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/customer/comments"
                render={() => (
                  <Comments
                    onSearch={onSearch}
                    customerId={this.state.customerId}
                    matchingCustomers={this.state.matchingCustomers}
                    customerNameEmail={this.state.customerNameEmail}
                    updateCustomerDetails={updateCustomerDetails}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/customer/products"
                render={() => (
                  <ReturnProducts
                    onSearch={onSearch}
                    customerId={this.state.customerId}
                    matchingCustomers={this.state.matchingCustomers}
                    customerNameEmail={this.state.customerNameEmail}
                    updateCustomerDetails={updateCustomerDetails}
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/customer/most-returned"
                render={() => (
                  <MostReturn
                  />
                )}
              />
              <Route path="*" component={ErrorRedirect} /> 
            </Switch>
          </Content>
        </Col>
      </Row>
    );
  }
}

export default Customer;
