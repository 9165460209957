/**
 * Component to render a spinner for our Authentication screens
 * that involve some sort of redirect and waiting time for the user.
 * 
 * @component
 */

import React, { Component } from 'react';
import { Layout, Button, Spin, Row, Col } from 'antd';
import Navigation from '../pages/dashboard/navigation/Navigation';
import '../styles/App.css';

const { Header, Content, Sider } = Layout;

class AuthenticationLoadingScreen extends Component {

  render() {
    return (
      <Layout>
        <Sider
          theme="light"
          breakpoint="md"
          collapsedWidth="0"
        >
          <div className="logo" />
          <Navigation />
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <span style={{ float: "right", marginRight: 30 }}>Having a problem? Hit the <Button type="primary" size="small">Help button</Button></span>
          </Header>
          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 600, textAlign: "center" }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <Spin size="large" />
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }

}

export default AuthenticationLoadingScreen;
