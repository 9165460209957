/**
 * Component for displaying notes that the customer
 * left during the Returns process.
 * 
 * @component
 */

import React, { Component } from "react";
import { Input, Row, Col  } from "antd";

const { TextArea } = Input;
 
class ReturnDetailsNoteCustomer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <React.Fragment>
        {
          this.props.returnObject.notes ?
            (
              <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
                <Col span={9}>
                  <strong>Customer notes</strong>
                </Col>
                <Col span={15}>
                  <TextArea 
                    autosize 
                    value={this.props.returnObject.notes}
                    placeholder="Customer notes (if available)" 
                    disabled={true}
                  />
                </Col>
              </Row>
            )
            : null
        }

      </React.Fragment>
    )
  }
}
 
export default ReturnDetailsNoteCustomer;