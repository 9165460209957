/**
 * Component for our Return details sidebar that
 * lets users edit the details of a customer like
 * email, name and address.
 * 
 * @component
 */

import React, { Component } from "react";
import { Button, Icon, Tooltip } from "antd";
import ReturnDetailsCustomerForm from "./ReturnDetailsCustomerForm";

class ReturnDetailsCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {

    return(

      <React.Fragment>

        <Tooltip placement="bottom" title="Update customer details">
          <Button type={this.props.buttonType} size="small" onClick={this.toggleModal.bind(this)}>
            <Icon type="edit" /> {this.props.buttonText}
          </Button>
        </Tooltip>

        {
          this.state.visible
            ? <ReturnDetailsCustomerForm  handleGetReturn={this.props.handleGetReturn} returnObject={this.props.returnObject} toggleModal={this.toggleModal.bind(this)}/>
            : null
        }

      </React.Fragment>

    );

  }
};

export default ReturnDetailsCustomer;