/**
 * Main Component for our Analytics Reports that loads
 * all different Report types. Also includes a Feature-
 * Gate for this feature.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Parser } from "json2csv";

import {
  DatePicker,
  Row,
  Col,
  Spin,
  Table,
  message,
  Tooltip,
  Icon,
  Divider,
  Button,
  Input,
  Select,
} from "antd";
import Upgrade from "../../../../components/Upgrade";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import "../../../../styles/App.css"
const { RangePicker } = DatePicker;
const { Option } = Select;
let timeout = undefined;

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exportLoading: false,
      data: [],
      dataToBeExported: [],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      date: undefined,
      searchValue: undefined,
      options: [],
    };
  }
  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }

  async handleExport() {

    this.setState({
      exportLoading: true,
    });


    if (this.props.startDate == null || this.props.endDate == null) {
      this.setState({
        exportLoading: false,
      });
      return message.error("Select a start and end date first.", 4);
    }

    try {
      const response = await axios.post(this.props.url, {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        isExport: true,
        searchTerm: this.state.searchValue
      });

      if (response.data.error) {
        this.setState({
          exportLoading: false,
        });
        return message.error("Error creating export file of the report.", 4);
      }

      if (response.data.emailExport) {
        this.setState({
          exportLoading: false,
        });
        return message.success("Report is being generated. You will receive an email shortly with the download link.", 4);
      }

      let reportDataParser = new Parser({ fields: this.props.exportHeaders });
      response.data.data = response.data.data.map(product => {
        if (Array.isArray(product.returnReasons)) {
          product.returnReasons = product.returnReasons.join(", ");
        }
        if (Array.isArray(product.notes)) {
          product.notes = product.notes.join(", ");
        }
        return product;
      })
      let dataToBeExported = reportDataParser.parse(response.data.data);

      return this.setState({
        exportLoading: false,
        dataToBeExported
      });

    } catch (err) {
      this.setState({
        exportLoading: false,
      });
      return message.error("Error creating report csv.", 4);
    }
  }

  async handleSubmit() {
    if (this.context && this.context.featureAnalyticsAdvanced === false) {
      return;
    }

    this.setState({
      loading: true,
    });

    const { currentPage, pageSize } = this.state;

    if (!this.props.startDate || !this.props.endDate) {
      this.setState({
        loading: false,
      });
      return message.error("Select a start and end date.", 4);
    }
    try {
      const response = await axios.post(this.props.url, {
        currentPage,
        pageSize,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });

      if (response.data.error) {
        return message.error("Error fetching report data from server.", 4);
      }

      this.setState({
        data: response.data.data,
        totalRecords: response.data.totalRecords,
        loading: false,
      });

      return;
    } catch (err) {
      message.error("Error fetching report data.", 4);
    }
  }

  onChange(dates, updateData) {
    this.props.updateDate(updateData)
    this.setState(
      {
        currentPage: 1, // to reset the active page
      }
    );
  }

  triggerPagination = async (paginationObject) => {
    const currentPage = paginationObject.current;
    const { pageSize } = paginationObject;
    this.setState(
      {
        currentPage,
        pageSize,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  handleResetDataToBeExported = () => {
    this.setState({
      dataToBeExported: []
    })
  };
  getRanges = () => {
    let ranges = {
      Today: [moment(), moment()],
      "This Month": [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    }
    const allowedDomains = ["bellybandit.myshopify.com", "kate-quinn-organics.myshopify.com"];
    if (allowedDomains.includes(this.context?.myshopify_domain)) {
      ranges = {
        ...ranges,
        "This Year": [
          moment().startOf("year"),
          moment().endOf("year"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ]
      }
    }
    return ranges;
  }

  handleSearchChange = (value) => {
    this.setState({
      searchValue: value,
    })
    const data = this.state.options.filter(option => {
      if (this.props.reportName === "most-returned-products") {
        return option.productId === value
      } else {
        return option.variantId === value
      }
    })
    this.setState({
      data,
    })
  }

  searchReturns = async (value) => {
    try {
      this.setState({
        searchValue: value,
      })
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const { currentPage, pageSize } = this.state;
        const response = await axios.post(this.props.url, {
          searchTerm: this.state.searchValue,
          currentPage,
          pageSize,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        });
        if (response.data.error) {
          return message.error("Error fetching report data from server.", 4);
        }
        this.setState({
          options: response.data.data,
          totalRecords: response.data.totalRecords,
          loading: false,
        });
      }, 300)
      return;
    } catch (err) {
      message.error("Error fetching report data.", 4);
    }
  }

  handleReset = () => {
    this.setState({
      searchValue: undefined,
      options: [],
    })
    this.handleSubmit()
  }

  onOk() {
    this.setState({
      loading: true,
    });
    this.handleSubmit();
  }

  render() {
    const {
      loading,
      currentPage,
      totalRecords,
      data,
      date,
    } = this.state;
    const timestamp = Date.now();
    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      let tooLate = date && current.diff(date, "days") > 45;
      let tooEarly = date && date.diff(current, "days") > 45;
      const allowedDomains = ["bellybandit.myshopify.com", "kate-quinn-organics.myshopify.com"];
      if (allowedDomains.includes(this.context?.myshopify_domain)) {
        tooLate = date && current.diff(date, "days") > 365;
        tooEarly = date && date.diff(current, "days") > 365;
      }
      return tooEarly || tooLate;
    };
    const optionId = this.props.reportName === "most-returned-products" ? "productId" : "variantId"

    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
        >
          <Col>
            <h1 className="pageTitle">
              <Tooltip title={this.props.tooltip}>
                <Icon
                  style={{ fontSize: "16px", alignItems: "center" }}
                  type="info-circle"
                />
              </Tooltip>{" "}
              {this.props.title}
            </h1>
          </Col>
        </Row>

        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row style={{ marginTop: 30, marginBottom: 30 }}>
              <Col>
                <Spin spinning={loading}>
                  <Row type="flex" justify="center" align="middle">
                    <RangePicker
                      ranges={{
                        "Today": [moment(), moment()],
                        "This Month": [moment().startOf('month'), moment().endOf('month')],
                        "Last Month": [moment().subtract(1, "month").startOf('month'), moment().subtract(1, "month").endOf('month')],
                        "Year to Date": [moment().startOf('year'), moment()],
                        "Last Year": [moment().subtract(1, "year").startOf('year'), moment().subtract(1, "year").endOf('year')],
                      }}
                      defaultValue={[moment(this.props.startDate), moment(this.props.endDate)]}
                      // ranges={this.getRanges()}
                      onChange={this.onChange.bind(this)}
                      // disabledDate={disabledDate}
                      onCalendarChange={(dateArray) => {
                        const endDateSelected = dateArray[1] != null;
                        if (endDateSelected) {
                          return this.setState({
                            date: undefined,
                          });
                        }

                        const startDateSelected = dateArray[0];
                        if (startDateSelected) {
                          this.setState({
                            date: dateArray[0],
                          });
                        }
                      }}
                      format="YYYY-MM-DD"
                      showTime={{ format: 'HH:mm' }}
                      onOk={() => {
                        setTimeout(() => { this.onOk() }, 0)
                      }}
                    />
                    <Col>
                      {
                        this.state.dataToBeExported.length > 0
                          ? (
                            <Button
                              type="primary"
                              style={{ marginLeft: 20 }}
                              onClick={this.handleResetDataToBeExported.bind(this)}
                            >
                              <CSVLink
                                data={this.state.dataToBeExported}
                                filename={`RichReturns-report-${this.props.reportName}-${timestamp}.csv`}
                              >
                                <Icon type="download" /> Download
                              </CSVLink>
                            </Button>
                          )
                          : (
                            <Button
                              loading={this.state.exportLoading}
                              style={{ marginLeft: 20 }}
                              onClick={this.handleExport.bind(this)}
                            >
                              <span><Icon type="export" /> Export</span>
                            </Button>
                          )
                      }
                    </Col>
                    <Col offset={1}>
                      {(this.props.reportName === "most-returned-products" || this.props.reportName === "most-returned-variants") &&
                        <Select
                          showSearch
                          showArrow={false}
                          filterOption={false}
                          notFoundContent={null}
                          style={{ width: "400px" }}
                          placeholder={this.props.reportName === "most-returned-products" ? "Search for a Product" : "Search for a Variant"}
                          value={this.state.searchValue}
                          onChange={this.handleSearchChange}
                          onSearch={this.searchReturns}
                        >
                          {this.state.options?.map(option => {
                            return <Option key={option[optionId]} value={option[optionId]}>
                              {this.props.reportName === "most-returned-products" ? option.productName : option.variantName}
                            </Option>
                          })}
                        </Select>
                      }
                      {this.state.searchValue && <Icon className="cross-icon-reports" style={{ color: "red" }} type="close" onClick={this.handleReset} />}
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Row>

            <Table
              columns={this.props.columns}
              dataSource={data}
              rowKey={(_, index) => index}
              bordered
              pagination={{
                simple: true,
                defaultCurrent: currentPage,
                total: parseInt(totalRecords),
              }}
              onChange={(e) => this.triggerPagination(e)}
            />
          </React.Fragment>
        ) : (
          <Row type="flex" justify="space-around" align="top">
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default Report;
