/**
 * Component for the OAauth part of our ups.com integration.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class UPS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);

    const code = urlObject.searchParams.get("code");
    const connectionPartner = "ups";

    if (code == null) {
      return;
    }

    try {
      await axios.post("/api/integrations/ups/auth", {
        code,
        connectionPartner,
      });
      this.setState({
        redirect: true,
      });
    } catch (err) {
      message.error("Error connecting your ups account.");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard/connect" />;
    }
    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default UPS;