/**
 * Component for our Manual Shipping-Labels
 * for users to upload them.
 * 
 * @component
 */

 
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm,
  Icon,
  Upload,
  message
} from "antd";
import axios from "axios"

const ButtonGroup = Button.Group;
   
class ReturnDetailsLabelManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customShippingLabelUrl: undefined,
    }
       
  }

  // image related functions
  async checkLabel(file) {
    const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"]
    if (!allowedFileTypes.includes(file.type)) {
      message.error("Only .jpg, .jpeg, .png and .pdf files smaller than 3 MB allowed.", 5);
      return false;
    }
    if ((file.size / 1024 / 1024) > 3) {
      message.error("Only files smaller than 3 MB allowed.", 5);
      return false;
    }
    return true;
  }


  async deleteLabel(options, firstOrSecondFile) {

    try {
      const response = await axios.delete("/api/returns/label", {
        data: {
          returnId: this.props.returnId,
          firstOrSecondFile,
        }
      });
      if (response.data.error) {
        return message.error("Error deleting file. Try again.", 4);
      }
      this.props.handleGetReturn()
      return message.success("File deleted successfully", 4);

    } catch (err) {
      message.error("Error deleting file. Try again.", 4);
    }
  }

  async handleLabelUpload(options, firstOrSecondFile) {
    options.onProgress({ percent: 20 });
    let signedUrl = "";
    let bucketUrl = "";
    let fileName = "";
    try {

      let response = await axios.post("/api/returns/uploadLabel", {
        fileType: options.file.type,
        fileSize: options.file.size / 1024 / 1024,
      });
      if (response.data.error === "wrong-size") {
        return message.error("Only files smaller than 3 MB allowed.", 5);
      }
      if (response.data.error === "wrong-format") {
        return message.error("Only .jpg, .jpeg, .png and .pdf files smaller than 3 MB allowed.", 5);
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch(err) {
      return message.error("Error preparing shipping label upload. Try again.", 4);
    }

    try {
      await axios.put(signedUrl, options.file, { headers: { 'Content-Type': options.file.type } });
      options.onProgress({ percent: 75 });
      const responseLabel = await axios.post("/api/returns/storeLabel", {
        returnId: this.props.returnId,
        customShippingLabelUrl: bucketUrl,
        customShippingLabelSecondUrl: bucketUrl,
        firstOrSecondFile,
      });
      if (responseLabel.data.error) {
        return message.error("Error saving shipping label. Try again.", 4);
      }
      this.props.handleGetReturn()
      options.onSuccess({ percent: 100 });
      return message.success("Shipping label uploaded successfully.", 5);

    } catch(err) {
      options.onError({ percent: 0 });
      return message.error("Error saving shipping label. Try again.", 4);
    }
  }
  render() {

    return(
    
      <React.Fragment>
        <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
          <Col span={9}>
            <strong>Upload a shipping-label</strong>
            <Tooltip 
              placement="bottom" 
              title="This will send out the following email template if enabled: 'Approved Return (Manually Uploaded Prepaid Label)'. A variable for the first label is already included in the template. If you use the upload for the second document, make sure to include the variable {MANUAL_LABEL_SECOND_DOWNLOAD_LINK} in the template as well.">
              <Icon type="info-circle"/>
            </Tooltip>
          </Col>
          <Col span={15}>
            <Row type="flex" justify="start" align="top">
              {
                this.props.returnObject.customShippingLabelUrl == null && this.state.customShippingLabelUrl == null
                  ? (
                    <Col md={8} sm={12} xs={12}>
                      <Upload 
                        beforeUpload={ (file) => { return this.checkLabel(file) } }
                        customRequest={ (options) => { this.handleLabelUpload(options, "first") } }
                      >
                        <Button type="default" size="small">
                          <Icon type="upload" />Upload
                        </Button>
                      </Upload>
                    </Col>
                  )
                  : (
                    <ButtonGroup style={{marginRight:10}}>
                      <Button 
                        type="default" 
                        size="small" 
                        href={(this.props.returnObject.customShippingLabelUrl != null)
                          ? 
                          this.props.returnObject.customShippingLabelUrl
                          : 
                          this.state.customShippingLabelUrl} 
                        target="_blank"
                      >
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                            View label
                      </Button>
                      <Popconfirm
                        title="Delete this file?"
                        onConfirm={(options) => this.deleteLabel(options, "first")}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                      >
                        <Button type="default" size="small" icon="delete" />
                      </Popconfirm>
                    </ButtonGroup>
                  )
              }

              {
                this.props.returnObject.customShippingLabelSecondUrl == null && this.props.customShippingLabelSecondUrl == null
                  ? (
                    <Col md={8} sm={12} xs={12}>
                      <Upload beforeUpload={ (file) => { return this.checkLabel(file) } } customRequest={ (options) => { this.handleLabelUpload(options, "second") } }>
                        <Button type="default" size="small">
                          <Icon type="upload" />Upload Second
                        </Button>
                      </Upload>
                    </Col>
                  )
                  : (
                    <ButtonGroup>
                      <Button 
                        type="default" 
                        size="small" 
                        href={(this.props.returnObject.customShippingLabelSecondUrl != null) ? this.props.returnObject.customShippingLabelSecondUrl : this.props.customShippingLabelSecondUrl} 
                        target="_blank"
                      >
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                        View
                      </Button>
                      <Popconfirm
                        title="Delete this file?"
                        onConfirm={(options) => this.deleteLabel(options, "second")}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                      >
                        <Button type="default" size="small" icon="delete" />
                      </Popconfirm>
                    </ButtonGroup>
                  )
              }
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
   
export default ReturnDetailsLabelManual;