import React from "react";
import axios from "axios";
import { Button, Modal, Row, Col, message, InputNumber, Input } from "antd";

class PhotoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      minimumPhoto: props?.state?.customPhotoSetting?.minimumPhoto || 0,
      maximumPhoto: props?.state?.customPhotoSetting?.maximumPhoto || 5,
      titlePhotos: {
        title1: props?.state?.customPhotoSetting?.titlePhotos?.title1 || "Photo 1",
        title2: props?.state?.customPhotoSetting?.titlePhotos?.title2 || "Photo 2",
        title3: props?.state?.customPhotoSetting?.titlePhotos?.title3 || "Photo 3",
        title4: props?.state?.customPhotoSetting?.titlePhotos?.title4 || "Photo 4",
        title5: props?.state?.customPhotoSetting?.titlePhotos?.title5 || "Photo 5",
      }
    };

  }

  async componentDidMount() {
    const response = await axios.get("/api/returnSettings");
    if (response.data.customPhotoSetting) {
      this.setState({
        minimumPhoto: response.data?.customPhotoSetting?.minimumPhoto || 0,
        maximumPhoto: response.data.customPhotoSetting.maximumPhoto || 5,
        titlePhotos: {
          title1: response.data.customPhotoSetting.titlePhotos.title1 || "Photo 1",
          title2: response.data.customPhotoSetting.titlePhotos.title2 || "Photo 2",
          title3: response.data.customPhotoSetting.titlePhotos.title3 || "Photo 3",
          title4: response.data.customPhotoSetting.titlePhotos.title4 || "Photo 4",
          title5: response.data.customPhotoSetting.titlePhotos.title5 || "Photo 5",
        }
      });
    }
  }

  handleSubmit = async () => {
    const customPhotoSetting = {
      minimumPhoto: this.state.minimumPhoto,
      maximumPhoto: this.state.maximumPhoto,
      titlePhotos: this.state.titlePhotos,
    };

    try {
      const response = await axios.post("/api/returnSettings/updateCustomPhotoUpload", {
        customPhotoSetting,
      });
      if (response.data.status === "error") {
        return message.error(
          "Error updating your return settings. Please try again.",
          5
        );
      }
      this.props.updateState({
        customPhotoSetting: {
          minimumPhoto: this.state.minimumPhoto,
          maximumPhoto: this.state.maximumPhoto,
          titlePhotos: this.state.titlePhotos,
        }
      });
      this.props.showConfigPhotoUploadModel(false);
      return message.success("Return settings successfully updated.", 5);
    } catch (err) {
      return message.error(
        "Error updating your return settings. Please try again.",
        5
      );
    }
  };

  handleCancel() {
    this.props.showConfigPhotoUploadModel(false);
  }

  handleOnChangeNumber = (target, value) => {
    if (target === 'maximumPhoto' && value < this.state.minimumPhoto) {
      return message.error("Maximum photo must be greater than minimum photo", 5);
    }
    if (target === 'minimumPhoto' && value > this.state.maximumPhoto) {
      return message.error("Minimum photo must be less than maximum photo", 5);
    }
    this.setState({ [target]: value });
  };

  handleOnchangeTitle = (target, value) => {
    this.setState({ titlePhotos: { ...this.state.titlePhotos, [target]: value } });
  };
  renderTitlePhotos = () => {
    const titlePhotos = this.state.titlePhotos;
    const maxPhoto = this.state.maximumPhoto;
    let titlePhotoList = [];
    for (let i = 1; i <= maxPhoto; i++) {
      titlePhotoList.push(
        <Row key={i} style={{ display: 'flex', justifyContent: 'center' }}>
          <Col span={4}>
            <p>Title Photo {i}</p>
          </Col>
          <Col span={20}>
            <Input
              span={24}
              value={titlePhotos[`title${i}`]}
              onChange={(e) => this.handleOnchangeTitle(`title${i}`, e.target.value)}
            // placeholder="Example: Please select all that apply down below in terms of your satisfaction"
            />
          </Col>
        </Row>
      );
    }
    return titlePhotoList
  };
  render() {
    const maxPhoto = this.state.maximumPhoto;
    const minPhoto = this.state.minimumPhoto;
    return (
      <React.Fragment>
        <Modal
          width="50%"
          title="Set up your photo upload"
          visible={true}
          onOk={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="Save"
          cancelText="Close"
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSubmit.bind(this)}
            >
              Save
            </Button>,
          ]}
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col span={12}>
              <Col span={8}>
                <p>Min photo</p>
              </Col>
              <Col span={12}>
                <InputNumber min={0} max={maxPhoto} value={minPhoto}
                  onChange={(value) => this.handleOnChangeNumber('minimumPhoto', value)}
                />
              </Col>
            </Col>
            <Col span={12}>
              <Col span={8}>
                <p>Max photo</p>
              </Col>
              <Col span={12}>
                <InputNumber min={1} max={5} value={this.state.maximumPhoto}
                  onChange={(value) => this.handleOnChangeNumber('maximumPhoto', value)}
                />
              </Col>
            </Col>
          </Row>
          {this.renderTitlePhotos()}

        </Modal>
      </React.Fragment>
    )
  }
}

export default PhotoUpload;