/**
 * Component for staff/user E-Mail settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Button, Form, Input, Checkbox, Row, Col, Divider, message } from "antd";
import { trackFSEvent } from "../../../../helpers/fullstory";

const FormItem = Form.Item;


class EmailAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notificationAdminEnabled: undefined,
      notificationErrorEnabled: undefined,
      notificationErrorEmail: undefined,
      notificationAdminEmail: undefined,
      notificationAdminCopyEnabled: undefined,
      notificationAdminCopyEmailAddress: undefined,
      emailReport: undefined,
      trackingChange: {
        newReturnChange: false,
        copyOfCustomerNotificationsChange: false,
        errorNotificationsChange: false
      }
    };
  }

  componentDidMount = async () => {
    try {
      const response = await axios.get("/api/emailSettings/admin");
      if (response.data.status === "error") {
        return message.error("There was an error loading your email settings. Try again.", 4);
      }
      return this.setState({
        ...response.data,
      });
    } catch(err) {
      return message.error("There was an error loading your email settings. Try again.", 4);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields( async (err, values) => {
      
      if (err) {
        return message.error("Error saving your email settings. Try again.", 4);
      }

      const { notificationAdminEnabled, notificationAdminEmail } = this.state;
      if (notificationAdminEnabled && (notificationAdminEmail == null || notificationAdminEmail === "")) {
        return message.error("Please enter a valid email address.", 4);
      }

      const { notificationErrorEnabled, notificationErrorEmail } = this.state;
      if (notificationErrorEnabled && (notificationErrorEmail == null || notificationErrorEmail === "")) {
        return message.error("Please enter a valid email address.", 4);
      }

      this.setState({
        loading: true,
      });

      try {
        const response = await axios.post("/api/emailSettings/admin/update", values);
        if (response.data.status === "error") {
          this.setState({
            loading: false,
          });
          return message.error("There was an error saving your email settings. Try again.", 4);
        }
        if (this.state.trackingChange?.newReturnChange) {
          trackFSEvent("Edit new return", {
            feature: "Email settings",
            enableNewReturn: values.notificationAdminEnabled || this.state.notificationAdminEnabled || false,
            email: values.notificationAdminEmail || this.state.notificationAdminEmail || ''
          });
        }
        if (this.state.trackingChange?.copyOfCustomerNotificationsChange) {
          trackFSEvent("Edit copy of customer notifications", {
            feature: "Email settings",
            enableCopyOfCustomerNotifications: values.notificationAdminCopyEnabled || this.state.notificationAdminCopyEnabled || false,
            email: values.notificationAdminCopyEmailAddress || this.state.notificationAdminCopyEmailAddress || ''
          });
        }
        if (this.state.trackingChange?.errorNotificationsChange) {
          trackFSEvent("Edit error notification", {
            feature: "Email settings",
            enableCopyOfCustomerNotifications: values.notificationErrorEnabled || this.state.notificationErrorEnabled || false,
            email: values.notificationErrorEmail || this.state.notificationErrorEmail || ''
          });
        }
        message.success("E-Mail settings updated successfully.", 4);
        return this.setState({
          loading: false,
        });
      } catch(err) {
        this.setState({
          loading: false,
        });
        return message.error("There was an error saving your email settings. Try again.", 4);
      }
    });
  }

  onChange  = (e) => {
    const target = e.target.id;
    const newReturnChange = ['notificationAdminEnabled', 'notificationAdminEmail'].includes(target);
    const copyOfCustomerNotificationsChange = ['notificationAdminCopyEnabled', 'notificationAdminCopyEmailAddress'].includes(target);
    const errorNotificationsChange = ['notificationErrorEnabled', 'notificationErrorEmail'].includes(target);
    this.setState({
      [target]: e.target.checked,
      trackingChange: { ...this.state.trackingChange, newReturnChange, copyOfCustomerNotificationsChange, errorNotificationsChange }
    });
  }

  onChangeEmail  = (e) => {
    const target = e.target.id;
    const newReturnChange = ['notificationAdminEnabled', 'notificationAdminEmail'].includes(target);
    const copyOfCustomerNotificationsChange = ['notificationAdminCopyEnabled', 'notificationAdminCopyEmailAddress'].includes(target);
    const errorNotificationsChange = ['notificationErrorEnabled', 'notificationErrorEmail'].includes(target);
    this.setState({
      [target]: e.target.value,
      trackingChange: { ...this.state.trackingChange, newReturnChange, copyOfCustomerNotificationsChange, errorNotificationsChange }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    return(
      <React.Fragment>
        <Row type="flex" justify="start" align="top" style={{ paddingBottom: 35 }}>
          <Col>
            <h1 className="pageTitle">Staff notifications</h1>
            <p>Settings for staff notifications.</p>
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>

        <Form onSubmit={ this.handleSubmit } hideRequiredMark={true}>
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>New Return</h4>
              <span>Who should be contacted for a new return? Usually the owner, staff or general customer-support email address. We send a summary of the return details.</span>
            </Col>
            <Col span={10}>
              <FormItem
                {...formItemLayout}
                label="Active"
              >
                {getFieldDecorator("notificationAdminEnabled", {
                })(
                  <Checkbox
                    checked={this.state.notificationAdminEnabled}
                    onChange={this.onChange.bind(this)}
                  >
                    Enable
                  </Checkbox>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="E-Mail"
              >
                {getFieldDecorator('notificationAdminEmail', {
                  initialValue: this.state.notificationAdminEmail,
                })(
                  <Input
                    style={{ width: 300 }}
                    placeholder="Enter an email address"
                    onChange={this.onChangeEmail.bind(this)} 
                    disabled={this.state && !this.state.notificationAdminEnabled}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Report email</h4>
              <span>Who should be the receiver of all your reports and exports?</span>
            </Col>
            <Col span={10}>
              <FormItem
                {...formItemLayout}
                label="E-Mail"
              >
                {getFieldDecorator('emailReport', {
                  initialValue: this.state.emailReport,
                })(
                  <Input
                    style={{ width: 300 }}
                    placeholder="Enter an email address"
                    onChange={this.onChangeEmail.bind(this)} 
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={10}>
              <h4>Copy of customer notifications</h4>
              <span>Receive a copy of the email notifications sent to customers.</span>
            </Col>
            <Col span={10}>
              <FormItem
                {...formItemLayout}
                label="Active"
              >
                {getFieldDecorator("notificationAdminCopyEnabled", {
                })(
                  <Checkbox
                    checked={this.state.notificationAdminCopyEnabled}
                    onChange={this.onChange.bind(this)}
                  >
                    Enable
                  </Checkbox>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="E-Mail"
              >
                {getFieldDecorator('notificationAdminCopyEmailAddress', {
                  initialValue: this.state.notificationAdminCopyEmailAddress,
                })(
                  <Input
                    style={{ width: 300 }}
                    placeholder="Enter an email address"
                    onChange={this.onChangeEmail.bind(this)}
                    disabled={this.state && !this.state.notificationAdminCopyEnabled}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
            
          <React.Fragment>
            <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
              <Col span={10}>
                <h4>Error notifications</h4>
                <span>Be notified each time there's a label generation error for your returns.</span>
              </Col>
              <Col span={10}>
                <FormItem
                  {...formItemLayout}
                  label="Active"
                >
                  {getFieldDecorator("notificationErrorEnabled", {
                  })(
                    <Checkbox
                      checked={this.state.notificationErrorEnabled}
                      onChange={this.onChange}
                    >
                      Enable
                    </Checkbox>
                  )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="E-Mail"
                >
                  {getFieldDecorator('notificationErrorEmail', {
                    initialValue: this.state.notificationErrorEmail,
                  })(
                    <Input
                      style={{ width: 300 }}
                      placeholder="Enter an email address"
                      onChange={this.onChangeEmail}
                      disabled={this.state && !this.state.notificationErrorEnabled}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
          </React.Fragment>

          <Row type="flex" justify="center" align="middle">
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit" disabled={this.state.loading} loading={this.state.loading}>
                  Save
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedEmailAdmin = Form.create()(EmailAdmin);
export default WrappedEmailAdmin;
