/**
 * Component for Store-Wide-Exchanges settings page in the Dashboard.
 *
 * @component
 */

import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Row, Col, Button, Divider, Form, Checkbox, message, Alert } from 'antd'
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext'
const FormItem = Form.Item

class StoreWideExchanges extends React.Component {
  static contextType = SubscriptionContext
  constructor(props) {
    super(props)
    this.state = {
      exchangesEnableHighPriced: false,
      storeWideOverselling:false,
      storeWideBalance: false,
      storeWideDiscount: false,
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings')
      const data = response.data
      if (!data) {
        return
      }
      this.setState({
        ...data,
      })
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5)
    }
  }

  handleChange = (e) => {
    const target = e.target.id
    this.setState({
      [target]: e.target.checked,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        )
      }
      if (this.state.storeWideDiscount && values.storeWideBalance) {
        values = {
          ...values,
          storeWideDiscount: false
        }
      }
      try {
        const response = await axios.post('/api/returnSettings/update', values)
        if (response.data.status === 'error') {
          return message.error('Error updating your exchange settings. Please try again.', 5)
        }
        this.setState({
          ...response.data,
        })
        return message.success('Return settings successfully updated.', 5)
      } catch (err) {
        console.log("Error updating your exchange settings", err)
        return message.error('Error updating your exchange settings. Please try again.', 5)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Store-Wide Exchanges</h1>
            <p>
              Let customers exchange for any product from all over the store.
            </p>
          </Col>
        </Row>

        {!this.context.featureStoreWideExchange ? (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={18}>
                <Alert
                  message="Already on our Plus Plan?"
                  description={
                    <React.Fragment>
                      <span>
                        Please reach out to us directly in order to have this
                        feature enabled and set up for your account.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Alert
                  message="Important"
                  description={
                    <React.Fragment>
                      <span>
                        Make sure to enable the 'Store-Wide Exchange' option in
                        the general{" "}
                        <Link to="/dashboard/settings">
                          Return-Settings tab
                        </Link>
                        {" "}or in the Advanced Rules.
                      </span>
                      <br /> <br />
                      <span>
                        Good to know:
                        <br />
                        - Store-Wide Exchanges are always assumed as even
                        exchanges which means that the customer is not
                        automatically charged/refunded for a price difference
                        <br />
                        - While you can offer exchanges for cheaper/more
                        expensive items, you need to manually charge or refund
                        the price difference in this case
                        <br />- We are checking the live inventory levels for
                        the selected product on your Shopify-store while the
                        customer chooses an exchange
                      </span>
                      <br /> <br />
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit} className="removeFormMargin" >
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Enable Higher Price Products</h4>
                  <span>
                    Please check this box to enable customers to exchange items for higher-value products.
                  </span>
                </Col>

                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangesEnableHighPriced',
                    )(
                      <Checkbox
                        checked={this.state.exchangesEnableHighPriced}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Force overselling</h4>
                  <span>By default we look at each product variant and check if the option "Continue selling when out of stock" is enabled in your Shopify store. Only then is a variant shown as being available when there is no inventory. Enabling 'Force overselling' will disregard this setting in Shopify and always show variants as available for exchange.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('storeWideOverselling', {
                    })(
                      <Checkbox checked={ this.state.storeWideOverselling } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Hide Balance message</h4>
                  <span>Check this box if you want to hide the store-wide exchange balance message.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('storeWideBalance', {
                    })(
                      <Checkbox checked={ this.state.storeWideBalance } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              {!this.state.storeWideBalance &&
              <React.Fragment>
                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>Store-Wide discount calculation</h4>
                    <span>Check this box if you want to apply discount calculation for the store-wide exchange balance message.</span>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('storeWideDiscount', {
                      })(
                        <Checkbox checked={ this.state.storeWideDiscount } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
              </React.Fragment>
              }

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col>
                  <FormItem>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}
const WrappedStoreWideExchange = Form.create()(StoreWideExchanges)
export default WrappedStoreWideExchange
