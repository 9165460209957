import React, { Component } from 'react'
import axios from 'axios'
import {
  Button,
  Row,
  Col,
  Select,
  Divider,
  message,
  Checkbox,
  Form,
  InputNumber,
} from 'antd'
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext'
const FormItem = Form.Item
import '../../../../../styles/App.css'
import { trackFSEvent } from '../../../../../helpers/fullstory'
const { Option } = Select

class Incentives extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      discountCodeResolution: "storeCredit",
      discountCodeType: "fixed",
      bonusCreditType: "fixed",
      bonusCreditValue: 0.0,
      discountCodeValue: 0.0,
      bonusCreditResolution: "exchangeItem",
      enableBonusDictating: false,
      dictatingCreditResolution: "",
      enableDiscountDictating: false,
      disctatingBonusResolution: "",
      enableTimer: false,
      timerValue: 1.0,
    };
  }

  componentDidMount = async() => {

    if (this.props.currentIncentive?.incentiveType) {
      const isDiscountCode = this.props.currentIncentive.incentiveType === "Discount Code" ? true : false;
      this.setState({
        ...this.props.currentIncentive,
        enableDiscountCode: isDiscountCode,
        enableBonusCredits: !isDiscountCode,
        bonusCreditValue: isDiscountCode ?  0.0 : this.props.currentIncentive.incentiveValue,
        discountCodeValue: isDiscountCode ? this.props.currentIncentive.incentiveValue : 0.0,
        discountCodeResolution: isDiscountCode ?  this.props.currentIncentive.incentiveResolution : "storeCredit",
        bonusCreditResolution: isDiscountCode ?  "exchangeItem" : this.props.currentIncentive.incentiveResolution,
        bonusCreditType: isDiscountCode ? "fixed" : this.props.currentIncentive.incentiveValueType,
        discountCodeType: isDiscountCode ? this.props.currentIncentive.incentiveValueType : "fixed",
        enableBonusDictating: isDiscountCode ? false : this.props.currentIncentive.dictatIncentive,
        dictatingCreditResolution: isDiscountCode ? undefined : this.props.currentIncentive.dictatResolution,
        enableDiscountDictating: isDiscountCode ? this.props.currentIncentive.dictatIncentive : false,
        disctatingBonusResolution: isDiscountCode ? this.props.currentIncentive.dictatResolution : undefined,
      })
    }
    await this.getScopes()
  }

  getScopes = async () => {
    try {
      const response = await axios.get("/api/returnSettings/scopes");
      if (response.data == null) {
        return;
      }
      this.setState({
        shopifyPlanName: response.data.shopifyPlanName,
      });
    } catch (err) {
      message.error("Error retrieving access scopes and plan. Try again.", 5);
    }
  }

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  }

  handleResolutions = (value, target) => {
    this.setState({
      [target]: value,
    })
  }

  handleIncentivesType = (value, target) => {
    this.setState({
      [target]: value
    })
  }

  getResoulutions = () => {
    const options = this.props.getResoulutions(true);
    return options;
  }

  handleSubmit = (e) => {
    e?.preventDefault();
    this.props.form.validateFields( async (err, values) => {
      if (err) {
        return message.error("There is something wrong with the data you entered. Please check again.", 5);
      }
      const { enableBonusCredits, enableDiscountCode } = values
      if (!enableBonusCredits && !enableDiscountCode) {
        return message.error("Please enable incentive First. Thanks", 2);
      }

      let url = "/api/returnSettings/addIncentive";
      let addIncentive = true;
      if (this.props.currentIncentive?.incentiveType) {
        url = "/api/returnSettings/updateIncentive";
        addIncentive = false;
        values.id  = this.props.currentIncentive.id;
      }
      if (addIncentive) {
        trackFSEvent("Add an Incentive", {
          feature: "Incentive",
          enableDiscountCode: values.enableDiscountCode || false,
          discountCodeType: values.discountCodeType || '',
          resolution: values.discountCodeResolution || this.state.discountCodeResolution || '',
          bonusCreditAmount: values.bonusCreditValue || 0,
          enableResolutionDictating: values.enableBonusDictating || false,
          enableIncentiveTimer: values.enableTimer || false,
          timeValue: values.timerValue || 0
        });
      }

      try {
        const response = await axios.post(url, values);
        if (response.data.status === "error") {
          return message.error("Error updating your return settings. Please try again.", 5);
        }
        this.setState({
          ...response.data,
        });
        this.props.toggleEditMode(_,{},false)
        return message.success("Incentive added successfully.", 3);
      } catch (err) {
        return message.error(
          "Error updating your Incentives. Please try again.",
          5
        );
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="removeFormMargin">
        {(this.state.shopifyPlanName === "enterprise" || this.state.shopifyPlanName === "shopify_plus") && (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Bonus Credits</h4>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('enableBonusCredits', {
                    initialValue: this.state.enableBonusCredits,
                  })(
                    <Checkbox
                      disabled={this.state.enableDiscountCode}
                      checked={this.state.enableBonusCredits}
                      onChange={(e) => this.handleChange(e)}
                    >
                      {' '}
                      Enable Bonus Credit{' '}
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.state.enableBonusCredits && (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Bonus Credit Type</h4>
              </Col>
              <Col span={10}>
                <FormItem className="resolutionOptions">
                  {getFieldDecorator('bonusCreditType', {
                    initialValue: this.state.bonusCreditType,
                  })(
                    <Select
                      onChange={(value) =>
                        this.handleIncentivesType(value, 'bonusCreditType')
                      }
                    >
                      <Option value="fixed">Fixed Amount</Option>
                      <Option value="percentage">Percentage</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Select Resolution</h4>
              </Col>
              <Col span={10}>
                <FormItem className="resolutionOptions">
                  {getFieldDecorator('bonusCreditResolution', {
                    initialValue: this.state.bonusCreditResolution,
                  })(
                    <Select
                      onChange={(value) =>
                        this.handleResolutions(value, 'bonusCreditResolution')
                      }
                    >
                      {this.getResoulutions()}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Bonus Credit Amount</h4>{' '}
                {`(In ${
                  this.state.bonusCreditType === 'fixed'
                    ? " your store's currency"
                    : ' percentage'
                } )`}
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('bonusCreditValue', {
                    initialValue: this.state.bonusCreditValue,
                  })(
                    this.state.bonusCreditType === 'fixed' ? (
                      <InputNumber min={0} step={0.1} />
                    ) : (
                      <InputNumber min={0} max={100} step={0.1} />
                    )
                  )}
                  {this.state.bonusCreditType !== 'fixed' && ' %'}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Dictate Incentive</h4>
                <span>Display the incentive only when a particular resolution is selected by your customers.</span>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('enableBonusDictating', {
                    initialValue: this.state.enableBonusDictating,
                  })(
                    <Checkbox
                      checked={this.state.enableBonusDictating}
                      onChange={(e) => this.handleChange(e)}
                    >
                      {' '}
                      Enable Resolution Dictating{' '}
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
            </Row>
            {this.state.enableBonusDictating &&
              <Row type="flex" justify="space-around" align="middle" style={{ marginTop: '20px' }}>
                <Col span={10}>
                  <h4>Select Dictating Resolution</h4>
                  <span>The incentive will only be displayed when a customer chooses this resolution.</span>
                </Col>
                <Col span={10}>
                  <FormItem className="resolutionOptions">
                    {getFieldDecorator('dictatingCreditResolution', {
                      initialValue: this.state.dictatingCreditResolution,
                    })(
                      <Select
                        onChange={(value) =>
                          this.handleResolutions(value, 'dictatingCreditResolution')
                        }
                      >
                        <Option key={"originalPaymentMethod"} value={"originalPaymentMethod"}>
                          Refund
                        </Option>
                        {this.getResoulutions()}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            }
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Enable Timer</h4>
                <span>A timer of 1 minute will be applied on incentive</span>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('enableTimer', {
                    initialValue: this.state.enableTimer,
                  })(
                    <Checkbox
                      checked={this.state.enableTimer}
                      onChange={(e) => this.handleChange(e)}
                    >
                      {' '}
                      Enable Incentive Timer{' '}
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
            </Row>
            {this.state.enableTimer &&
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10} style={{marginTop: 10}}>
                  <span>Choose timer value (in minutes) </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('timerValue', {
                      initialValue: this.state.timerValue,
                    })(
                      <InputNumber min={0} step={0.1} />
                    )}
                  </FormItem>
                </Col>
              </Row>
            }
          </React.Fragment>
        )}
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Discount Codes</h4>
          </Col>
          <Col span={10}>
            <FormItem>
              {getFieldDecorator('enableDiscountCode', {
                initialValue: this.state.enableDiscountCode,
              })(
                <Checkbox
                  disabled={this.state.enableBonusCredits}
                  checked={this.state.enableDiscountCode}
                  onChange={(e) => this.handleChange(e)}
                >
                  {' '}
                  Enable Discount Code{' '}
                </Checkbox>
              )}
            </FormItem>
          </Col>
        </Row>

        {this.state.enableDiscountCode && (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Discount Code Type</h4>
              </Col>
              <Col span={10}>
                <FormItem className="resolutionOptions">
                  {getFieldDecorator('discountCodeType', {
                    initialValue: this.state.discountCodeType,
                  })(
                    <Select
                      onChange={(value) =>
                        this.handleIncentivesType(value, 'discountCodeType')
                      }
                    >
                      <Option value="fixed">Fixed Amount</Option>
                      <Option value="percentage">Percentage</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Select Resolution</h4>
              </Col>
              <Col span={10}>
                <FormItem className="resolutionOptions">
                  {getFieldDecorator('discountCodeResolution', {
                    initialValue: this.state.discountCodeResolution,
                  })(
                    <Select
                      onChange={(value) =>
                        this.handleResolutions(value, 'discountCodeResolution')
                      }
                    >
                      {this.getResoulutions()}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Bonus Credit Amount</h4>{' '}
                {`(In ${
                  this.state.discountCodeType === 'fixed'
                    ? " your store's currency"
                    : ' percentage'
                } )`}
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('discountCodeValue', {
                    initialValue: this.state.discountCodeValue,
                  })(
                    this.state.discountCodeType === 'fixed' ? (
                      <InputNumber min={0} step={0.1} />
                    ) : (
                      <InputNumber min={0} max={100} step={0.1} />
                    )
                  )}
                  {this.state.discountCodeType !== 'fixed' && ' %'}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Dictate Incentive</h4>
                <span>Display the incentive only when a particular resolution is selected by your customers.</span>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('enableDiscountDictating', {
                    initialValue: this.state.enableDiscountDictating,
                  })(
                    <Checkbox
                      checked={this.state.enableDiscountDictating}
                      onChange={(e) => this.handleChange(e)}
                    >
                      {' '}
                      Enable Resolution Dictating{' '}
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
            </Row>
            {this.state.enableDiscountDictating &&
              <Row type="flex" justify="space-around" align="middle" style={{ marginTop: '20px' }}>
                <Col span={10}>
                  <h4>Select Dictating Resolution</h4>
                  <span>The incentive will only be displayed when a customer chooses this resolution.</span>
                </Col>
                <Col span={10}>
                  <FormItem className="resolutionOptions">
                    {getFieldDecorator('disctatingBonusResolution', {
                      initialValue: this.state.disctatingBonusResolution,
                    })(
                      <Select
                        onChange={(value) =>
                          this.handleResolutions(value, 'disctatingBonusResolution')
                        }
                      >
                        <Option key={"originalPaymentMethod"} value={"originalPaymentMethod"}>
                          Refund
                        </Option>
                        {this.getResoulutions()}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
            }
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Enable Timer</h4>
                <span>A timer of 1 minute will be applied on incentive</span>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('enableTimer', {
                    initialValue: this.state.enableTimer,
                  })(
                    <Checkbox
                      checked={this.state.enableTimer}
                      onChange={(e) => this.handleChange(e)}
                    >
                      {' '}
                      Enable Incentive Timer{' '}
                    </Checkbox>
                  )}
                </FormItem>
              </Col>
            </Row>
            {this.state.enableTimer &&
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10} style={{marginTop: 10}}>
                  <span>Choose timer value (in minutes) </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('timerValue', {
                      initialValue: this.state.timerValue,
                    })(
                      <InputNumber min={0} step={0.1} />
                    )}
                  </FormItem>
                </Col>
              </Row>
            }
          </React.Fragment>
        )}
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col span={2}>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </FormItem>
          </Col>
          <Col span={2}>
            <Button type="danger" onClick={() => this.props.toggleEditMode(_,{},false)}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

const WrappedCustomerIncentives = Form.create()(Incentives)
export default WrappedCustomerIncentives;
