/**
 * Component for user's Subscription with Shopify Billing.
 * 
 * @component
 */

import React, { Component } from 'react';
import axios from "axios";
import { Alert, Button, Row, Col, Card, Icon, Divider, Rate, Checkbox, Tag, Slider, Typography, message, Anchor, Popover, Radio } from 'antd';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import PackageLicenses from '../../../../components/PackageLicenses';
const { Link } = Anchor;
const { Title } = Typography;

export class Plans extends Component {

  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      featureCustomerPhotoUpload: false,
      featureCustomerPhotoUploadPlus: false,
      featureCustomerPhotoUploadPremium: false,
      featureCustomerPhotoUploadPro: false,
      featureCustomerPhotoUploadStandard: false,

      visible: false,
      plusPlanSelectedTier: undefined,
      durationLimitInIntervals: null,
      discountPercentage: undefined,
      plans: [], // (for new plans flow)
      checkedAddOns: [], // for checked Add On (for new plans flow)
      intervalTime: 'EVERY_30_DAYS',
      monthlyPlusPlanPricing: {
        500: {
          included: 500,
          price: 199,
        },
        750: {
          included: 750,
          price: 299,
        },
        1000: {
          included: 1000,
          price: 399,
        },
        1250: {
          included: 1250,
          price: 499,
        },
        1500: {
          included: 1500,
          price: 599,
        },
        1750: {
          included: 1750,
          price: 699,
        },
        2000: {
          included: 2000,
          price: 799,
        },
        2250: {
          included: 2250,
          price: 899,
        },
        2500: {
          included: 2500,
          price: 999,
        },
      },
      yearlyPlusPlanPricing: {
        500: {
          included: 500,
          price: 1910,
          fakePrice: 2388,
        },
        750: {
          included: 750,
          price: 2870,
          fakePrice: 3588
        },
        1000: {
          included: 1000,
          price: 3830,
          fakePrice: 4788
        },
        1250: {
          included: 1250,
          price: 4790,
          fakePrice: 5988,
        },
        1500: {
          included: 1500,
          price: 5750,
          fakePrice: 7188,
        },
        1750: {
          included: 1750,
          price: 6710,
          fakePrice: 8388,
        },
        2000: {
          included: 2000,
          price: 7670,
          fakePrice: 9588,
        },
        2250: {
          included: 2250,
          price: 8630,
          fakePrice: 10788,
        },
        2500: {
          included: 2500,
          price: 9590,
          fakePrice: 11988,
        },
      },
      monthlyPlusPlanAddOnPricing: {
        500: {
          included: 500,
          price: 40,
        },
        750: {
          included: 750,
          price: 60,
        },
        1000: {
          included: 1000,
          price: 80,
        },
        1250: {
          included: 1250,
          price: 100,
        },
        1500: {
          included: 1500,
          price: 120,
        },
        1750: {
          included: 1750,
          price: 140,
        },
        2000: {
          included: 2000,
          price: 160,
        },
        2250: {
          included: 2250,
          price: 180,
        },
        2500: {
          included: 2500,
          price: 200,
        },
      },
      yearlyPlusPlanAddOnPricing: {
        500: {
          included: 500,
          price: 384,
          fakePrice: 480,
        },
        750: {
          included: 750,
          price: 576,
          fakePrice: 720,
        },
        1000: {
          included: 1000,
          price: 768,
          fakePrice: 960,
        },
        1250: {
          included: 1250,
          price: 960,
          fakePrice: 1200,
        },
        1500: {
          included: 1500,
          price: 1152,
          fakePrice: 1440,
        },
        1750: {
          included: 1750,
          price: 1344,
          fakePrice: 1680,
        },
        2000: {
          included: 2000,
          price: 1536,
          fakePrice: 1920,
        },
        2250: {
          included: 2250,
          price: 1728,
          fakePrice: 2160,
        },
        2500: {
          included: 2500,
          price: 1920,
          fakePrice: 2400,
        },
      },
    };
  }

  toggleVisibilityOfLicenseModal() {
    this.setState({ visible: !this.state.visible })
  }

  async componentDidMount() {
    this.getDiscount();
    this.getPlans(); // call function to fetch plans data
    if (this.context && this.context.featureCustomerPhotoUpload) {
      this.setState({
        featureCustomerPhotoUpload: this.context.featureCustomerPhotoUpload,
      });
    }
  }

  // Fetch plans relevant data from API
  async getPlans() {
    try {
      const response = await axios.get('/api/plans');
      const plans = response.data.plans;
      this.setState({
        plans,
      });
    } catch (err) {
      console.log("Error getting the plans. Please try again.");
    }
  }

  changeIntervalTime(target) {
    const intervalTime = target.target.value;
    this.setState({
      intervalTime: ["EVERY_30_DAYS", "ANNUAL"].includes(intervalTime) ? intervalTime : "EVERY_30_DAYS"
    });
  }

  async getDiscount() {
    try {
      const response = await axios.get('/api/connections/discountPlan');
      this.setState({
        durationLimitInIntervals: response.data.durationLimitInIntervals,
        discountPercentage: response.data.discountPercentage ? response.data.discountPercentage : null
      });
    } catch (err) {
      console.log("Error retrieving the billing discount");
    }
  }

  async handleSubmit(e) {
    const selectedPlan = e.target.value;
    const selectedAddOn = this.state.featureCustomerPhotoUpload ? "addOnCustomerPhotoUpload" : null;
    const plusPlanSelectedTier = this.state.plusPlanSelectedTier;
    const intervalTime = this.state.intervalTime;

    // use different endpoint to distinct b/w first time installation flow or update plan subscriton.
    let endpoint = this.props.existingPlan ?
      "/api/shop/shopify/billing/existing" :
      "/api/shop/shopify/billing/planOptions";

    const response = await axios.post(`${endpoint}`, {
      selectedPlan,
      selectedAddOn,
      plusPlanSelectedTier,
      intervalTime: intervalTime
    });
    if (response.data.status === "error") {
      return message.error("Error updating your plan. Please try again or contact support (top right corner of your screen).")
    }
    return window.location.href = response.data.redirectUrl;
  }

  async handleSubmitNewPlanFlow(planId) {
    const addOns = this.state.checkedAddOns
      .filter(checkedAddOn => checkedAddOn.planId == planId)
      .map(checkedAddOn => checkedAddOn.addOnId);

    let planSelectedTier;
    this.state.plans.map((plan) => {
      if (plan.id == planId) {
        planSelectedTier = plan.tier
      }
    });

    try {
      const response = await axios.post("/api/shop/shopify/billing/existing/newPlanFlow", {
        planId,
        addOns,
        planSelectedTier
      });

      if (response.data.status === "error") {
        return message.error("Error updating your plan. Please try again or contact support (top right corner of your screen).")
      }
      return window.location.href = response.data.redirectUrl;
    } catch (err) {
      message.error("Error updating your plan. Please try again or contact support.", 5)
    }
  }

  _onChangeAddOn = (planId, addOnId) => {
    let checkedAddOnsCopy = this.state.checkedAddOns;
    const condition = cond => cond.planId === planId && cond.addOnId === addOnId;

    const included = checkedAddOnsCopy.some(checkedAddon => condition(checkedAddon));
    if (included) {
      checkedAddOnsCopy = checkedAddOnsCopy.filter(checkedAddon => !condition(checkedAddon))
    } else {
      checkedAddOnsCopy.push({
        planId,
        addOnId
      });
    }
    this.setState({
      checkedAddOns: checkedAddOnsCopy
    });
  }

  onChangePlanTier = (tier, plan) => {
    const selectedPlan = this.state.plans.map((_plan) => {
      if (_plan.id == plan.id) {
        _plan.price = plan.metadata.slider.steps[tier]
        _plan.tier = tier
      }
      return _plan;
    });
    this.setState({ plans: [...selectedPlan] });
  }

  onChangeAddon = (e) => {
    const selectedAddOn = e.target.value;
    const addOnEnabled = e.target.checked;

    this.setState({
      [selectedAddOn]: addOnEnabled,
    },
    () => {
      // Callback to update central property based on individual checkbox states
      const featureCustomerPhotoUpload = this.state.featureCustomerPhotoUploadPlus ||
                                        this.state.featureCustomerPhotoUploadPremium ||
                                        this.state.featureCustomerPhotoUploadPro ||
                                        this.state.featureCustomerPhotoUploadStandard;
      this.setState({ featureCustomerPhotoUpload });
    });
  }

  onChangePlusPlanTier = (e) => {
    this.setState({
      plusPlanSelectedTier: e,
    });
  }

  planPrice = (subscriptionPrice, fakePrice) => {
    return (
      <React.Fragment>
        {
          (this.state.intervalTime === 'EVERY_30_DAYS') ? (
            this.state.discountPercentage
              ? (
                <React.Fragment>
                  <s>${subscriptionPrice}</s>
                  &nbsp;
                  ${(subscriptionPrice - ((subscriptionPrice / 100) * this.state.discountPercentage * 100)).toFixed(2)}
                </React.Fragment>
              )
              : (
                <React.Fragment>
                  ${(subscriptionPrice).toFixed(2)}
                </React.Fragment>
              )
          ) : (
            <React.Fragment>
              <s>${fakePrice}</s>
                &nbsp;
                ${(subscriptionPrice).toFixed(2)}
            </React.Fragment>
          )
        } {this.state.intervalTime === 'ANNUAL' ? (
          <>
            per month (billed annually at ${(subscriptionPrice * 12).toFixed(0)})
            <Alert 
              message={`Save $${(((fakePrice - subscriptionPrice) * 12)).toFixed(2)} per year!`} 
              type="info" 
              showIcon 
              style={{ maxWidth: '85%', marginTop: 10, borderColor: 'var(--rrPrimary)' }}  />
          </>
        ) : (
          'per month'
        )}
      </React.Fragment>
    )
  }

  renderSelectablePlanAddons = (plan) => {
    const selectablePlanAddons = plan.PlanAddOns.filter(planAddOn => planAddOn.isSelectable);
    if (selectablePlanAddons.length === 0) {
      return null;
    }

    return (
      <Card title={`Optional Add-Ons for ${plan.name} Plan`} style={{ marginTop: 20 }} key={`card-plans-${plan.id}`}>
        <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
        {selectablePlanAddons.map((planAddOn) => (
          <React.Fragment key={`selectable-${plan.id}-addon-${planAddOn.id}`}>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title={this.context && this.context[planAddOn.AddOn.featureAddOnName] === true && plan.name === this.context.planName ?
                <Tag color="gold">Active</Tag> :
                null
              }
            >
              <Row>
                <Col span={2}>
                  <Popover
                    content={
                      <React.Fragment>
                        <p key={`PlanAddOn-${planAddOn.AddOn.id}`}>
                          <Icon type="check" /> {planAddOn.AddOn.description}
                        </p>
                        <Divider />
                        {planAddOn.AddOn.metadata && planAddOn.AddOn.metadata.features && planAddOn.AddOn.metadata.features.length > 0 &&
                          planAddOn.AddOn.metadata?.features.map((feature, index) => (
                            <p key={index}>
                              <Icon type="check" /> {feature}
                            </p>
                          ))
                        }
                        <Divider />
                        <p key={planAddOn.AddOn.id}>
                          <Icon type="check" /> {planAddOn.AddOn.footerNotes}
                        </p>
                      </React.Fragment>
                    }
                    style={{
                      maxWidth: '300px'
                    }}
                  >
                    <Icon type="info-circle" style={{ position: "relative", right: "8px" }} />
                  </Popover>
                </Col>
                <Col span={15}>
                  <span style={{ fontWeight: 'bold' }}>
                    {planAddOn.AddOn.name}
                  </span>
                </Col>
                <Col span={7}>
                  <Checkbox
                    onChange={() => this._onChangeAddOn(plan.id, planAddOn.AddOn.id)}
                  // defaultValue={this.context[planAddOn.AddOn.featureAddOnName] === true && plan.name === this.context.planName}
                  >
                    Enable
                  </Checkbox>
                </Col>
                <Col span={24} offset={2}>
                  {`$ ${planAddOn.price ? planAddOn.price : planAddOn.AddOn.price}`}
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        ))}
      </Card>
    )
  }

  renderPlanSlider = (plan) => {
    if (!plan.metadata &&
      !plan.metadata.slider &&
      !plan.metadata.slider.step &&
      !plan.metadata.slider.min &&
      !plan.metadata.slider.max &&
      !plan.metadata.slider.marks) {
      return
    }
    return (
      <React.Fragment>
        <p>Returns included per month:</p>
        <Slider
          key={`plan-returns-slider-${plan.id}`}
          min={plan.metadata.slider.min}
          max={plan.metadata.slider.max}
          step={plan.metadata.slider.step}
          marks={plan.metadata.slider.marks}
          tipFormatter={(value) => `${value} returns`}
          onChange={(tier) => this.onChangePlanTier(tier, plan)}
        />
      </React.Fragment>
    )
  }

  renderPlans = (plans) => {
    if (!plans || plans.length === 0) return null;
    const { intervalTime } = this.state;
    const displayPlans = plans.filter(plan => plan.intervalTime === intervalTime);
    return (
      displayPlans.map((plan, index) => (
        <Col span={8} key={`col-plans-${plan.id}`}>
          <Card
            title={
              <React.Fragment>
                <p>
                  <span>{plan.name}
                  </span>
                </p>
                <p>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.handleSubmitNewPlanFlow(plan.id)}
                  >
                    Choose plan<Icon type="right" />
                  </Button>
                </p>
              </React.Fragment>
            }
            hoverable={true}
          >
            {this.renderPlanSlider(plan)}
            <h1>
              {plan.tier || this.state.checkedAddOns.length > 0 ? `$ ${plan.price} + ` : `$ ${plan.price}`}
            </h1>
            {plan.metadata && plan.metadata.planUsage && <p>${plan.metadata.planUsage} per extra return</p>}
            <Divider />
            {plan.metadata && plan.metadata.features && plan.metadata.features.length > 0 && plan.metadata.features.map((feature, index) => (
              <p key={`plan-${plan.id}-features-${index}`}>
                <Icon type="check" /> {feature}
              </p>
            ))}


            {plan.PlanAddOns && plan.PlanAddOns.length > 0 && plan.PlanAddOns.map((planAddOn, index) => {
              return !planAddOn.isSelectable ?
                (
                  <p key={`plan-${plan.id}-addon-features-${index}`}>
                    <Icon type="check" /> {planAddOn.AddOn.name}
                  </p>
                ) : null

            })}
            <p>
              <Icon type="check" /> {plan.tier ? plan.tier : plan.monthlyQuota} returns included per month
            </p>
          </Card>
          {this.renderSelectablePlanAddons(plan)}
        </Col>
      ))
    )
  }

  render() {
    const marks = {
      500: '500',
      750: '',
      1000: '1k',
      1250: '',
      1500: '1.5k',
      1750: '',
      2000: '2k',
      2250: '',
      2500: '2.5k',
    }
    const plusPriceList = this.state.intervalTime === 'EVERY_30_DAYS' ? this.state.monthlyPlusPlanPricing : this.state.yearlyPlusPlanPricing;
    const addOnsPriceList = this.state.intervalTime === 'EVERY_30_DAYS' ? this.state.monthlyPlusPlanAddOnPricing : this.state.yearlyPlusPlanAddOnPricing;
    let percentOfQuota = 0;
    const contextIntervalTime = this.context ? this.context.intervalTime : 'EVERY_30_DAYS';
    const contextUsageQuota = contextIntervalTime === 'EVERY_30_DAYS' ? this.context?.monthlyQuota : this.context?.monthlyQuota * 12 || 0;
  
    if (contextUsageQuota === 0) {
      // this is for legacay plans with no monthly quota
      percentOfQuota = 100;
    } else if (this.context?.usageChargeCount === 0) {
      percentOfQuota = 0;
    } else if (this.context?.usageChargeCount > contextUsageQuota) {
      percentOfQuota = 100;
    } else {
      percentOfQuota = (this.context?.usageChargeCount / contextUsageQuota * 100).toFixed(0);
    }
    return (
      <div style={{ padding: '30px' }}>

        <Row type="flex" justify="center" align="top" style={{ textAlign: "center", marginTop: 40, marginBottom: 20 }}>
          <Col span={14}>
            <Title>Powerful Returns. Flexible Pricing.</Title>
            <Title level={4}>Plans that grow with your business</Title>
          </Col>
        </Row>
        <Row style={{ padding: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Radio.Group value={this.state.intervalTime} onChange={this.changeIntervalTime.bind(this)} style={{ marginBottom: 16 }}>
            <Radio.Button value="EVERY_30_DAYS" style={{ width: '175px', display: 'inline-grid', justifyContent: 'center', alignItems: 'center' }}>Monthly Plan</Radio.Button>
            <Radio.Button value="ANNUAL">Yearly Plan (20% OFF)</Radio.Button>
          </Radio.Group>
        </Row>
        <Row gutter={10}>

          <Col span={8}>
            <Card
              title={
                <React.Fragment>
                  <p>
                    <span>Plus Plan {(this.context && this.context.planName === "plus") && <Tag color="gold">Active</Tag>}</span>
                  </p>
                  <p>
                    <Button type="primary" size="small" onClick={this.handleSubmit.bind(this)} value="plus">{this.state.discountPercentage ? "Choose Discount Plan" : "Choose plan"}<Icon type="right" /></Button>
                  </p>
                </React.Fragment>
              }
              hoverable={true}
            >
              <p>Returns included per month:</p>
              <Slider
                defaultValue={this.state.plusPlanSelectedTier ? this.state.plusPlanSelectedTier : 500}
                min={500}
                max={2500}
                step={250}
                marks={marks}
                tipFormatter={(value) => `${value} returns`}
                onChange={this.onChangePlusPlanTier.bind(this)}
              />
              <h1>{this.state.plusPlanSelectedTier
                ? (
                  this.planPrice(
                    this.state.intervalTime === 'EVERY_30_DAYS' 
                      ? plusPriceList[this.state.plusPlanSelectedTier].price
                      : plusPriceList[this.state.plusPlanSelectedTier].price / 12,
                    this.state.intervalTime === 'EVERY_30_DAYS' 
                      ? plusPriceList[this.state.plusPlanSelectedTier].fakePrice
                      : plusPriceList[this.state.plusPlanSelectedTier].fakePrice / 12 
                  )
                )
                : (
                  this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(199) : this.planPrice(159.17, 199)
                )
              }
              </h1>
              <p>$0.5 per extra return</p>

              <Divider />

              <p><Icon type="check" /> {this.state.plusPlanSelectedTier ? plusPriceList[this.state.plusPlanSelectedTier].included : 500}+ monthly returns (larger plans available)</p>
              <p><Icon type="check" /> All PREMIUM Features</p>
              <p><Icon type="check" /> Exchange for a different item</p>              
              <p><Icon type="check" /> Store-Wide Exchange</p>              
              <p><Icon type="check" /> Stripe Payments</p>              
              <p><Icon type="check" /> Custom Domain</p>
              <p><Icon type="check" /> International Labels</p>
              <p><Icon type="check" /> Audit Log</p>
              <p><Icon type="check" /> Auto Tracking</p>
              <p><Icon type="check" /> API Access</p>
              <p><Icon type="check" /> QR-Code Labels</p>
              <p><Icon type="check" /> Advanced Rules (20 included)</p>
            </Card>
          </Col>

          <Col span={8}>
            <Card
              style={{ height: 680 }}
              title={
                <React.Fragment>
                  <p>
                    <span>Premium Plan {this.context && this.context.planName && this.context.planName === "premium" && <Tag color="gold">Active</Tag>}</span>
                  </p>
                  <p>
                    <Button type="primary" size="small" onClick={this.handleSubmit.bind(this)} value="premium">{this.state.discountPercentage ? "Choose Discount Plan" : "Choose plan"}<Icon type="right" /></Button>
                  </p>
                </React.Fragment>
              }
              hoverable={true}
            >
              <h1 style={{ marginTop: 73 }}>
                {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(99) : this.planPrice(79.17, 99)}
              </h1>
              <p>$0.5 per extra return</p>
              <Divider />
              <p><Icon type="check" /> 100 monthly returns ($0.5 per extra return)</p>
              <p><Icon type="check" /> All PRO Features</p>
              <p><Icon type="check" /> Automated Refund</p>
              <p><Icon type="check" /> Advance Exchanges</p>
              <p><Icon type="check" /> Advanced Rules (10 included)</p>
              <p><Icon type="check" /> Advanced Reports & Trends</p>
              <p><Icon type="check" /> Automatic Exchanges / Exchange Orders</p>
              <p><Icon type="check" /> Customer Incentives</p>
            </Card>
          </Col>

          <Col span={8}>
            <Card
              style={{ height: 680 }}
              title={
                <React.Fragment>
                  <p>
                    <span>Pro Plan {this.context && this.context.planName === "pro" && <Tag color="gold">Active</Tag>}</span>
                  </p>
                  <p>
                    <Button type="primary" size="small" onClick={this.handleSubmit.bind(this)} value="pro">{this.state.discountPercentage ? "Choose Discount Plan" : "Choose plan"}<Icon type="right" /></Button>
                  </p>
                </React.Fragment>
              }

              hoverable={true}
            >
              <h1 style={{ marginTop: 73 }}>
                {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(39) : this.planPrice(31.17, 39)}
              </h1>
              <p>$0.75 per extra return</p>
              <Divider />
              <p><Icon type="check" /> 50 monthly returns ($0.75 include per extra return)</p>
              <p><Icon type="check" /> All STANDARD Features</p>
              <p><Icon type="check" /> Variant Exchanges</p>
              <p><Icon type="check" /> Advanced Analytics Dashboard</p>
              <p><Icon type="check" /> Multi-Language Return Portal</p>
              <p><Icon type="check" /> Language Customization</p>
              {this.state.returnReasonLanguage &&
                <p><Icon type="check" /> Multi-Language Return Reasons</p>
              }
              <p><Icon type="check" /> CSV Export</p>              
              <p><Icon type="check" /> Update Return Resolution</p>              

            </Card>
          </Col>

        </Row>

        <Row gutter={16} style={{ paddingTop: 20 }}>
          <Col span={8}>
            <Card title="Add-Ons for Plus Plan">
              <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
              <Card
                title={this.context && this.context.featureCustomerPhotoUpload && this.context.planName === "plus" &&
                  <Tag color="gold">Active</Tag>
                }
              >
                <Row>
                  <Col span={2}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>Let customers upload photos of their returned items. Make more educated decisions regarding returns.</p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads for your monthly quota of {this.state.plusPlanSelectedTier ? this.state.monthlyPlusPlanAddOnPricing[this.state.plusPlanSelectedTier].included : 500} returns. Adds $0.25 per extra return (in addition to regular overage charges).
                          </p>
                        </React.Fragment>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </Col>
                  <Col span={15}>
                    <span style={{ fontWeight: 'bold' }}>
                      Customer photo uploads
                    </span>
                  </Col>
                  <Col span={7}>
                    <Checkbox onChange={this.onChangeAddon} checked={this.state.featureCustomerPhotoUploadPlus} value="featureCustomerPhotoUploadPlus">Enable</Checkbox>
                  </Col>
                  <Col span={24} offset={2}>
                    {this.planPrice(
                      this.state.intervalTime === 'EVERY_30_DAYS' 
                        ? addOnsPriceList[this.state.plusPlanSelectedTier ? this.state.plusPlanSelectedTier : 500].price
                        : addOnsPriceList[this.state.plusPlanSelectedTier ? this.state.plusPlanSelectedTier : 500].price / 12 , 
                      this.state.intervalTime === 'EVERY_30_DAYS' 
                        ? addOnsPriceList[this.state.plusPlanSelectedTier ? this.state.plusPlanSelectedTier : 500].fakePrice
                        : addOnsPriceList[this.state.plusPlanSelectedTier ? this.state.plusPlanSelectedTier : 500].fakePrice / 12 
                    )}
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>

          <Col span={8}>
            <Card title="Add-Ons for Premium Plan">
              <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
              <Card
                title={this.context && this.context.featureCustomerPhotoUpload && this.context.planName === "premium" &&
                  <Tag color="gold">Active</Tag>
                }
              >
                <Row>
                  <Col span={2}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>Let customers upload photos of their returned items. Make more educated decisions regarding returns.</p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads for your monthly quota of 100 returns. Adds $0.25 per extra return (in addition to regular overage charges).
                          </p>
                        </React.Fragment>

                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </Col>
                  <Col span={15}>
                    <span style={{ fontWeight: 'bold' }}>
                      Customer photo Upload
                    </span>
                  </Col>
                  <Col span={7}>
                    <Checkbox onChange={this.onChangeAddon} checked={this.state.featureCustomerPhotoUploadPremium} value="featureCustomerPhotoUploadPremium">Enable</Checkbox>
                  </Col>
                  <Col span={24} offset={2}>
                    {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(20) : this.planPrice(16, 20)}
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>

          <Col span={8}>
            <Card title="Add-Ons for Pro Plan">
              <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
              <Card
                title={this.context && this.context.featureCustomerPhotoUpload && this.context.planName === "pro" ?
                  <Tag color="gold">Active</Tag> :
                  null
                }
              >
                <Row>
                  <Col span={2}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>Let customers upload photos of their returned items. Make more educated decisions regarding returns.</p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads for your monthly quota of 50 returns. Adds $0.25 per extra return (in addition to regular overage charges).
                          </p>

                        </React.Fragment>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </Col>
                  <Col span={15}>
                    <span style={{ fontWeight: 'bold' }}>
                      Customer photo uploads
                    </span>
                  </Col>
                  <Col span={7}>
                    <Checkbox onChange={this.onChangeAddon} checked={this.state.featureCustomerPhotoUploadPro} value="featureCustomerPhotoUploadPro">Enable</Checkbox>
                  </Col>
                  <Col span={24} offset={2}>
                    {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(10) : this.planPrice(8, 10)}
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>

        </Row>

        <Row type="flex" justify="space-around" align="top" style={{ textAlign: "center", paddingTop: 20 }}>
          <Col span={12}>
            <h3>Securely checkout with your Shopify account</h3>
            <span><img src="/Shopify.png" style={{ maxHeight: 30 }} /></span>
            <Divider type="vertical" />
            <Rate disabled defaultValue={5} />
          </Col>
        </Row>

        <Row type="flex" justify="start" align="top" style={{ paddingTop: 20 }} gutter={16}>
          <Col span={8}>
            <Card title={
              <React.Fragment>
                <p>
                  <span>Standard Plan {this.context && this.context.planName === "standard" && <Tag color="gold">Active</Tag>}</span>
                </p>
                <p>
                  <Button type="primary" size="small" onClick={this.handleSubmit.bind(this)} value="standard">{this.state.discountPercentage ? "Choose Discount Plan" : "Choose plan"}<Icon type="right" /></Button>
                </p>
              </React.Fragment>
            }
            hoverable={true}
            >
              <h1>
                {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(19) : this.planPrice(15.17, 19)}
              </h1>
              <p>$1.00 per extra return</p>
              <Divider />
              <p><Icon type="check" /> 10 monthly returns ($1 per extra return)</p>
              <p><Icon type="check" /> Branded Returns Portal</p>
              <p><Icon type="check" /> Automated Prepaid Labels</p>
              <p><Icon type="check" /> Auto-Return Approval</p>
              <p><Icon type="check" /> Multi-Language Customer Notifications</p>
              <p><Icon type="check" /> Supported integrations: Klaviyo, Gorgias and Intercom</p>
            </Card>

            <Card title="Optional Add-Ons for Standard Plan" style={{ marginTop: 20 }}>
              <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
              <Card
                title={this.context && this.context.featureCustomerPhotoUpload && this.context.planName === "pro" &&
                  <Tag color="gold">Active</Tag>
                }
              >
                <Row>
                  <Col span={2}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>Let customers upload photos of their returned items. Make more educated decisions regarding returns.</p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading photos a requirement
                          </p>
                          <p>
                            <Icon type="check" /> Add a custom description for best photo results
                          </p>
                          <p>
                            <Icon type="check" /> Storage of photos included
                          </p>
                          <p>
                            <Icon type="check" /> Backup of photos included
                          </p>
                          <p>
                            <Icon type="check" /> Lifelong archiving of photos included
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads for your monthly quota of 10 returns. Adds $0.25 per extra return (in addition to regular overage charges).
                          </p>
                        </React.Fragment>
                      }
                      style={{
                        maxWidth: '300px'
                      }}
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </Col>
                  <Col span={15}>
                    <span style={{ fontWeight: 'bold' }}>
                      Customer photo uploads
                    </span>
                  </Col>
                  <Col span={7}>
                    <Checkbox onChange={this.onChangeAddon} checked={this.state.featureCustomerPhotoUploadStandard} value="featureCustomerPhotoUploadStandard">Enable</Checkbox>
                  </Col>
                  <Col span={24} offset={2}>
                    {this.state.intervalTime === 'EVERY_30_DAYS' ? this.planPrice(5) : this.planPrice(4, 5)}
                  </Col>
                </Row>
              </Card>
            </Card>
          </Col>
          {this.renderPlans(this.state.plans)}
        </Row>
        <Divider />
        <Row>
          <Anchor >
            <Col span={4}>
            </Col>
            <Col span={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.richcommerce.co/legal/terms-of-service/"
                title="Terms"
              />
            </Col>
            <Col span={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.richcommerce.co/legal/privacy-policy/"
                title="Privacy"
              />
            </Col>
            <Col span={3}>
              <Button
                style={{ color: "rgba(0, 0, 0, 0.65)" }}
                type="link"
                onClick={() => this.toggleVisibilityOfLicenseModal()}
              >
                Licenses
              </Button>
            </Col>
            <Col span={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.richcommerce.co/imprint/"
                title="Imprint"
              />
            </Col>
            <Col span={3}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.richcommerce.co/legal/acceptable-use-policy/"
                title="Acceptable use"
              />
            </Col>
          </Anchor>
        </Row>
        <PackageLicenses
          visible={this.state.visible}
          onCancel={() => this.toggleVisibilityOfLicenseModal()}
        />
      </div>
    )
  }
}

export default Plans;
