/**
 * Component  to get Customs details 
 *
 * @component
 */

import React, { Component } from "react";
import { Row, Col, Divider, Input, Alert } from "antd";

class ReturnLabelCustoms extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <React.Fragment>
      <Row type="flex" justify="space-around" align="top">
        <Col span={22}>
          <Divider />
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={10}>
          <h4>Customs Number</h4>
          <span>
            Enter a customs number (e.g. EORI, AES/ITN) or exemption (e.g.
            NOEEI 30.37(a)).
          </span>
        </Col>
        <Col span={10}>
          <Input
            placeholder="e.g. EORI/AES/ITN/Exemption"
            name="customsNumberOrExemption"
            value={this.props.customsNumberOrExemption}
            onChange={(e) =>
              this.props.handleChange(
                "customsNumberOrExemption",
                e.target.value
              )
            }
          />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{ marginTop: 10 }}
      >
        <Col span={22}>
          <Alert
            type="warning"
            showIcon
            message="Always verify customs documents and commercial invoices with your legal team and carrier contact person. Any delays, costs or penalties incurred are not the responsibility of Rich Returns."
          />
        </Col>
      </Row>
    </React.Fragment>;
  }
}

export default ReturnLabelCustoms;
