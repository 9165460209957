/**
 * Component for the our Photo-Uploads Add-On that can
 * either be enabled permanently or using our Advanced-
 * Rules only e.g. when certain Return Reasons have been
 * selected.
 * 
 * @component
 */

import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Divider, Button, Form, Upload, Icon, message } from "antd";
import axios from "axios";
import { FormattedMessage } from "react-intl";

const FormItem = Form.Item;

class Resolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bucketUrl: undefined,
      submitButtonDisabled: false,
      listImages: {},
    };
  }

  componentDidMount() {
    // scroll to top of page
    window.scrollTo(0, 0);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    // eslint-disable-next-line no-unused-vars
    this.props.form.validateFields( async (err, values) => {
      const { companyIdentifier, returnNumber } = this.props;

      try {
        this.setState({
          submitButtonDisabled: true
        });

        const response = await axios.post("/api/visitors/return/photo/verify", {
          companyIdentifier,
          returnNumber,
        });

        if (response.data.status === "error") {
          this.setState({
            submitButtonDisabled: false
          });
          return message.error(this.getTextUploadPhoto(this.props.returnSettings.customPhotoSetting.minimumPhoto, this.props.returnSettings.customPhotoSetting.maximumPhoto), 5);
        }

        if (this.props.missingEmailForOrder) {
          return this.props.history.push('/return/info');
        } else {
          return this.props.history.push('/return/method');
        }
      } catch(err) {
        this.setState({
          submitButtonDisabled: false
        });
        return message.error("There was an error communicating with the server. Please try again.", 4);
      }
    });
  }

  // image related functions
  async checkPhoto(file, photoNumber) {
    const allowedFileTypes = ["image/png", "image/jpeg"]
    if (!allowedFileTypes.includes(file.type)) {
      message.error("Only .jpg, .jpeg and .png files smaller than 7 MB allowed.", 5);
      return Promise.reject(false);
    }
    if ((file.size / 1024 / 1024) > 7) {
      message.error("Only files smaller than 7 MB allowed.", 5);
      return Promise.reject(false);
    }
    if (this.state.listImages[photoNumber]) {
      message.error("You can only upload one photo per field.", 5);
      return Promise.reject(false);
    }
    return true;
  }

  async handlePhotoUpload(options, photoNumber) {
    options.onProgress({ percent: 20 });
    let signedUrl = "";
    let bucketUrl = "";
    try {
      let response = await axios.post("/api/visitors/return/photo/sign", {
        fileType: options.file.type,
        fileSize: options.file.size / 1024 / 1024,
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
      });
      if (response.data.error === "wrong-size") {
        return message.error("Only files smaller than 7 MB allowed.", 5);
      }
      if (response.data.error === "wrong-format") {
        return message.error("Only .jpg, .jpeg and .png files smaller than 7 MB allowed.", 5);
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      options.onProgress({ percent: 50 });

      this.setState({
        bucketUrl,
        listImages: {
          ...this.state.listImages,
          [photoNumber]: bucketUrl,
        }
      });
    } catch(err) {
      return message.error("Error preparing customer photo upload. Try again.", 4);
    }

    try {
      await axios.put(signedUrl, options.file, { headers: { 'Content-Type': options.file.type } });
      options.onProgress({ percent: 75 });
      const request = await axios.post("/api/visitors/return/photo/save", {
        photoUrl: bucketUrl,
        photoNumber: photoNumber,
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
      });
      if (request.data.error) {
        return message.error("Error saving customer photo. Try again.", 4);
      }
      message.success("Photo uploaded successfully.", 5);
      options.onSuccess({ percent: 100 });
      return this.setState({
        ...request.data,
      });
    } catch(err) {
      options.onError({ percent: 0 });
      return message.error("Error saving customer photo. Try again.", 4);
    }
  }

  async handleRemovePhoto(photoNumber) {
    try {
      // Make a DELETE request to the server
      const request = await axios.post("/api/visitors/return/photo/delete", {
        photoNumber: photoNumber,
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
      });
      if (request.data.error) {
        return message.error("Error deleting customer photo. Try again.", 4);
      }
      return this.setState(prevState => {
        const updatedListImages = { ...prevState.listImages };
        updatedListImages[photoNumber] = null;
        return { listImages: updatedListImages };
      });
    } catch(err) {
      return message.error("Error deleting customer photo. Try again.", 4);
    }
  }

  renderPhotoUpload() {
    const maximumPhoto = this.props?.returnSettings?.customPhotoSetting?.maximumPhoto || 3;
    const titlePhotos = this.props?.returnSettings?.customPhotoSetting?.titlePhotos;
    const rows = []; 
    for (let i = 0; i < maximumPhoto; i++) {
      rows.push(
        <Row key={i} type="flex" justify="center" align="middle" style={{ marginTop: 20 }} >
          <Col xs={24} md={10}>
            <Upload 
              beforeUpload={ (file) => { return this.checkPhoto(file, i+1) } } 
              customRequest={ (options) => { this.handlePhotoUpload(options, i+1) } }
              multiple={false}
              onRemove={() => {
                this.handleRemovePhoto(i+1);
              }}>
              <Button><Icon type="upload" />{titlePhotos[`title${i+1}`]}</Button></Upload>
          </Col>
        </Row>
      )
    }
    return rows;
  }

  getTextUploadPhoto = (minimumPhoto, maximumPhoto) => {
    if (minimumPhoto === 0 && maximumPhoto === 1) {
      return `Please upload ${maximumPhoto} photo`;
    }
    if (minimumPhoto === 0 && maximumPhoto > 1) {
      return `Please upload up to ${maximumPhoto} photos`;
    }
    if (minimumPhoto === 1 && maximumPhoto > 1) {
      return `Please upload at least ${minimumPhoto} photo`;
    }
    if (minimumPhoto === maximumPhoto) {
      return `Please upload ${minimumPhoto} photos`;
    }
    return `Please upload ${minimumPhoto} to ${maximumPhoto} photos`;
  }
  render() {
    // eslint-disable-next-line no-unused-vars
    const { minimumPhoto, maximumPhoto } = this.props.returnSettings.customPhotoSetting;

    return(
      <React.Fragment>
        <Card
          title={
            <FormattedMessage
              id="app.visitor.resolutions.headline"
              description="Title of the third step of the customer portal process ('Additional information')"
              defaultMessage="Upload photos"
              values={{
              }}
            />
          }
          className="textCenter"
        >
          <Form onSubmit={this.handleSubmit}>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Icon type="camera" theme="filled" style={{ fontSize: 50 }} />
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col xs={24} md={10}>
                <h4>{this.getTextUploadPhoto(minimumPhoto, maximumPhoto)}</h4>
                <span>{this.props.returnSettings.customerPhotoUploadDescription}</span>
              </Col>
            </Row>
            {this.renderPhotoUpload()}
            <Divider />
            <Row type="flex" justify="center" align="top">
              <Col>
                <FormItem>
                  <Button>
                    <Link to='/return/order'>
                      <FormattedMessage
                        id="app.visitor.order.buttonGoBack"
                        description="Button to go back one screen"
                        defaultMessage="Go back"
                        values={{
                        }}
                      />
                    </Link>
                  </Button>
                  <Divider type="vertical"/>
                  <Button type="primary" htmlType="submit" disabled={this.state.submitButtonDisabled} style={{ backgroundColor: this.props.brandColorButton, borderColor: this.props.brandColorButton }}>
                    <span style={{ color: this.props.brandColorButtonText, fontStyle: this.props.brandFontStyle }}>
                      <FormattedMessage
                        id="app.visitor.order.buttonSubmit"
                        description="Button to continue from step 'order' to 'resolution'"
                        defaultMessage="Continue"
                        values={{
                        }}
                      />
                    </span>
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

const WrappedResolution = Form.create()(Resolution);
export default WrappedResolution;
