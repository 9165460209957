/**
 * Component for Advanced-Exchanges settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Row, Col, Button, Table, Tag, message, Icon, Alert, Divider } from "antd";
import AddAdvancedExchange from "./components/AddAdvancedExchanges";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";
import VideoPopup from "../../../../components/VideoPopup";
import { trackFSEvent } from "../../../../helpers/fullstory";

class AdvancedExchanges extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      displayAddExchangeSection: false,
      advancedExchangeId: null,
      active: false,
      productName: "",
      showProductTitle: false,
      collections: undefined,
    };
  }

  async componentDidMount() {
    await this.fetchAdvancedExchanges();
  }

  columns = [
    {
      title: "Matching term in product name",
      key: "name",
      dataIndex: "productName",
    },
    {
      title: "Collections shown for matched product",
      key: "collection",
      dataIndex: "collection",
      render: (text, record) => {
        return (
          <span>
            {record.collections.map((collection) => {
              return (
                <Tag
                  color="gold"
                  key={collection.collectionId}
                  style={{ marginBottom: 3 }}
                >
                  {collection.title}
                </Tag>
              );
            })}
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "active",
      render: (text) => {
        return text ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <a onClick={() => this.editAdvanceExchange(record)}>Edit</a>
          <Divider type="vertical" />
          <a onClick={() => this.deleteAdvanceExchange(record)}>Delete</a>
        </span>
      ),
    },
  ];

  deleteAdvanceExchange = async (record) => {
    try {
      const response = await axios.delete(
        `/api/advanced-exchanges/${record.id}`
      );
      if (response.status == 200) {
        let newData = this.state.data.filter(
          (advancedExchange) => advancedExchange.id !== record.id
        );
        let deletedRecord = this.state.data.filter(
          (advancedExchange) => advancedExchange.id === record.id
        );
        trackFSEvent("Delete Advanced Exchanged", {
          feature: "Exchanges",
          numberOfExchangeOptions: newData.length,
          exchangeOptionName: deletedRecord.length > 0 ? deletedRecord[0].productName : '',
          exchangeCollectionsForMatchProducts: deletedRecord.length > 0 ? deletedRecord[0].collections.map(i => i.title).join(',') : 0,
        })
        this.setState({ data: newData });
        message.success("Deleted advanced exchange successfully.", 5);
        return;
      }
    } catch (err) {
      message.error("Error deleting advanced exchange. Try again.", 5);
    }
  };

  toggleAddExchangeForm = () => {
    this.setState({
      advancedExchangeId: null,
      active: true,
      showProductTitle: false,
      productName: "",
      collections: [],
      displayAddExchangeSection: !this.state.displayAddExchangeSection,
    });
  };

  async editAdvanceExchange(record) {
    this.setState({
      advancedExchangeId: record.id,
      active: record.active,
      showProductTitle: record.showProductTitle,
      productName: record.productName,
      collections: record.collections,
      displayAddExchangeSection: true,
    });
  }

  async fetchAdvancedExchanges() {
    try {
      const response = await axios.get("/api/advanced-exchanges");
      if (response.data == null) {
        return;
      }
      this.setState({
        data: response.data,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }
  async resetEditArguments() {
    this.setState(
      {
        advancedExchangeId: null,
        active: false,
        showProductTitle: false,
        productName: "",
        collections: [],
        displayAddExchangeSection: false,
      },
      () => {
        this.fetchAdvancedExchanges();
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Advanced Exchanges</h1>
            <p>Let customers exchange for a similar product (e.g. a different color).</p>
          </Col>
        </Row>

        {
          !this.context.featureAdvancedExchanges
            ? (
              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={18}>
                    <Alert
                      message="Already on our Premium Plan?"
                      description={
                        <React.Fragment>
                          <span>
                            Please reach out to us directly in order to have this feature enabled and set up for your account.
                          </span>
                        </React.Fragment>
                      }
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>
                <Upgrade
                  videoId="ddcft-4_qas"
                  title="Keep more revenue in the business with Advanced-Exchanges."
                  description="Upgrade to the Premium Plan to let customers exchange for something similar."
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Alert
                      message="Important"
                      description={
                        <React.Fragment>
                          <span>
                            Make sure to enable the 'Advanced Exchange' option in the general{" "}
                            <Link to="/dashboard/settings">
                              Return-Settings tab
                            </Link>{" "}
                            or in the Advanced Rules.
                          </span>

                          <br /><br />

                          <span>
                            Good to know:
                            <br />
                            - Advanced Exchanges are always assumed as even exchanges which means that the customer is not automatically charged/refunded for a price difference
                            <br />
                            - While you can offer exchanges for cheaper/more expensive items, you need to manually charge or refund the price difference in this case
                            <br />
                            - We are checking the live inventory levels for the selected product on your Shopify-store while the customer chooses an exchange
                          </span>

                          <br /><br />

                          <span>
                            Read{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://support.apphub.com/en/articles/6324979-how-is-inventory-handled-variant-exchanges-one-click-exchange-orders"
                            >
                              here in our Docs when and how inventory is handled.
                            </a>
                          </span>
                        </React.Fragment>
                      }
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="center" style={{ textAlign: "center", marginTop: 30 }}>
                  <Col span={22}>
                    <h4>See how this works</h4>
                    Launch video-tutorial <VideoPopup videoId="qFjyf6YaWfM" />

                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row type="flex" justify="center" className="dashboard-content">
                  <Col span={12}>
                    <h4>Set up Collections</h4>
                    <p>
                      Offer new product exchange options based on your Shopify collections. Add or update collections in Shopify as needed.
                    </p>
                  </Col>
                  <Col span={10} style={{ textAlign: "center" }}>
                    <a
                      href={
                        "https://" +
                        this.context.myshopify_domain +
                        "/admin/collections"
                      }
                      target="_blank"
                    >
                      <Button><Icon type="edit" /> Go to your Shopify Collections</Button>
                    </a>
                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                {this.state.displayAddExchangeSection ? (
                  <AddAdvancedExchange
                    advancedExchangeId={this.state.advancedExchangeId}
                    active={this.state.active}
                    productName={this.state.productName}
                    showProductTitle={this.state.showProductTitle}
                    collections={this.state.collections}
                    resetEditArguments={this.resetEditArguments.bind(this)}
                    toggleAddExchangeForm={this.toggleAddExchangeForm.bind(this)}
                    numberOfExchangeOptions={this.state.data.length}
                  />
                ) : (
                  <React.Fragment>
                    <Row type="flex" justify="center" align="top" style={{ marginBottom: 10 }}>
                      <Col span={22}>
                        <Button type="primary" onClick={this.toggleAddExchangeForm}>
                          Add Exchange Option
                        </Button>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="top">
                      <Col span={22}>
                        <Table
                          bordered
                          columns={this.columns}
                          dataSource={this.state.data}
                          rowKey={(record) => record.id}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
        }
      </React.Fragment>
    );
  }
}

export default AdvancedExchanges;
