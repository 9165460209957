/**
 * Component to load the Table containing top 10 returned customers * 
 * @component
 */

import React from 'react'
import { Table, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
class MostReturnedTable extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props)
  }
  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'shippingName',
        key: 'shippingName',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Email',
        dataIndex: 'customerEmail',
        key: 'customerEmail',
      },
      {
        title: 'Returns',
        key: 'count',
        dataIndex: 'count',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link to={`/dashboard/analytics/customer?customerId=${record.customerId}`}> <Button type="primary" size="small">See Details</Button> </Link>
            <Divider type="vertical" />
            <Button
              type="primary"
              size="small"
              target="_blank"
              rel="noopener noreferrer"
              href={this.context.storeShopifyUrl + `/admin/customers/${record.customerId}`}
            >
              Show in Shopify
            </Button>
          </span>
        ),
      },
    ];
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.props.mostReturns}
          pagination={false}
          rowKey="customerId"
        />
      </React.Fragment>
    )
  }
}
export default MostReturnedTable;