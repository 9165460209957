import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// screens
import Returns from '../pages/dashboard/returns/Returns';
import Account from '../pages/dashboard/settings/account/Account';
import AccountDirect from '../pages/dashboard/settings/account/components/PlansDirectBilling';
import Connect from '../pages/dashboard/settings/connections/Connect';
import SettingsApi from '../pages/dashboard/settings/api/SettingsApi';
import Brand from '../pages/dashboard/settings/brand/Brand';
import CustomDomain from '../pages/dashboard/settings/brand/CustomDomain';
import Settings from '../pages/dashboard/settings/returns/Settings';
import ExchangeSettings from '../pages/dashboard/settings/exchanges/index';
import VariantExchanges from '../pages/dashboard/settings/exchanges/VariantExchanges';
import AdvancedExchanges from '../pages/dashboard/settings/exchanges/AdvancedExchanges';
import StoreWideExchange from '../pages/dashboard/settings/exchanges/StoreWideExchange';
import ExchangeOrders from '../pages/dashboard/settings/exchanges/ExchangeOrders';
import SettingsRules from '../pages/dashboard/settings/rules/Rules';
import SettingsRefunds from '../pages/dashboard/settings/refunds/Refunds';
import Payment from '../pages/dashboard/settings/payment/StripePayment';
import Email from '../pages/dashboard/settings/email/Email';
import EmailAdmin from '../pages/dashboard/settings/email/EmailAdmin';
import Translations from '../pages/dashboard/settings/translations/Translations';
import AuditLog from '../pages/dashboard/AuditLog';
import Logout from '../components/Logout';

import ShopifySync from '../pages/dashboard/settings/shopifySync/shopifySync';
import Dashboard from '../pages/dashboard/analytics/stats/StatsFull';
import Trends from '../pages/dashboard/analytics/trends/index';
import Customer from '../pages/dashboard/analytics/customer/index';
import Reports from '../pages/dashboard/analytics/reports/index';
import AnalyticsExport from '../pages/dashboard/analytics/export/Export';

//styles
import '../styles/App.css';
import CustomerIncentives from '../pages/dashboard/settings/returns/components/CustomerIncentives';
import ErrorRedirect from './ErrorRedirect';
import Page from '../components/Page';

class DashboardRouter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/dashboard"
          render={(props) => (
            <Page {...props} component={Returns} title="Returns" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/returns"
          render={(props) => (
            <Page {...props} component={Returns} title="Returns" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account"
          render={(props) => (
            <Page {...props} component={Account} title="Your Account" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/account/plan"
          render={(props) => (
            <Page {...props} component={AccountDirect} title="Account" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect"
          render={(props) => (
            <Page {...props} component={Connect} title="Connect services" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings"
          render={(props) => (
            <Page {...props} component={Settings} title="Return settings" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges"
          render={(props) => (
            <Page {...props} component={ExchangeSettings} title="Exchanges" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/variants"
          render={(props) => (
            <Page {...props} component={VariantExchanges} title="Variant Exchanges" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/advanced"
          render={(props) => (
            <Page {...props} component={AdvancedExchanges} title="Advanced Exchanges" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/storeWideExchange"
          render={(props) => (
            <Page {...props} component={StoreWideExchange} title="Store-Wide Exchanges" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/exchanges/orders"
          render={(props) => (
            <Page {...props} component={ExchangeOrders} title="Exchange Orders" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/api"
          render={(props) => (
            <Page {...props} component={SettingsApi} title="Return settings - API keys" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/rules"
          render={(props) => (
            <Page {...props} component={SettingsRules} title="Return settings - Advanced Rules" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/refunds"
          render={(props) => (
            <Page {...props} component={SettingsRefunds} title="Return settings - Refunds" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/payment"
          render={(props) => (
            <Page {...props} component={Payment} title="Stripe Payments" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/incentives"
          render={(props) => (
            <Page {...props} component={CustomerIncentives} title="Customer Incentives" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/brand"
          render={(props) => (
            <Page {...props} component={Brand} title="Brand settings" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/brand/custom-domain"
          render={(props) => (
            <Page {...props} component={CustomDomain} title="Custom Domain" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/email"
          render={(props) => (
            <Page {...props} component={Email} title="Customer notifications" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/email/admin"
          render={(props) => (
            <Page {...props} component={EmailAdmin} title="Staff notifications" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/translations"
          render={(props) => (
            <Page {...props} component={Translations} title="Translations" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/export"
          render={(props) => (
            <Page {...props} component={AnalyticsExport} title="Export" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/settings/sync"
          render={(props) => (
            <Page {...props} component={ShopifySync} title="Shopify Sync" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/audit-log"
          render={(props) => (
            <Page {...props} component={AuditLog} title="Audit Log" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/analytics/dashboard"
          render={(props) => (
            <Page {...props} component={Dashboard} title="Analytics Dashboard" />
          )}
        />
        <Route
          path="/dashboard/analytics/customer"
          render={(props) => (
            <Page {...props} component={Customer} title="Customers" />
          )}
        />
        <Route
          path="/dashboard/analytics/trends"
          render={(props) => (
            <Page {...props} component={Trends} title="Trends" />
          )}
        />
        <Route
          path="/dashboard/analytics/reports"
          component={Reports}
        />
        <Route
          exact={true}
          path="/dashboard/logout"
          render={(props) => (
            <Page {...props} component={Logout} title="Logout" />
          )}
        />
        <Route path="*" component={ErrorRedirect} />
      </Switch>
    );
  }
}

export default DashboardRouter;
