/**
 * Component for Exchange-Orders settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Divider,
  Form,
  Checkbox,
  message,
  Input,
  Select,
} from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";
import { trackFSEvent } from "../../../../helpers/fullstory";

const FormItem = Form.Item;
class SettingsExchanges extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      exchangesEnableVariants: undefined,
      exchangesEnableSamePrice: undefined,
      exchangesEnableOverselling: undefined,
      autoExchange: false,
      exchangeType: "draftOrder",
      autoExchangeStatus: "Pending"
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          "There is something wrong with the data you entered. Please check again.",
          5
        );
      }
      try {
        const response = await axios.post("/api/returnSettings/update", values);
        if (response.data.status === "error") {
          return message.error(
            "Error updating your exchange settings. Please try again.",
            5
          );
        }
        trackFSEvent("Edit Exchange Orders", {
          feature: "Exchanges",
          attachANoteToTheExchangeOrder: values.exchangeOrderNote || this.state.exchangeOrderNote || false,
          createAFreeExchangeOrder: values.exchangeOrderFreeExchange || this.state.exchangeOrderFreeExchange || false,
          restockOriginalItems: values.exchangeOrderRestock || this.state.exchangeOrderRestock || false,
          orderConfirmation: values.exchangeOrderSendConfirmationReceipt || this.state.exchangeOrderSendConfirmationReceipt || false,
          useTitleOfOriginalShippingMethod: values.exchangeOrderUseOriginalShippingTitle || this.state.exchangeOrderUseOriginalShippingTitle || false
        });
        this.setState({
          ...response.data,
        });
        return message.success("Return settings successfully updated.", 5);
      } catch (err) {
        return message.error(
          "Error updating your exchange settings. Please try again.",
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  handleExchangeOrderType = (value) => {
    this.setState({
      exchangeType: value
    })
  }

  handleAutoExchangeStatus = (value) => {
    this.setState({
      autoExchangeStatus: value
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Exchange Orders</h1>
            <p>Create Orders and Draft-Orders for Exchanges directly from Rich Returns.</p>
          </Col>
        </Row>

        {this.context.featureExchangeOrders ? (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit}>
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Attach a note to the Exchange Order</h4>
                  <span>
                    Adds or appends a note to the order.
                    You can use ORDER_NAME, RMA_NUMBER and
                    RETURN_METHOD as placeholders to insert dynamic values.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangeOrderNote",
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeOrderNote}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
                {this.state.exchangeOrderNote &&
                  <React.Fragment>
                    <Col span={10}>
                      <span>
                        This will attach a note to all
                        exchange-orders.
                      </span>
                    </Col>
                    <Col span={10}>
                      <FormItem>
                        {getFieldDecorator("exchangeOrderNoteValue", {
                          initialValue: this.state.exchangeOrderNoteValue,
                        })(
                          <Input
                            placeholder="Exchange for order ORDER_NAME / Return RMA_NUMBER."
                            disabled={!this.state.exchangeOrderNote}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </React.Fragment>
                }
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Create a Free Exchange Order</h4>
                  <span>
                    This will create an exchange-order with prices of
                    line-items set to '0.00'. For Draft-Orders we apply
                    a 100% discount to all line-items due to a
                    limitation by Shopify. If you disable this setting
                    we will create exchange orders & draft-orders with
                    full price line-items, note that in this case you
                    will have to collect any outstanding balance
                    manually e.g. through an invoice of a draft-order.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangeOrderFreeExchange",
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeOrderFreeExchange}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Restock original items</h4>
                  <span>This will enable the checkbox in the dialog where the exchange-order is created. Can be changed manually for each exchange-order later.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('exchangeOrderRestock', {
                    })(
                      <Checkbox checked={this.state.exchangeOrderRestock} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Order Confirmation</h4>
                  <span>
                    This will send your Shopify store's default order
                    confirmation email to the customer for the exchange
                    order. Note: this does not apply to draft-orders as
                    order-confirmations for draft-orders are sent out
                    automatically from Shopify when the actual order is
                    created later.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangeOrderSendConfirmationReceipt",
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state
                            .exchangeOrderSendConfirmationReceipt
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              {/* <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>Use Shopify's native restocking functionality</h4>
                    <p>Any items selected to be restocked will be restocked in the original order just like you would restock them through your Shopify admin dashboard. Restocked items are visible in the timeline of the order and also marked as restocked in any Shopify reports.</p>
                    <p>If this option is disabled we'll restock items by increasing your inventory directly and leave the original order untouched.</p>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('exchangeOrderRestockViaRefund', {
                      })(
                      <Checkbox checked={ this.state.exchangeOrderRestockViaRefund } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Use Title of Original Shipping Method</h4>
                  <span>
                    This will use the title of the shipping method from
                    the original order for the exchange order. If
                    disabled the default shipping title "Free Shipping"
                    is applied.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangeOrderUseOriginalShippingTitle",
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state
                            .exchangeOrderUseOriginalShippingTitle
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>Enable AutoExchange</h4>
                    <span>Check this box if you want to automatically create an exchange order for the return initiated by a customer.</span>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('autoExchange', {
                      })(
                        <Checkbox checked={this.state.autoExchange} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>

              <React.Fragment>
                <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                  <Col span={10}>
                    <h4>Select order type</h4>
                    <span>Select the order type while creating auto exchange for the return initiated by a customer. i.e, Exchange Order, Draft Order.</span>
                  </Col>
                  <Col span={10}>
                    <FormItem label="Exchange type" >
                      {getFieldDecorator('exchangeType', {
                        initialValue: this.state.exchangeType
                      })(
                        <Select
                          onChange={this.handleExchangeOrderType.bind(this)}
                          disabled={!this.state.autoExchange}
                        >
                          <Option value="exchangeOrder">Exchange Order</Option>
                          <Option value="draftOrder">Draft Order</Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content" >
                <Col span={10}>
                  <span>Choose a return status for which the exchange order should be created automatically.</span>
                </Col>
                <Col span={10}>
                  <FormItem label="Select Status" >
                    {getFieldDecorator('autoExchangeStatus', {
                      initialValue: this.state.autoExchangeStatus
                    })(
                      <Select
                        disabled={!this.state.autoExchange}
                        onChange={this.handleAutoExchangeStatus.bind(this)}
                      >
                        <Option value="Pending">Pending</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Shipped">Shipped</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Resolved">Resolved</Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col>
                  <FormItem>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        ) : (
          <Upgrade
            title="A faster way to process Exchanges."
            description="Upgrade to the Premium Plan to create Exchange-Orders directly from your Rich Returns dashboard."
          />
        )}
      </React.Fragment>
    );
  }
}

const WrappedSettingsExchanges = Form.create()(SettingsExchanges);
export default WrappedSettingsExchanges;
