import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// screens
import UserWrappedContext from '../components/UserWrappedContext';
import ReturnPortal from '../pages/portal/index';
import Auth from '../pages/auth/index';

// oAuth
import Shopify from '../pages/dashboard/integrations/authentication/shopifyPublicApp/Shopify';
import ShopifyStore from '../pages/dashboard/integrations/authentication/shopifyPublicApp/ShopifyStore';
import ShopifyStoreConfirm from '../pages/dashboard/integrations/authentication/shopifyPublicApp/ShopifyStoreConfirm';
import CustomShopify from '../pages/dashboard/integrations/authentication/shopifyCustomApp/CustomShopify';
import CustomShopifyConfirm from '../pages/dashboard/integrations/authentication/shopifyCustomApp/CustomShopifyConfirm';
import Intercom from '../pages/dashboard/integrations/authentication/intercom/Intercom';
import ShopifyBilling  from '../pages/dashboard/integrations/billing/ShopifyBilling';
import ShopifyBillingConfirm  from '../pages/dashboard/integrations/billing/ShopifyBillingConfirm';
import Plans from '../pages/dashboard/integrations/plans/Plans';
import UPS from '../pages/dashboard/integrations/authentication/ups/UpsAuth';
import AppHubSignup from '../pages/dashboard/integrations/authentication/Bolt-Ons/AppHubSignup';
import AppHubAppConfirm from '../pages/dashboard/integrations/authentication/Bolt-Ons/AppHubAppConfirm';
import AppHubSignin from '../pages/dashboard/integrations/authentication/Bolt-Ons/AppHubSignin';
import ErrorRedirect from './ErrorRedirect';
import Error404 from '../pages/error/404/index';
import Page from '../components/Page';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/login"
          render={(props) => <Page {...props} component={Auth} title="Login" />}
        />
        <Route
          path="/return"
          render={(props) => (
            <Page {...props} component={ReturnPortal} title="Returns" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/apphub/signup"
          component={AppHubSignup}
          render={(props) => (
            <Page {...props} component={AppHubSignup} title="AppHub" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/apphub/signin"
          render={(props) => (
            <Page {...props} component={AppHubSignin} title="AppHub" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/apphub/confirm"
          render={(props) => (
            <Page {...props} component={AppHubAppConfirm} title="AppHub" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/intercom"
          render={(props) => (
            <Page {...props} component={Intercom} title="Intercom" />
          )}
        />
        <Route 
          exact={ true } 
          path="/dashboard/connect/ups"
          render={(props) => (
            <Page {...props} component={UPS} title="Ups" />
          )} 
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopify"
          render={(props) => (
            <Page {...props} component={Shopify} title="Shopify" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifyappstore"
          render={(props) => (
            <Page {...props} component={ShopifyStore} title="Shopify" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifyappstore/confirm"
          render={(props) => (
            <Page {...props} component={ShopifyStoreConfirm} title="Shopify" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifybilling"
          render={(props) => (
            <Page {...props} component={ShopifyBilling} title="Shopify" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/plan"
          render={(props) => (
            <Page {...props} component={Plans} title="Plans" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifybilling/confirm"
          render={(props) => (
            <Page
              {...props}
              component={ShopifyBillingConfirm}
              title="Shopify"
            />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifycustom"
          render={(props) => (
            <Page {...props} component={CustomShopify} title="Shopify" />
          )}
        />
        <Route
          exact={true}
          path="/dashboard/connect/shopifycustom/confirm"
          render={(props) => (
            <Page {...props} component={CustomShopifyConfirm} title="Shopify" />
          )}
        />
        <Route path="/dashboard" component={UserWrappedContext} />
        <Route
          exact={true}
          path="/"
          render={(props) => <Page {...props} component={Auth} title="Auth" />}
        />
        <Route
          exact={true}
          path="/error-404"
          render={(props) => (
            <Page {...props} component={Error404} title="404 Error" />
          )}
        />
        <Route path="*" component={ErrorRedirect} />
      </Switch>
    );
  }
}

export default App;
