/**
 * Component for our main Navigation sidebar
 * in the dashboard.
 *
 * @component
 */

import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon, Tag, Button, message } from "antd";
import { SubscriptionContext } from "../../../contexts/SubscriptionContext";

const { SubMenu } = Menu;
const Navigation = withRouter((props) => {
  const context = useContext(SubscriptionContext);
  const { location } = props;

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["/dashboard"]}
      defaultOpenKeys={["returns"]}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/dashboard" className="navReturns">
        <Link to="/dashboard">
          <span>
            <Icon type="check-circle" />
            <span>Returns</span>
          </span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub1"
        title={
          <span>
            <Icon type="line-chart" />
            <span>Analytics</span>
          </span>
        }
      >
        <Menu.Item key="/dashboard/analytics/dashboard">
          <Link to="/dashboard/analytics/dashboard">
            <span>Dashboard</span>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="/dashboard/analytics/reports/reasons/by-product"
          className="navAnalytics"
        >
          <Link to="/dashboard/analytics/reports/reasons/by-product">
            <span>Reports</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/dashboard/analytics/trends/return-volume"
          className="navAnalytics"
        >
          <Link to="/dashboard/analytics/trends/return-volume">
            <span>Trends</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/analytics/customer">
          <Link to="/dashboard/analytics/customer">
            <span>Customers <Tag color="gold">new</Tag></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/analytics/export" className="navAnalytics">
          <Link to="/dashboard/analytics/export">
            <span>Export</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="brand-settings"
        title={
          <span>
            <Icon type="shopping" />
            <span>Brand settings</span>
          </span>
        }
      >
        <Menu.Item key="/dashboard/brand" className="navBrand">
          <Link to="/dashboard/brand">
            <span>General</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/brand/custom-domain">
          <Link to="/dashboard/brand/custom-domain">
            <span>Custom Domain <Tag color="gold">new</Tag></span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="return-settings"
        title={
          <span>
            <Icon type="reload" />
            <span>Return settings</span>
          </span>
        }
      >
        <Menu.Item key="/dashboard/settings" className="navSettingsGeneral">
          <Link to="/dashboard/settings">
            <Icon type="rollback" />
            <span>General</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/exchanges" className="navSettingsExchanges">
          <Link to="/dashboard/settings/exchanges">
            <Icon type="swap" />
            <span>Exchanges <Tag color="gold">new</Tag></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/rules" className="navSettingsRules">
          <Link to="/dashboard/settings/rules">
            <Icon type="stop" />
            <span>Advanced Rules</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/sync" className="navSettingsRules">
          <Link to="/dashboard/settings/sync">
            <Icon type="thunderbolt" />
            <span>Shopify Sync <Tag color="gold">new</Tag></span>
          </Link>
        </Menu.Item>        
        <Menu.Item key="/dashboard/settings/refunds" className="navSettingsRefunds">
          <Link to="/dashboard/settings/refunds">
            <Icon type="dollar" />
            <span>Refunds</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/incentives" className="navSettingsPayment">
          <Link to="/dashboard/settings/incentives">
            <Icon type="gift" />
            <span>Incentives <Tag color="gold">new</Tag></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/payment" className="navSettingsPayment">
          <Link to="/dashboard/settings/payment">
            <Icon type="dollar" />
            <span>Payment</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub-email"
        title={
          <span>
            <Icon type="mail" />
            <span>Email settings</span>
          </span>
        }
      >
        <Menu.Item key="/dashboard/email/admin" className="navEmailAdmin">
          <Link to="/dashboard/email/admin">
            <span>Staff</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/email" className="navEmail">
          <Link to="/dashboard/email">
            <span>Customers</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/dashboard/translations" className="navReturns">
        <Link to="/dashboard/translations">
          <span>
            <Icon type="compass"/>
            <span>Translations</span>
          </span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub-account"
        title={
          <span>
            <Icon type="home" />
            <span>Account</span>
          </span>
        }
      >
        {context && context.directBilling ? (
          <Menu.Item key="/dashboard/account/plan" className="navAccount">
            <Link to="/dashboard/account/plan">
              <Icon type="star" />
              <span>Your Plan</span>
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item key="/dashboard/account" className="navAccount">
            <Link to="/dashboard/account">
              <Icon type="star" />
              <span>Your Plan</span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="/dashboard/connect" className="navConnect">
          <Link to="/dashboard/connect">
            <Icon type="thunderbolt" />
            <span>Integrations</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/settings/api" className="navApi">
          <Link to="/dashboard/settings/api">
            <Icon type="branches" />
            <span>Developers</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/audit-log" className="navReturns">
          <Link to="/dashboard/audit-log">
            <Icon type="history" />
            <span>Audit Log</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/logout" className="navReturns">
          <Link to="/dashboard/logout">
            <Button 
              size="small" 
              icon="logout"
            >
              Logout
            </Button>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/dashboard/tutorials" className="navTutorials">
        <Icon type="video-camera" style={{ fontSize: 18, color: 'var(--rrPrimary)' }} />
        <span style={{ color: 'var(--rrPrimary)' }}>
          <strong>
            <a
              href="https://support.apphub.com/en/collections/3507240-rich-returns"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorials & FAQ
            </a>
          </strong>
        </span>
      </Menu.Item>
    </Menu>
  );
});

export default Navigation;
