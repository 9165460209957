/**
 * Component to get the details for Returns Package Dimension
 *
 * @component
 */

import React, { Component } from "react";
import { Row, Col, Select, Input } from "antd";

const { Option } = Select;
class ReturnLabelDimensions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Package dimensions</h4>
            <span>
              Enter the dimensions of your most used box. Either use the
              metrical system (centimeter) or imperial system (inch). Use either
              a full number (e.g. 30) or a point for a decimal (e.g. 10.50). Do
              NOT use a comma.
            </span>
          </Col>
          <Col span={10}>
            <Select
              style={{ width: "100%" }}
              defaultValue={this.props.labelParcelDimensionsUnit}
              name="labelParcelDimensionsUnit"
              onChange={(value) =>
                this.props.handleChange(
                  "labelParcelDimensionsUnit",
                  value
                )
              }
              placeholder="Select unit (cm or inch)"
            >
              <Option value="cm">cm</Option>
              <Option value="in">inch</Option>
            </Select>

            <Input
              style={{ marginTop: "4%" }}
              addonBefore="Length"
              name="labelParcelLength"
              value={this.props.labelParcelLength}
              onChange={(e) =>
                this.props.handleChange(
                  "labelParcelLength",
                  e.target.value
                )
              }
            />

            <Input
              style={{ marginTop: "4%" }}
              addonBefore="Width"
              name="labelParcelWidth"
              value={this.props.labelParcelWidth}
              onChange={(e) =>
                this.props.handleChange(
                  "labelParcelWidth",
                  e.target.value
                )
              }
            />

            <Input
              style={{ marginTop: "4%" }}
              addonBefore="Height"
              name="labelParcelHeight"
              value={this.props.labelParcelHeight}
              onChange={(e) =>
                this.props.handleChange(
                  "labelParcelHeight",
                  e.target.value
                )
              }
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ReturnLabelDimensions;
