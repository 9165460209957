/**
 * Component to display our Return Reason  Table.
 *
 * @component
 */

import React, { Component } from "react";
import { Table, Popconfirm, Tag, Button } from "antd";

class ReturnReasonTable extends Component {
  constructor(props) {
    super(props);
  }

  getColumns = () => {
    const { featureMultilangReturnReasons } = this.props;
    const columns = [];

    if (featureMultilangReturnReasons) {
      columns.push(
        {
          title: "Return Reason",
          dataIndex: "reason",
          key: "reason",
        },
        {
          title: "Language",
          dataIndex: "defaultLanguage",
          key: "defaultLanguage",
          render: (defaultLanguage) => <Tag>{defaultLanguage}</Tag>,
        },
        {
          title: "Translations",
          dataIndex: "translations",
          key: "translations",
          render: (translations) => translations.map(translation => <><Tag>{translation.language}</Tag></>
          ),
        },
        {
          title: <span style={{marginLeft:15}}>Action</span>,
          key: "operation",
          render: (_, data) => this.renderActions(data),
        },
      );
    } else {
      columns.push(
        {
          width: 170,
          title: "Return Reason",
          dataIndex: "reason",
          key: "reason",
        },
        {
          width: 100,
          title: <span style={{marginLeft:15}}>Action</span>,
          key: "operation",
          render: (_, data) => this.renderActions(data),
        },
      );
    }

    return columns;
  };

  renderActions = (data) => (
    <React.Fragment>
      {this.props.featureMultilangReturnReasons &&
        <Button
          type="link" 
          onClick={() =>
            this.props.toggleReturnReasonInputModal("addTranslation", data)
          }
        >
          Add translation
        </Button>
      }
      <Button type="link" onClick={() => this.props.toggleReturnReasonInputModal("editReturnReason", data)}>
        Edit
      </Button>{" "}
      <Popconfirm
        title="Are you sure you want to delete this?"
        onConfirm={() => this.props.handleDelete("mainTableRowDeletion", data)}
        okText="Yes"
        cancelText="Cancel"
      >
        <Button type="link" >Delete</Button>
      </Popconfirm>
    </React.Fragment>
  );

  render() {
    const expandedRowRender = (row) => {
      const nestedColumns = [
        {
          title: "Language",
          dataIndex: "language",
          key: "language",
          render: (language) => <Tag>{language}</Tag>,
        },
        {
          title: "Translation",
          dataIndex: "translation",
          key: "translation",
        },
        {
          title: "Action",
          key: "operation",
          render: (value, data) => (
            <React.Fragment>
              <Button
                type="link"
                onClick={() =>
                  this.props.toggleReturnReasonInputModal(
                    "editTranslation",
                    data
                  )
                }
              >
                Edit
              </Button>{" "}
              <Popconfirm
                title="Are you sure you want to delete this ?"
                onConfirm={() =>
                  this.props.handleDelete("nestedTableRowDeletion", data)
                }
                okText="Yes"
                cancelText="Cancel"
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            </React.Fragment>
          ),
        },
      ];
      const filterSelectedRowData = this.props.returnReason.find(
        (data) => data.id === row.key
      );
      const nestedTableData = filterSelectedRowData.translations.map(
        (translation, index) => ({
          key: index,
          language: translation.language,
          translation: translation.translation,
          id: row.key,
        })
      );
      return (
        <Table
          columns={nestedColumns}
          dataSource={nestedTableData}
          pagination={{ defaultPageSize: 5 }}
        />
      );
    };

    const mainTableData = this.props.returnReason.map((data) => ({
      key: data.id,
      defaultLanguage: data.defaultLanguage,
      reason: data.reason,
      translations: data.translations,
      id: data.id,
    }));

    return (
      <Table
        className="components-table-demo-nested"
        columns={this.getColumns()}
        expandedRowRender={this.props.featureMultilangReturnReasons && expandedRowRender}
        dataSource={mainTableData}
        pagination={{ defaultPageSize: 10 }}
      />
    );
  }
}

export default ReturnReasonTable;