/**
 * Component to get details for shopify sync.
 *
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Divider, Form, Input, message, Button, Alert, Checkbox, Select } from "antd";
import { trackFSEvent } from "../../../../helpers/fullstory";
const { Option } = Select;
const FormItem = Form.Item;

class ShopifySync extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      syncReturnCreatedAddOrderTag: undefined,
      syncReturnCreatedAddOrderNote: undefined,
      syncReturnCreatedAddMetafieldReturnNumber: undefined,
      syncLabelCreatedAddMetafieldLabelNumber: undefined,
      syncLabelCreatedAddMetafieldLabelTrackingURL: undefined,
      syncLabelCreatedAddMetafieldLabelFileURL: undefined,
      shopifyReturnDataSync: undefined,
      shopifyReturnDataSyncStatus: "Approved"
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          "There is something wrong with the data you entered. Please check again.",
          5
        );
      }
      try {
        const response = await axios.post("/api/returnSettings/update", {
          ...values,
        });
        if (response.data.status === "error") {
          return message.error(
            "Something went wrong. Please try again.",
            5
          );
        }
        if (values.syncReturnCreatedAddOrderTag !== this.state.syncReturnCreatedAddOrderTag) {
          trackFSEvent("Add a Order Tag", {
            feature: "Shopify Sync",
            orderTag: values.syncReturnCreatedAddOrderTag || ''
          });
        }
        if (values.syncReturnCreatedAddOrderNote !== this.state.syncReturnCreatedAddOrderNote) {
          trackFSEvent("Add a Order Note", {
            feature: "Shopify Sync",
            orderTag: values.syncReturnCreatedAddOrderNote || ''
          });
        }
        trackFSEvent("Change Shopify Sync Settings", {
          feature: "Shopify Sync",
          addReturnNumberRMAAsMetafieldToOriginalOrder: values.syncReturnCreatedAddMetafieldReturnNumber || false,
          addReturnLabelNumberAsMetafieldToOriginalOrder: values.syncLabelCreatedAddMetafieldLabelNumber || false,
          addReturnLabelTrackingURLAsMetafieldToOriginalOrder: values.syncLabelCreatedAddMetafieldLabelTrackingURL || false,
          addTheReturnLabelFileURLAsMetafieldtoOriginalOrder: values.syncLabelCreatedAddMetafieldLabelFileURL || false,
          enableShopifyReturnTracking: values.shopifyReturnDataSync || false,
          shopifyReturnTrackingStatus: values.shopifyReturnDataSyncStatus || false,
        });
        this.setState({
          ...response.data,
        });
        return message.success("successfully updated.", 5);
      } catch (err) {
        return message.error(
          "Something went wrong. Please try again.",
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="pageTitle">Shopify Sync</h1>
            <p>Sync Return details to your Shopify Store</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={22}>
            <Alert
              message="Important"
              description={
                <span>
                  The new Shopify API allows us to mark items in an order as “Return in Progress” and “Returned”. We've rolled out this feature and you can activate it by checking the <b>Enable Shopify Return Tracking</b>.
                </span>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <h2>When a Return is created...</h2>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}

          >
            <Col span={10}>
              <h4>Add a tag to the order</h4>
              <span>
                Adds a tag to the order. Leave empty to
                not add a tag. You can use ORDER_NAME, RMA_NUMBER, RETURN_METHOD, RETURN_ACTION and
                DATE_TODAY as placeholders to insert dynamic values.<br />
                <b>Note:</b> The limit set by Shopify for tags is 40 characters. Any tags that exceed this limit will be ignored by Shopify and the order will not be tagged.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator("syncReturnCreatedAddOrderTag", {
                  initialValue: this.state.syncReturnCreatedAddOrderTag,
                })(
                  <Input
                    addonBefore="Order Tag"
                    placeholder="e.g. RETURN STARTED"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Add a note to the order</h4>
              <span>
                Adds or appends a note to the order. Leave empty to
                not add a note. You can use ORDER_NAME, RMA_NUMBER, RETURN_METHOD, RETURN_ACTION and
                DATE_TODAY as placeholders to insert dynamic values.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator("syncReturnCreatedAddOrderNote", {
                  initialValue: this.state.syncReturnCreatedAddOrderNote,
                })(
                  <Input
                    addonBefore="Order Note"
                    placeholder="e.g. Return RMA_NUMBER requested by customer on DATE_TODAY."
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Add Return-Number (RMA) as Metafield to Original Order</h4>
              <span>
                The Return-Number (RMA) will be added to the original Shopify Order as a Metafield. Using Shopify’s Metafield-Viewer, this can be displayed directly in the Shopify Order details page.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('syncReturnCreatedAddMetafieldReturnNumber', {
                })(<Checkbox checked={this.state.syncReturnCreatedAddMetafieldReturnNumber} onChange={(e) => this.handleChange(e)}>Return Number</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <h2>Return Label is created…</h2>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Add Return-Label Number as Metafield to Original Order</h4>
              <span>
                The Return-Label Number will be added to the original Shopify Order as a Metafield. Using Shopify’s Metafield-Viewer, this can be displayed directly in the Shopify Order details page.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('syncLabelCreatedAddMetafieldLabelNumber', {
                })(
                  <Checkbox checked={this.state.syncLabelCreatedAddMetafieldLabelNumber} onChange={(e) => this.handleChange(e)}>Return-Label Number</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <h2></h2>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Add Return-Label Tracking URL as Metafield to Original Order</h4>
              <span>
                The Return-Label Tracking URL will be added to the original Shopify Order as a Metafield. Using Shopify’s Metafield-Viewer, this can be displayed directly in the Shopify Order details page.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('syncLabelCreatedAddMetafieldLabelTrackingURL', {
                })(
                  <Checkbox checked={this.state.syncLabelCreatedAddMetafieldLabelTrackingURL} onChange={(e) => this.handleChange(e)}>Return-Label Tracking</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <h2></h2>
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Add the Return-Label File URL as Metafield to Original Order</h4>
              <span>
                The Return-Label File URL will be added to the original Shopify Order as a Metafield. Using Shopify’s Metafield-Viewer, this can be displayed directly in the Shopify Order details page.
              </span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('syncLabelCreatedAddMetafieldLabelFileURL', {
                })(
                  <Checkbox checked={this.state.syncLabelCreatedAddMetafieldLabelFileURL} onChange={(e) => this.handleChange(e)}>Return-Label File</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <h4>Enable Shopify Return Tracking</h4>
              <span>Check this box if you wish to update the status of your order in Shopify. This allows for greater visibility on Shopify and it is highly recommended.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('shopifyReturnDataSync', {})(
                  <Checkbox checked={this.state.shopifyReturnDataSync} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10} style={{ marginTop: "11px" }}>
              <span>Choose a status when we start the return on the original order in Shopify.</span>
            </Col>
            <Col span={10}>
              <FormItem label="Select Status" >
                {getFieldDecorator('shopifySyncCreateStatus', {
                  initialValue: this.state.shopifySyncCreateStatus
                })(
                  <Select
                    disabled={!this.state.shopifyReturnDataSync}
                  >
                    <Option value="Pending">Pending</Option>
                    <Option value="Approved">Approved</Option>
                    <Option value="Shipped">Shipped</Option>
                    <Option value="Received">Received</Option>
                    <Option value="Resolved">Resolved</Option>
                    <Option value="Rejected">Rejected</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10} style={{ marginTop: "11px" }}>
              <span>Choose a status when we close the return on the original order in Shopify.</span>
            </Col>
            <Col span={10}>
              <FormItem label="Select Status" >
                {getFieldDecorator('shopifyReturnDataSyncStatus', {
                  initialValue: this.state.shopifyReturnDataSyncStatus
                })(
                  <Select
                    disabled={!this.state.shopifyReturnDataSync}
                  >
                    <Option value="Approved">Approved</Option>
                    <Option value="Shipped">Shipped</Option>
                    <Option value="Received">Received</Option>
                    <Option value="Resolved">Resolved</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <span>Exclude exchange. </span>
              <span>If you check this box, we will not send related information for any of your exchanges in Shopify. This means that a return will not be started on these orders. We recommend checking this box if you are offering Exchanges and you’re creating free exchanges.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('shopifySyncExcludeExchange', {})(
                  <Checkbox checked={this.state.shopifySyncExcludeExchange} onChange={(e) => this.handleChange(e)} disabled={!this.state.shopifyReturnDataSync}>Enable</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            className="dashboard-content"
            style={{ paddingTop: 20 }}
          >
            <Col span={10}>
              <span>Exclude Store Credit. </span>
              <span>If you check this box, we will not send related information for any of your store credit in Shopify. This means that a return will not be started on these orders. We recommend checking this box if you are offering Store credit and you’re creating free store credit.</span>
            </Col>
            <Col span={10}>
              <FormItem>
                {getFieldDecorator('shopifySyncExcludeStoreCredit', {})(
                  <Checkbox checked={this.state.shopifySyncExcludeStoreCredit} onChange={(e) => this.handleChange(e)} disabled={!this.state.shopifyReturnDataSync}>Enable</Checkbox>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="dashboard-content"
          >
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedShopifySync = Form.create()(ShopifySync);
export default WrappedShopifySync;
