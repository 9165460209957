/**
 * Component for Custom domain settings page in the Dashboard
 * that is part of the Brand Settings.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Button, Divider, Result, Tag, message } from "antd";

import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";

class CustomDomain extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      customDomain: null,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/brandSettings");
      if (response.data.status === "error") {
        return message.error(
          "Error retrieving your brand settings. Try again.",
          5
        );
      }
      if (response.data == null) {
        return;
      }
      return this.setState({
        customDomain: response.data.customDomain,
      });
    } catch (err) {
      message.error("Error retrieving your brand settings. Try again.", 5);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Custom Domain</h1>
            <p>Set up a custom domain for your Returns Portal.</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        {this.context.featureCustomDomain ? (
          <React.Fragment>
            {this.state.customDomain ? (
              <Result
                status="success"
                title="Your Custom Domain is active!"
                subTitle={<span>You can find your Returns Portal here: <a href={"https://" + this.state.customDomain} target="_blank" rel="noopener noreferrer">{this.state.customDomain}</a></span>}
              />
            ) : (
              <Result
                status="warning"
                title="Custom domain has not been set up. Please reach out to us to start the process."
                extra={
                  <React.Fragment>
                    <p>Use the HELP button on the left to get in touch.</p>
                    <Button
                      type="primary"
                      href="mailto:hello@richcommerce.co"
                      key="contact-us"
                    >
                    Or click here to Contact Us via email
                    </Button>
                  </React.Fragment>
                }
              />
            )}
          </React.Fragment>
        ) : (
          <Upgrade
            title={<span>Add a custom Domain to your Returns Portal like<br/><Tag color="gold">returns.brand.com</Tag> <Tag color="gold">or</Tag> <Tag color="gold">exchanges.brand.com</Tag> <Tag color="gold">or any other term</Tag></span>}
            description="Upgrade to a plan that supports Custom Domains."
           
          />
        )}
      </React.Fragment>
    );
  }
}

export default CustomDomain;
