/**
 * Main Component for our Analytics Graphs that loads
 * all different Graph types. Also includes a Feature-
 * Gate for this feature.
 * 
 * @component
 */

import React from "react";
import {
  DatePicker,
  Divider,
  Row,
  Col,
  Spin,
  message,
  Tooltip,
  Icon,
} from "antd";
import axios from "axios";
import moment from "moment";
import { Area } from "@ant-design/charts";
import Upgrade from "../../../../components/Upgrade";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";

const { RangePicker } = DatePicker;

// testing visualization color
const config = {
  areaStyle: function areaStyle() {
    return { fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff" };
  },
};

class VolumeGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: undefined,
      data: [],
    };
  }
  static contextType = SubscriptionContext;

  componentDidMount() {
    this.handleSubmit();
  }

  async handleSubmit() {
    if (this.context && this.context.featureAnalyticsAdvanced === false) {
      return;
    }
    
    this.setState({
      loading: true,
    });

    if (!this.props.startDate || !this.props.endDate) {
      this.setState({
        loading: false,
      });
      return message.error("Select a start and end date.", 4);
    }
    try {
      const response = await axios.post(this.props.url, {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });

      if (response.data.error) {
        return message.error("Error fetching graph's data from server.", 4);
      }

      return this.setState({
        data: response.data.data,
        loading: false,
      });
    } catch (err) {
      message.error("Error fetching graph's data.", 4);
    }
  }

  onChange(dates, dateStrings) {
    this.props.updateDate(dateStrings)
    this.setState(
      {
        checkboxDisabled: false,
      },
      () => {
        this.handleSubmit();
      }
    );
  }

  render() {
    const { date } = this.state;

    const disabledDate = (current) => {
      if (!date) {
        return false;
      }
      const tooLate = date && current.diff(date, "days") > 366;
      const tooEarly = date && date.diff(current, "days") > 45;
      return tooEarly || tooLate;
    };

    return (
      <React.Fragment>
        {
          this.context.featureAnalyticsAdvanced == null
            ? (
              <Spin
                spinning={true}
                indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
              />
            )
            : (
              <React.Fragment>
                <Row
                  type="flex"
                  justify="start"
                  align="top"
                >
                  <Col>
                    <h1 className="pageTitle">
                      <Tooltip title={this.props.tooltip}>
                        <Icon
                          style={{ fontSize: "16px", alignItems: "center" }}
                          type="info-circle"
                        />
                      </Tooltip>{" "}
                      {this.props.title}
                    </h1>
                  </Col>
                </Row>
                {this.context.featureAnalyticsAdvanced ? (
                  <React.Fragment>
                    <Row type="flex" justify="center" align="middle">
                      <Col>
                        <Spin spinning={this.state.loading}>
                          <Row type="flex" justify="center" align="middle">
                            <RangePicker
                              defaultValue={[moment(this.props.startDate), moment(this.props.endDate)]}
                              ranges={{
                                Today: [moment(), moment()],
                                "This Month": [
                                  moment().startOf("month"),
                                  moment().endOf("month"),
                                ],
                                "Last Month": [
                                  moment().subtract(1, "month").startOf("month"),
                                  moment().subtract(1, "month").endOf("month"),
                                ],
                                "Year to Date": [moment().startOf('year'), moment()],
                                "Last Year": [moment().subtract(1, "year").startOf('year'), moment().subtract(1, "year").endOf('year')],
                              }}
                              onChange={this.onChange.bind(this)}
                              disabledDate={disabledDate}
                              onCalendarChange={(dateArray) => {
                                const endDateSelected = dateArray[1] != null;
                                if (endDateSelected) {
                                  return this.setState({
                                    date: undefined,
                                  });
                                }

                                const startDateSelected = dateArray[0];
                                if (startDateSelected) {
                                  this.setState({
                                    date: dateArray[0],
                                  });
                                }
                              }}
                            />
                          </Row>
                        </Spin>
                      </Col>
                    </Row>

                    <Divider style={{ marginTop: 30, marginBottom: 30 }}>
                      Select a start and end date
                    </Divider>

                    <Row
                      type="flex"
                      justify="center"
                      align="middle"
                      style={{ paddingTop: 20 }}
                    >
                      <Col span={24}>
                        <Area
                          data={this.state.data}
                          {...this.props.config}
                          {...config}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <Row type="flex" justify="space-around" align="top">
                    <Col span={24}>
                      <Divider />
                    </Col>
                    <Col span={24}>
                      <Upgrade
                        title="Business Intelligence at your fingertips."
                        description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                        videoId="VjbdXeknVMM"
                      />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )
        }
      </React.Fragment>
    );
  }
}

export default VolumeGraph;
