/**
 * Component to get details of Empty Box Weight
 *
 * @component
 */

import React, { Component } from "react";
import { Row, Col, Select, Input} from "antd";

const { Option } = Select;

class ReturnLabelWeight extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Empty box weight</h4>
            <span>
              Enter the default weight of your most used EMPTY box. Product
              weight is calculated dynamically for each return on top of the box
              weight. Either use the metrical system (grams) or imperial system
              (pounds). Use either a full number (e.g. 500) or a point for a
              decimal (e.g. 1.30). Do NOT use a comma.
            </span>
          </Col>
          <Col span={10}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select unit (g or lb)"
              name="labelParcelWeightUnit"
              defaultValue={this.props.labelParcelWeightUnit}
              onChange={(value) =>
                this.props.handleChange(
                  "labelParcelWeightUnit",
                  value
                )
              }
            >
              <Option value="g">Gram (g)</Option>
              <Option value="lb">Pound (lb)</Option>
            </Select>
            <span> </span>
            <Input
              style={{ marginTop: "4%" }}
              addonBefore="Weight"
              name="labelParcelDefaultWeigth"
              value={this.props.labelParcelDefaultWeigth}
              onChange={(e) =>
                this.props.handleChange(
                  "labelParcelDefaultWeigth",
                  e.target.value
                )
              }
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ReturnLabelWeight;
