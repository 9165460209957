/**
 * Component for our Analytics Customer page
 * used to show the comments of a customer on returned products
 * @component
*/

import React, { Component } from "react";
import axios from "axios";
import { Table, Select, Tooltip, Icon, Row, message, Col, Divider } from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";

const { Option } = Select;
class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  static contextType = SubscriptionContext;

  async componentDidMount() {
    if (this.props.customerId) {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${this.props.customerId}/comments`
        );
        const products = [...response.data.data];
        this.setState({
          data: products,
        });
        
      } catch (err) {
        return message.error(
          "something went wrong in getting the customer details",
          5
        );
      }
    }
  }

  render() {
    const onChange = async (value) => {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${value}/comments`
        );
        const products = [...response.data.data];
        this.props.updateCustomerDetails(value);
        this.setState({
          data: products,
        });
      } catch (err) {
        return message.error(
          "something went wrong in getting the customer details",
          5
        );
      }
    };
    const dataSource = this.state.data;
    const columns = [
      {
        title: "Order Name",
        dataIndex: "Return.orderName",
      },
      {
        title: "Return Number",
        dataIndex: "Return.returnNumber",
      },
      {
        title: "Product Name",
        dataIndex: "productName",
      },
      {
        title: "Variant Name",
        dataIndex: "variantName",
      },
      {
        title: "SKU",
        dataIndex: "sku",
      },
      {
        title: "Comment",
        dataIndex: "notes",
      },
    ];

    return (
      <React.Fragment>
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row type="flex" justify="start" align="top">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="This report shows customer comments on returned products."
                >
                  <Icon style={{ paddingTop: "13px" }} type="info-circle" />
                </Tooltip>
              </Col>
              <Col>
                &nbsp;
                <span className="pageTitle">Customer Comments</span>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 5 }}
            >
              <Tooltip
                placement="bottom"
                title="Search for a customer by using their first name, last name or email address. Only customers with at least one Return in Rich Returns will show up as a result."
              >
                <Icon type="info-circle" />
              </Tooltip>
              &nbsp;
              <Select
                showSearch
                value={this.props.customerNameEmail}
                style={{ width: 350 }}
                placeholder="Search for a customer"
                onChange={onChange}
                onSearch={(value)=>this.props.onSearch(value)}
                filterOption={false}
              >
                {this.props.matchingCustomers &&
                this.props.matchingCustomers.length > 0
                  ? this.props.matchingCustomers.map((value) => {
                    return (
                      <Option value={value.customerId}>
                        {`${value.shippingName} (${value.customerEmail})`}
                      </Option>
                    );
                  })
                  : null}
              </Select>
            </Row>
            <br />
            <Table dataSource={dataSource} columns={columns} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Comments;
