/**
 * Component for our Analytics section displaying customer-related Analytics data.
 *
 * @component
 */

import React from "react";
import {
  Select,
  Divider,
  Row,
  Col,
  Spin,
  Card,
  Tooltip,
  Icon,
  message,
} from "antd";
import axios from "axios";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";

const { Option } = Select;

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      numberOfTotalReturns: "-",
      numberOfTotalProducts: "-",
      averageProductsPerReturn: "-",
      totalReturnValue: "-",
      averageReturnValue: "-",
      numberOfOrders: "-",
      returnRate: "-",
      currency: "-",
      numberOfCompletedReturns: "-",
      valueOfCompletedReturns: "-",
      numberOfRefundToOriginal: "-",
      valueOfRefundToOriginal: "-",
      rateOfRefundToOriginal: "-",
      numberOfRefundToExchange: "-",
      valueOfRefundToExchange: "-",
      rateOfRefundToExchange: "-",
      numberOfRefundToCredit: "-",
      valueOfRefundToCredit: "-",
      rateOfRefundToCredit: "-",
    };
  }

  static contextType = SubscriptionContext;

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const customerId = urlObject.searchParams.get("customerId");
    if (this.props.customerId || customerId ) {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${customerId ? customerId : this.props.customerId}`
        );

        if (customerId) {
          const { shippingNameEmailAddress } = response.data;
          this.props.updateCustomerDetails(customerId, shippingNameEmailAddress);
        }
        return this.setState({
          ...response.data,
          loading: false,
        });
      } catch (err) {
        return message.error(
          "something went wrong in getting the customer details",
          5
        );
      }
    }
  }

  render() {
    const onChange = async (value) => {
      this.setState({
        loading: true,
      });
      try {
        const response = await axios.get(`/api/analytics/customers/${value}`);
        this.props.updateCustomerDetails(value);
        return this.setState({
          ...response.data,
          loading: false,
        });
      } catch (err) {
        this.setState({
          loading: false,
        });
        return message.error(
          "something went wrong in getting the customer details",
          5
        );
      }
    };
    return (
      <React.Fragment>
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <Row type="flex" justify="start" align="top">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Shows Returns-Analytics for a specific customer."
                >
                  <Icon style={{ paddingTop: "13px" }} type="info-circle" />
                </Tooltip>
              </Col>
              <Col>
                &nbsp;
                <span className="pageTitle">Customer Analytics</span>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 5 }}
            >
              <Tooltip
                placement="bottom"
                title="Search for a customer by using their first name, last name or email address. Only customers with at least one Return in Rich Returns will show up as a result."
              >
                <Icon type="info-circle" />
              </Tooltip>
              &nbsp;
              <Select
                showSearch
                style={{ width: 350 }}
                value={this.props.customerNameEmail}
                placeholder="Search for a customer"
                onChange={onChange}
                onSearch={(value)=>this.props.onSearch(value)}
                filterOption={false}
              >
                {this.props.matchingCustomers &&
                this.props.matchingCustomers.length > 0
                  ? this.props.matchingCustomers.map((value) => {
                    return (
                      <Option value={value.customerId}>
                        {`${value.shippingName} (${value.customerEmail})`}
                      </Option>
                    );
                  })
                  : null}
              </Select>
            </Row>

            <Spin
              spinning={this.state.loading}
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            >
              <Row
                gutter={10}
                style={{ marginTop: "20px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Return Rate"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of orders with a subsequent return divided by the number of orders."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.returnRate}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Return Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of Returns created."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfTotalReturns}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Order Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of Orders received."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfOrders}
                  </Card>
                </Col>
              </Row>
              <Row
                gutter={10}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Return Value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of returned items."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.totalReturnValue} {this.state.currency}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Average return value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of returned items divided by the number of returns."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.averageReturnValue} {this.state.currency}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Completed Return Value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of returned items that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.valueOfCompletedReturns} {this.state.currency}
                  </Card>
                </Col>
              </Row>
              <Row
                gutter={10}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Returned Items"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items returned."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfTotalProducts}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Average items per return"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items returned divided by the number of returns."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.averageProductsPerReturn}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Completed Return Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of returns that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfCompletedReturns}
                  </Card>
                </Col>
              </Row>

              <Divider style={{ marginTop: 20, marginBottom: 20 }}>
                Item based Metrics
              </Divider>
              <Row
                gutter={10}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Refund Rate"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Refund divided by number of items returned."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.rateOfRefundToOriginal}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Refund Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Refund."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfRefundToOriginal}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Refund Value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of items selected for Refund."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.valueOfRefundToOriginal} {this.state.currency}
                  </Card>
                </Col>
              </Row>
              <Row
                gutter={10}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Exchange Rate"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Exchange divided by number of items returned."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.rateOfRefundToExchange}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Exchange Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Exchange."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfRefundToExchange}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Exchange Value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of items selected for Exchange."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.valueOfRefundToExchange} {this.state.currency}
                  </Card>
                </Col>
              </Row>
              <Row
                gutter={10}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <Col span={8}>
                  <Card
                    title="Store Credit Rate"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Store-Credit divided by number of items returned."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.rateOfRefundToCredit}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Store Credit Volume"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Number of items selected for Store-Credit."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.numberOfRefundToCredit}
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Store Credit Value"
                    bordered={true}
                    hoverable={true}
                    extra={
                      <React.Fragment>
                        <Tooltip
                          placement="bottom"
                          title="Value of items selected for Store-Credit."
                        >
                          <Icon type="info-circle" />
                        </Tooltip>
                      </React.Fragment>
                    }
                  >
                    {this.state.valueOfRefundToCredit} {this.state.currency}
                  </Card>
                </Col>
              </Row>
            </Spin>

            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 20 }}
            >
              <Col>
                <h3>
                  Want to see another Metric in the dashboard? Let us know{" "}
                  <a
                    href="mailto:hello@richcommerce.co?subject=Please%20add%20this%20Number%20to%20the%20dashboard"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </h3>
              </Col>
            </Row>

            <Divider style={{ marginTop: 30, marginBottom: 30 }} />

            <Row type="flex" justify="center" align="top">
              <Col>
                <p>
                  <Icon type="exclamation-circle" /> If you encounter issues on
                  this Analytics-Page make sure to disable any ad-blockers in
                  your browser.
                </p>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Stats;
