/**
 * Component for our Analytics Graphs pages
 * including navigation and routing for different
 * graphs.
 *
 * @component
 */

import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Row, Col, Layout, Menu } from 'antd';

import ReturnVolume from './graphs/ReturnVolume';
import ExchangeVolume from './graphs/ExchangeVolume';
import RefundVolume from './graphs/RefundVolume';
import StoreCreditVolume from './graphs/StoreCreditVolume';
import AdvancedExchange from './graphs/advancedExchangeVolume';
import Overview from './graphs/Overview';
import moment from 'moment';
import ErrorRedirect from '../../../../routes/ErrorRedirect';
import Page from '../../../../components/Page';

const { Content } = Layout;

class Trends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
    };
  }

  updateDate = (dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
  };

  render() {
    const { location } = this.props;
    return (
      <Row>
        <Col span={4}>
          <Menu
            mode="inline"
            onClick={this.handleClick}
            defaultSelectedKeys={['/dashboard/analytics/trends/return-volume']}
            selectedKeys={[location.pathname]}
          >
            <Menu.ItemGroup key="return" title="Return">
              <Menu.Item key="/dashboard/analytics/trends/return-volume">
                <Link to="/dashboard/analytics/trends/return-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="refund" title="Refund">
              <Menu.Item key="/dashboard/analytics/trends/refund-volume">
                <Link to="/dashboard/analytics/trends/refund-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="exchange" title="Exchange">
              <Menu.Item key="/dashboard/analytics/trends/exchange-volume">
                <Link to="/dashboard/analytics/trends/exchange-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="store-credit" title="Store Credit">
              <Menu.Item key="/dashboard/analytics/trends/store-credit-volume">
                <Link to="/dashboard/analytics/trends/store-credit-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="Advanced-Exchange" title="Advanced Exchange">
              <Menu.Item key="/dashboard/analytics/trends/advanced-exchange-volume">
                <Link to="/dashboard/analytics/trends/advanced-exchange-volume">
                  <span>Volume</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="compare" title="Compare">
              <Menu.Item key="/dashboard/analytics/trends/overview">
                <Link to="/dashboard/analytics/trends/overview">
                  <span>Overview</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Col>
        <Col span={20}>
          <Content
            style={{
              paddingLeft: 24,
              background: '#fff',
              minHeight: 600,
            }}
          >
            <Switch>
              <Route
                exact
                path="/dashboard/analytics/trends/return-volume"
                render={() => (
                  <Page
                    component={() => (
                      <ReturnVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Return Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/refund-volume"
                render={() => (
                  <Page
                    component={() => (
                      <RefundVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Refund Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/exchange-volume"
                render={() => (
                  <Page
                    component={() => (
                      <ExchangeVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Exchange Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/store-credit-volume"
                render={() => (
                  <Page
                    component={() => (
                      <StoreCreditVolume
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Store Credit Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/advanced-exchange-volume"
                render={() => (
                  <Page
                    component={() => (
                      <AdvancedExchange
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Advanced Exchange Volume"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/trends/overview"
                render={() => (
                  <Page
                    component={() => (
                      <Overview
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Overview"
                  />
                )}
              />
              <Route path="*" component={ErrorRedirect} />
            </Switch>
          </Content>
        </Col>
      </Row>
    );
  }
}

export default Trends;
