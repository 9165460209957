/**
 * Component for displaying notes that staff left
 * on the Return.
 * 
 * @component
 */

 
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  message
} from "antd";
import axios from "axios"

const { TextArea } = Input;   
class ReturnDetailsNoteStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffNotes: this.props.staffNotes,
      notesKey: undefined,
    }
  }
  updateStaffNotesInState = (e) => {
    const staffNotes = e.target.value;
    this.setState({
      staffNotes,
      notesKey: Date.now(),
    });
  }

  saveStaffNotes = async () => {
    let staffNotes = this.state.staffNotes;

    if (staffNotes == null || staffNotes === "") {
      staffNotes = null;
    }

    try {
      const response = await axios.post("/api/returns/updateNotes", {
        staffNotes,
        returnId: this.props.returnId,
      });
      if (response.data.status === "error") {
        return message.error("There was an error saving your note. Please try again.", 4);
      }

      this.props.handleGetReturn();

      return message.success("Notes saved successfully.", 3);
    } catch(err) {
      return message.error("There was an error saving your note. Please try again.", 4);
    }
  }

  render() {
    return(
      <React.Fragment>
        <Row type="flex" justify="start" align="top" className="editReturnDetailsRow">
          <Col span={9}>
            <strong>Staff notes</strong>
          </Col>
          <Col span={15}>
            <TextArea
              rows={4}
              placeholder="Internal notes. Not visible to the customer"
              defaultValue={this.props.returnObject.staffNotes}
              onChange={ this.updateStaffNotesInState}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="top" className="editReturnDetailsRow">
          <Col span={9}>
          </Col>
          <Col span={15}>
            <Button onClick={this.saveStaffNotes.bind(this)} icon="check">Save notes</Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
   
export default ReturnDetailsNoteStaff;