/**
 * Component for our Analytics Reports pages
 * including navigation and routing for different
 * Reports.
 *
 * @component
 */

import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Row, Col, Layout, Menu, Tag } from 'antd';

import AllReasons from './reasons/AllReasons';
import ReasonsByProduct from './reasons/ByProduct';
import ReasonsByVariant from './reasons/ByVariant';
import MostReturnedProducts from './most-returned/Products';
import MostReturnedVariants from './most-returned/Variants';

import MostExchangedProducts from './most-exchanged/Products';
import ExchangedToVariant from './most-exchanged/ToVariant';
import MostExchangedVariants from './most-exchanged/Variants';
import AllComments from './comments/AllComments';
import moment from 'moment';
import LabelCost from './label/Costs';
import ErrorRedirect from '../../../../routes/ErrorRedirect';
import Page from '../../../../components/Page';

const { Content } = Layout;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
    };
  }

  updateDate = (dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    });
  };

  render() {
    const { location } = this.props;
    return (
      <Row>
        <Col span={4}>
          <Menu
            mode="inline"
            onClick={this.handleClick}
            defaultSelectedKeys={['/dashboard/reports/by-product']}
            defaultOpenKeys={['reports-menu']}
            selectedKeys={[location.pathname]}
          >
            <Menu.ItemGroup key="reasons" title="Top Reasons">
              <Menu.Item key="/dashboard/analytics/reports/reasons/by-product">
                <Link to="/dashboard/analytics/reports/reasons/by-product">
                  <span>By Product</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/reports/reasons/by-variant">
                <Link to="/dashboard/analytics/reports/reasons/by-variant">
                  <span>By Variant</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/reports/reasons/all">
                <Link to="/dashboard/analytics/reports/reasons/all">
                  <span>All Reasons</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="products" title="Most Returned">
              <Menu.Item key="/dashboard/analytics/reports/products/by-product">
                <Link to="/dashboard/analytics/reports/products/by-product">
                  <span>Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/reports/products/by-variant">
                <Link to="/dashboard/analytics/reports/products/by-variant">
                  <span>Variants</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="exchanges" title="Most Exchanged">
              <Menu.Item key="/dashboard/analytics/reports/exchanged-products/by-product">
                <Link to="/dashboard/analytics/reports/exchanged-products/by-product">
                  <span>Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/reports/exchanged-products/from-variant">
                <Link to="/dashboard/analytics/reports/exchanged-products/from-variant">
                  <span>Variants (from)</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/dashboard/analytics/reports/exchanged-products/to-variant">
                <Link to="/dashboard/analytics/reports/exchanged-products/to-variant">
                  <span>Variants (to)</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="comments" title="Comments">
              <Menu.Item key="/dashboard/analytics/reports/comments/all">
                <Link to="/dashboard/analytics/reports/comments/all">
                  <span>All Comments</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="labels" title="Labels">
              <Menu.Item key="/dashboard/analytics/reports/label/cost">
                <Link to="/dashboard/analytics/reports/label/cost">
                  <span>Label Costs</span> <Tag color="gold">new</Tag>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Col>
        <Col span={20}>
          <Content
            style={{
              paddingLeft: 24,
              background: '#fff',
              minHeight: 600,
            }}
          >
            <Switch>
              <Route
                exact
                path="/dashboard/analytics/reports/reasons/by-product"
                render={() => (
                  <Page
                    component={() => (
                      <ReasonsByProduct
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Top Reasons By Product"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/reasons/by-variant"
                render={() => (
                  <Page
                    component={() => (
                      <ReasonsByVariant
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Top Reasons By Variant"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/label/cost"
                render={() => (
                  <Page
                    component={() => (
                      <LabelCost
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Label Cost of Returns"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/reasons/all"
                render={() => (
                  <Page
                    component={() => (
                      <AllReasons
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="All Reasons from Recent Returns"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/products/by-product"
                render={() => (
                  <Page
                    component={() => (
                      <MostReturnedProducts
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Most Returned Products"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/products/by-variant"
                render={() => (
                  <Page
                    component={() => (
                      <MostReturnedVariants
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Most Returned Variants"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/exchanged-products/by-product"
                render={() => (
                  <Page
                    component={() => (
                      <MostExchangedProducts
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Most Exchanged Products"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/exchanged-products/from-variant"
                render={() => (
                  <Page
                    component={() => (
                      <MostExchangedVariants
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Most Exchanged-From Variants"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/exchanged-products/to-variant"
                render={() => (
                  <Page
                    component={() => (
                      <ExchangedToVariant
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="Most Exchanged-To Variants"
                  />
                )}
              />
              <Route
                exact
                path="/dashboard/analytics/reports/comments/all"
                render={() => (
                  <Page
                    component={() => (
                      <AllComments
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        updateDate={this.updateDate}
                      />
                    )}
                    title="All Comments from Recent Returns"
                  />
                )}
              />
              <Route path="*" component={ErrorRedirect} />
            </Switch>
          </Content>
        </Col>
      </Row>
    );
  }
}

export default Reports;
