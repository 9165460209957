/**
 * Component for Connections settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { List, Avatar, Row, Col, Button, Divider, Alert, Tag, message } from "antd";
import "../../../../styles/App.css";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import KlaviyoModal from "../../../../components/KlaviyoModal";
import GorgiasModal from "../../../../components/GorgiasModal";
import { getAppInfo } from "../../../../helpers/appInfo";
import { createShopifyUrl } from "../../../../helpers/createShopifyUrl"
import { trackFSEvent } from "../../../../helpers/fullstory";

class Connect extends React.Component {

  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      brandSettings: {},
      connectionSettings: {},
      connectionStatus: {},
      clientId: "efdd8904c3999b79312362f88fc31059",
      modalVisible: "",
      isProductionApp: false,
      env: null,
      shopifyUrl: null,
      developmentStore: null,
    };
  }

  generateShopifyUrl = async () => {
    const shopifyUrl = await createShopifyUrl('/dashboard/connect/shopify');
    this.setState({
      shopifyUrl,
    });
  }

  async fetchClientId() {
    try {
      const info = await getAppInfo()
      if (info.clientId) {
        this.setState({
          clientId: info.clientId
        });
      }
      this.setState({
        isProductionApp: info.isProductionApp,
        env: info.env
      });
    } catch (err) {
      message.error("error fetching clientId of application");
    }
  }

  modalVisible = (value) => {
    this.setState({
      modalVisible: value,
    });
  };

  componentDidMount = async () => {
    try {
      this.fetchClientId();
      this.generateShopifyUrl();

      const response = await axios.get("/api/connections");
      if (response.data.status === "error") {
        return message.error("Error loading your connection settings. Try again.", 4);
      }
      const shopifyUrl = response.data.brandSettings.storeShopifyUrl;
      if (shopifyUrl == null || shopifyUrl === "") {
        message.error("Please set your Shopify Store URL first before connecting. You'll find it under >>> Brand Settings", 10);
      }
      const connectionStatus = this.getConnectionStatus(response.data.connectionSettings);

      return this.setState({
        ...response.data,
        connectionStatus,
      });
    } catch (err) {
      message.error("Error loading your connection settings. Try again.", 4);
    }
  }

  getConnectionStatus = (connectionSettings) => {
    let connectionStatus = this.state.connectionStatus;
    connectionSettings.map((connection) => {
      connectionStatus = {
        ...connectionStatus,
        [connection.identifier]: connection.status
      }
    });
    return connectionStatus;
  }

  trackingEvent = (appName) => {
    trackFSEvent("Connect app", {
      feature: "Integrations",
      integratedApp: appName
    })
  }

  render() {

    const nonceToken = "123456";

    const url = window.location.href;
    const urlObject = new URL(url);
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;

    const oAuthRedirectUrlIntercom = `${urlProtocol}//${urlHostname}/dashboard/connect/intercom`;
    const oAuthRedirectUrlUPS = `${urlProtocol}//${urlHostname}/dashboard/connect/ups`;

    const intercomUrl = "https://app.intercom.io/oauth?client_id=6d86b2f5-d4d1-41ef-b224-44e8dd1d4615&state="
      + nonceToken
      + "&redirect_uri="
      + oAuthRedirectUrlIntercom;

    const upsUrl = `${this.state.developmentStore ? "https://wwwcie" : "https://onlinetools"}.ups.com/security/v1/oauth/authorize?`
      + "client_id=0blYFDlAbrXE2itGOCfbJfRfBWgwDq4llaCxkohBrlZcFHHz"
      + `&redirect_uri=${oAuthRedirectUrlUPS}`
      + "&response_type=code"
    let data = [

      {
        title: "Intercom",
        identifier: "intercom",
        oAuthUrl: intercomUrl,
        logo: "/logos/intercom.svg",
        description: "Connect to Intercom to integrate a return-widget directly into the Intercom messenger. Your customers and support-agents will love you.",
      },
      {
        title: <>Klaviyo <Tag color="gold">New</Tag></>,
        identifier: "klaviyo",
        logo: "/logos/klaviyo.png",
        description: "Connect Rich Returns to Klaviyo. We push information to Klaviyo each time a Return is created or updated.",
      },
      {
        title: <>Gorgias <Tag color="gold">New</Tag></>,
        identifier: "gorgias",
        logo: "/logos/gorgias.jpeg",
        description: "Connect Rich Returns to Gorgias. Put Return-information side-by-side with your customer tickets.",
      },
      {
        title: <>UPS <Tag color="gold">New</Tag></>,
        identifier: "ups",
        oAuthUrl: upsUrl,
        logo: "/logos/ups.png",
        description: "Connect to UPS to streamline your return process with seamless shipping and tracking.",
      },
    ];

    if (this.state.shopifyUrl) {
      data.unshift({
        title: "Shopify",
        identifier: "shopify",
        oAuthUrl: this.state.shopifyUrl,
        logo: "/logos/shopify.svg",
        description: "Connect your Shopify store to enable returns. Greatly improve customer satisfaction and reduce agent support time for returns.",
      });
    }

    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top" style={{ paddingBottom: 35 }}>
          <Col>
            <h1 className="pageTitle">Connect services</h1>
            <p>Offer an awesome & streamlined return process by connecting these great services.</p>
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle">
          <Col span={14}>
            <Alert
              message="Looking for Return Labels?"
              description={<span>Head over to <strong>Return settings / General / Return methods / Configure</strong> to set up the integration with one of our supported shipping platforms and carriers.</span>}
              type="info"
              showIcon
            />
          </Col>
        </Row>

        <Row type="flex" justify="center" align="top">
          <Col span={18}>
            <Divider />
          </Col>
        </Row>

        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar shape="square" src={item.logo} />}
                title={item.title}
                description={item.description}
              />
              {
                (this.state.connectionStatus[item.identifier] === "connected")
                  ? (
                    <React.Fragment>
                      <span>Connected <Avatar icon="check" style={{ backgroundColor: '#52c41a', marginRight: 10 }} /></span>
                      {item.identifier === "klaviyo" || item.identifier === "gorgias" ?
                        <Button type="primary" onClick={() => this.modalVisible(item.identifier)}>{"Update"}</Button> :
                        <Button type="primary" href={item.oAuthUrl} onClick={() => { this.trackingEvent(item.identifier) }}>{"Update"}</Button>
                      }
                    </React.Fragment>
                  )
                  : (

                    item.oAuthUrl ?
                      <Button type="primary" href={item.oAuthUrl} onClick={() => { this.trackingEvent(item.identifier) }}>{"Connect"}</Button> :
                      <Button type="primary" onClick={() => this.modalVisible(item.identifier)}>{"Connect"}</Button>
                  )
              }
            </List.Item>
          )}
        />
        <Divider />
        {this.state.modalVisible === "klaviyo" ?
          <KlaviyoModal
            modalForKlaviyoVisible={this.state.modalVisible === "klaviyo"}
            modalVisible={this.modalVisible}
          /> :
          null
        }
        {this.state.modalVisible === "gorgias" ?
          <GorgiasModal
            modalForGorgiasVisible={this.state.modalVisible === "gorgias"}
            modalVisible={this.modalVisible}
          /> :
          null
        }
        <p className="textCenter">Missing a service?<a href="mailto:hello@richcommerce.co"> Let us know here.</a></p>
      </React.Fragment>
    );
  }

}

export default Connect;
