/**
 * Component for the final page of our Return-Portal
 * where a customer is informed that the Return
 * request has been received. If a shipping-label
 * is involved this will also be downloadable on this
 * page.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Card, Divider, Icon, Spin, Result, message } from "antd";
import { FormattedMessage } from "react-intl";
class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelUrl: undefined,
      trackingUrl: undefined,
      loading: true,
      labelQRcodeUrl: undefined,
      error: false,
    };
  }

  async componentDidMount() {
    // scroll to top of page
    window.scrollTo(0, 0);

    try {
      let companyIdentifier = this.props.companyIdentifier;
      let returnNumber = this.props.returnNumber;
      
      if (!companyIdentifier || !returnNumber) {
        const url = window.location.href;
        const urlObject = new URL(url);
        companyIdentifier = urlObject.searchParams.get("identifier");
        returnNumber = urlObject.searchParams.get("returnNumber")
      }
      const response = await axios.post("/api/visitors/return/summary", {
        companyIdentifier,
        returnNumber
      });

      message.success("Successfully created your return.", 4);
      return this.setState({
        loading: false,
        ...response.data,
      });
    } catch(err) {
      message.error("There was an error submitting your return. Please try again or contact support.", 10);
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  render() {
    return(
      <React.Fragment>
        <Card
          title={
            <FormattedMessage
              id="app.visitor.status.headline"
              description="Title of the last step of the return-portal process 'status page'"
              defaultMessage="Summary of your return"
            />
          }
          className="textCenter"
        >
          <Row type="flex" justify="center" align="top" className="textCenter">
            <Col>
              {
                this.state.loading
                  ? (
                    <Col span={24}>
                      <Spin size="large" />
                    </Col>
                  ) : null
              }
              {
                this.state.error
                  ? (
                    <Result
                      status="warning"
                      title="There was an error submitting your return. Please try again or contact support."
                    />
                  ) : null
              }
              { 
                !this.state.loading && !this.state.error && !this.state.labelUrl
                  ? (
                    <React.Fragment>
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize: '100px' }} />
                      <h1>
                        <FormattedMessage
                          id="app.visitor.status.message"
                          description="Top message of the last step of the return-portal process 'status page'"
                          defaultMessage="Congrats!"
                        />
                      </h1>
                      <h3>
                        <FormattedMessage
                          id="app.visitor.status.infoViaEmail"
                          description="Instructions via email message"
                          defaultMessage="We will email the instructions to you once your request has been approved. Please check your inbox and spam folder."
                        />
                      </h3>
                    </React.Fragment>
                  )
                  : null
              }
              {
                !this.state.loading && !this.state.error && this.state.labelUrl
                  ? <React.Fragment>
                    <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize: '100px' }} />
                    <h1>
                      <FormattedMessage
                        id="app.visitor.status.message"
                        description="Top message of the last step of the return-portal process 'status page'"
                        defaultMessage="Congrats!"
                      />
                    </h1>
                    <h2>
                      <FormattedMessage
                        id="app.visitor.status.labelInfo"
                        description="Instructions for prepaid return label"
                        defaultMessage="Please check your inbox (and spam folder) for instructions"
                      />
                    </h2>
                    <h3>
                      <FormattedMessage
                        id="app.visitor.status.labelDownload"
                        description="Download link-text for prepaid shipping label"
                        defaultMessage="You can download your shipping-label here"
                      />: <a href={ this.state.labelUrl } target="_blank" rel="noopener noreferrer">Link</a>
                    </h3>
                    <h3>
                      <FormattedMessage
                        id="app.visitor.status.labelTrack"
                        description="Tracking link-text for prepaid shipping label"
                        defaultMessage="You can track your shipment after approx. 24 hours here"
                      />: <a href={ this.state.trackingUrl } target="_blank" rel="noopener noreferrer">Link</a>
                    </h3> 
                    { this.state.labelQRcodeUrl ? 
                      <h3>
                        <FormattedMessage
                          id="app.visitor.status.labelQRcodeUrl"
                          description="QR-Code for your shipment"
                          defaultMessage="QR-Code for your shipment"
                        />: <img src={ this.state.labelQRcodeUrl } alt="QR-Code"/>
                      </h3> :
                      null
                    }
                  </React.Fragment>
                  : null
              }
              
            </Col>
          </Row>
          <Divider />
          <Row type="flex" justify="center" align="top">
            <Col>
              <p>
                <FormattedMessage
                  id="app.visitor.status.contact"
                  description="Contact info for customer"
                  defaultMessage="If you need a helping hand contact us at"
                />
                {" "}<a style= {{ textDecoration: "underline" }} href={"mailto:" + this.props.contactEmail}>{this.props.contactEmail}</a>.
              </p>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default Status;
