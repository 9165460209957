/**
 * Component for Customer Incentives settings
 * that allows merchants to provide incentives to their customers
 * either store credit or discount codes
 *
 * @component
 */

import React from "react";
import axios from "axios";
import { Button, Row, Col, Divider, Alert, message, Form, Table, Popconfirm, Select, Checkbox } from "antd";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
import "../../../../../styles/App.css"
import Incentives from "./Incentives";
const { Option } = Select;
const FormItem = Form.Item;
class CustomerIncentives extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      discountCodeResolution: "storeCredit",
      discountCodeType: "fixed",
      bonusCreditType: "fixed",
      bonusCreditValue: 0.0,
      discountCodeValue: 0.0,
      bonusCreditResolution: "exchangeItem",
      currentIncentive: {},
      data: [],
      resolutionOptions: {
        exchangeItem: "Exchange",
        storeCredit: "Store credit",
        storeWideExchange: "Store-Wide Exchange",
        advancedExchange: "Advanced Exchange",
      },
      enableCustomerIncentives: false,
    };
  }

  componentDidMount = () => {
    this.getIncentives()
    this.getReturnSettings()
  }

  getReturnSettings = async () => {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }
      this.setState({
        enableCustomerIncentives: response.data.enableCustomerIncentives,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  getIncentives = async() => {
    try {
      const response = await axios.get("/api/returnSettings/getIncentives");
      if (response.data == null) {
        return;
      }
      this.setState({
        data: response.data,
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  getResoulutions = (skipState) => {
    let resolutionOptions = {
      exchangeItem: 'Exchange',
      storeCredit: 'Store Credit',
    }

    if (this.context.featureStoreWideExchange) {
      resolutionOptions = {...resolutionOptions, storeWideExchange: "Store-Wide Exchange"}
    }
    if (this.context.featureAdvancedExchanges) {
      resolutionOptions = {...resolutionOptions, advancedExchange: "Advanced Exchange"}
    }
    if (!skipState) {
      this.setState({
        resolutionOptions,
      })
    }

    const options = Object.entries(resolutionOptions).map(([key, value]) => (
      <Option key={key} value={key}>
        {value}
      </Option>
    ))
    return options
  }
  toggleEditMode = (e, record, toggle) => {
    if (!toggle) {
      this.getIncentives()
    }
    this.setState({currentIncentive: record, editIncentive: toggle})
  }

  deleteIncentive = async(record) => {
    try {
      const response = await axios.post("/api/returnSettings/deleteIncentive", {id: record.id});
      if (response.error) {
        return message.error("Error deleting the incentive. Try again.", 3);
      }
      this.getIncentives()
    } catch (err) {
      message.error("Error deleting the incentive. Try again.", 2);
    }
  }

  updateSettings = async() => {
    try {
      const response = await axios.post("/api/returnSettings/update", {
        enableCustomerIncentives: this.state.enableCustomerIncentives,
      });
      if (response.data.status === "error") {
        return message.error("Error updating your customer incentives. Please try again-----.", 3);
      }
      if (!this.state.enableCustomerIncentives) return message.warning("Incentives Disabled", 3);
    } catch(err) {
      message.error("Error while updating customer incentives",3)
    }
  }

  handleChange = (e) => {
    const target = e.target.name;
    this.setState({
      [target]: e.target.checked,
    }, () => this.updateSettings());
  }

  render() {
    const columns = [
      {
        title: 'Incentive Type',
        dataIndex: 'incentiveType',
      },
      {
        title: 'Value Type',
        dataIndex: 'incentiveValueType',
        render: (text) => {
          return (
            <span>{text === "fixed" ? "Fixed Amount" : "Percentage"}</span>
          )
        }
      },
      {
        title: 'Incentive Resolution',
        dataIndex: 'incentiveResolution',
        render: (text) => {
          return (
            <span>{this.state.resolutionOptions[text]}</span>
          )
        },
      },
      {
        title: 'Incentive Value',
        dataIndex: 'incentiveValue',
        render: (text) => parseFloat(text)?.toFixed(2)
      },
      {
        title: 'Timer',
        dataIndex: 'enableTimer',
        render: (value) => {
          return value === true ? <span>ON</span> : <span>OFF</span>
        }
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record) => {
          return (
            `edit-${record.id}`
          )
        },
        render: (text, record) => {
          return (
            <Button size="small" onClick={(e) => this.toggleEditMode(e, record, true)}>Edit</Button>
          )
        }
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          return (
            `delete-${record.id}`
          )
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete this rule?"
              onConfirm={() => this.deleteIncentive(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          )
        }
      }
    ];
    return (
      <React.Fragment>
        {this.context.featureCustomerIncentives ? (
          <React.Fragment>
            <React.Fragment>
              {!this.state.editIncentive && (
                <React.Fragment>
                  <Row type="flex" justify="space-around" align="middle">
                    <Col span={16}>
                      <Alert
                        message="How to set up Customer Incentives"
                        description={
                          <span>
                            We cover the setup of customer incentives{' '}
                            <a
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here in our Help Docs
                            </a>
                          </span>
                        }
                        type="info"
                        showIcon
                      />
                    </Col>
                  </Row>
                  {(this.context.featureCustomerIncentives) &&
                    <React.Fragment>
                      <Row type="flex" justify="space-around" align="top">
                        <Col span={18}>
                          <Divider />
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                        <Col offset={3} span={14}>
                          <h4>Customer Incentives</h4>
                          <p>
                            Boost your revenue by encouraging customers to choose store credits
                            and exchanges over refunds
                          </p>
                        </Col>
                        <Col span={4}>
                          <Checkbox name="enableCustomerIncentives" checked={this.state.enableCustomerIncentives} onChange={(e) => this.handleChange(e)}>Enable</Checkbox>
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                  {this.state.enableCustomerIncentives &&
                    <React.Fragment>
                      <Row type="flex" justify="space-around" align="top">
                        <Col span={18}>
                          <Divider />
                        </Col>
                      </Row>
                      <Row type="flex" justify="center" align="middle">
                        <Col>
                          <Button
                            type="primary"
                            onClick={() => this.setState({ editIncentive: true })}
                          >
                            {' '}
                            Add Incentive
                          </Button>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-around" align="top">
                        <Col span={18}>
                          <Divider />
                        </Col>
                      </Row>
                      <Row
                        type="flex"
                        justify="space-around"
                        align="middle"
                        className="dashboard-content"
                      >
                        <Col>
                          {this.state.resolutionOptions &&
                            Object.keys(this.state.resolutionOptions)?.length > 0 &&
                            (
                              <Table
                                rowKey={(_, index) => index}
                                columns={columns}
                                dataSource={this.state.data}
                              />
                            )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                </React.Fragment>
              )}
              {this.state.editIncentive && (
                <Incentives
                  currentIncentive={this.state.currentIncentive}
                  getResoulutions={this.getResoulutions}
                  toggleEditMode={this.toggleEditMode}
                />
              )}
            </React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="start" align="top">
              <Col>
                <h1 className="pageTitle">Customer Incentives</h1>
                <p>
                  Boost revenue by encouraging customers to choose store credits
                  and exchanges instead of refunds
                </p>
              </Col>
            </Row>
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={18}>
                  <Alert
                    message="Already on our Premium or Plus Plan?"
                    description={
                      <React.Fragment>
                        <span>
                          Please reach out to us directly in order to have this
                          feature enabled and set up for your account.
                        </span>
                      </React.Fragment>
                    }
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default CustomerIncentives;
