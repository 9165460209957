/** 
 * Component for email based logins.
 * 
 * @component
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Form, Icon, Input, Button, message } from 'antd';
import { getAppInfo } from '../../../helpers/appInfo';
import '../../../styles/login.css';
import '../../../styles/App.css';
const FormItem = Form.Item;

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      redirect: false,
      isProductionApp: false
    }
  }

  componentDidMount = async () => {
    try {
      const info = await getAppInfo();
      if (info?.isProductionApp) {
        this.setState({
          isProductionApp: info.isProductionApp
        });
      }
    } catch (err) {
      message.error("error fetching clientId of application");
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let response = await axios.post("/auth/login",
            {
              username: values.username,
              password: values.password
            });
          if (response.data.error) {
            return message.error("Username unknown or wrong password.", 5);
          }
          return this.setState({ redirect: true });
        } catch (err) {
          return message.error("Error while logging you in.", 5)
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.redirect) {
      return <Redirect push to="/dashboard" />
    }

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} className="login-form">
          {!this.state.isProductionApp &&
            <FormItem>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please enter your username or user id' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Username or user id" />
              )}
            </FormItem>
          }
          {this.state.isProductionApp &&
            <FormItem>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please enter your email' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="E-Mail address" />
              )}
            </FormItem>
          }
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please enter password' }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
            )}
          </FormItem>
          <FormItem className="textCenter">
            <Button type="primary" htmlType="submit" className="login-form-button">
              Login to your account
            </Button>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedLoginForm = Form.create()(LoginForm);
export default WrappedLoginForm;
