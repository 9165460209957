/**
 * Component for the payment option of our Return-Portal
 * where a customer is asked if they would like to
 * pay their outstanding dues of exhange Items or label costs.
 * @component
 */
import React from 'react'
import axios from 'axios'
import { Row, Col, Card, Divider, Spin, message, Table, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { loadStripe } from '@stripe/stripe-js'
class Payment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      products: [],
      totalDues: 0,
      labelCost: 0,
    }
  }
  async componentDidMount() {
    try {
      const response = await axios.post(
        '/api/visitors/return/getExchangeDetails',
        {
          companyIdentifier: this.props.companyIdentifier,
          returnNumber: this.props.returnNumber,
        }
      )
      if (response && response.status === 200) {
        const { customerReturn, products} = response.data
        const { labelFeeValue, ruleStripePayments } = this.props.returnData
        const { labelCostPayment, enableStripePayment, manualLabelCostPayment }= this.props.returnSettings
        let totalDues = 0
        products.forEach((product) => {
          totalDues = totalDues + parseFloat(product.dueAmount)
        })
        if (
          labelFeeValue &&
          (enableStripePayment || ruleStripePayments) &&
          labelCostPayment &&
          customerReturn.shippingMethod === 'methodLabelCreation'
        ) {
          totalDues = totalDues + parseFloat(labelFeeValue)
          this.setState({
            labelCost: parseFloat(labelFeeValue).toFixed(2),
          })
        }
        else if (customerReturn && !labelFeeValue) {
          if (customerReturn.labelRateAmount) {
            const labelCost = parseFloat(customerReturn.labelRateAmount)
            totalDues += labelCost
            this.setState({
              labelCost: labelCost.toFixed(2)
            })
          }
        } else if (manualLabelCostPayment && labelFeeValue && customerReturn.shippingMethod === 'methodLabelManual') {
          totalDues = totalDues + parseFloat(labelFeeValue)
          this.setState({
            labelCost: parseFloat(labelFeeValue).toFixed(2),
          })
        }
        if (totalDues > 0) {
          this.setState({
            totalDues: totalDues.toFixed(2),
          })
        }
        this.setState({
          loading: false,
          products,
        })
      }
    } catch (err) {
      message.error(
        'There was an error submitting your return. Please try again or contact support.',
        10
      )
      this.setState({
        loading: false,
      })
    }
  }
  handlePayments = async () => {
    this.setState({
      loading: true,
    })
    const stripe = await loadStripe(
      this.props.returnSettings.stripePublishableKey
    )
    const response = await axios.post('/api/visitors/return/createSession', {
      companyIdentifier: this.props.companyIdentifier,
      returnNumber: this.props.returnNumber,
    })
    stripe.redirectToCheckout({
      sessionId: response.data.sessionId,
    })
  }
  columns = [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Exchange Item',
      dataIndex: 'exchangeItemProductName',
      key: 'exchangeItemProductName',
      render: (text, record) => (
        <p>
          {text} ({record.exchangeItemVariantName})
        </p>
      ),
    },
    {
      title: 'Resolution',
      dataIndex: 'resolutionLabel',
      key: 'resolutionLabel',
      render: (text) => <p>{text?.toUpperCase()}</p>,
    },
    {
      title: 'Dues',
      key: 'exchangeItemPrice',
      render: (_, product) => <p>{product.dueAmount?.toFixed(2)}</p>
    },
  ]
  render() {
    return (
      <React.Fragment>
        <Card
          title={
            <FormattedMessage
              id="app.visitor.payment.headline"
              description="Title of the Payment option page'"
              defaultMessage="Please complete the payment to finalize your return"
            />
          }
          className="textCenter"
        >
          {this.state.loading ? (
            <Col span={24}>
              <Spin size="large" />
            </Col>
          ) : (
            <React.Fragment>
              <Row type="flex" justify="center" align="top">
                <Col>
                  {this.state.products?.length > 0 && (
                    <Table
                      rowKey={(record) => record.id}
                      columns={this.columns}
                      dataSource={this.state.products}
                      pagination={false}
                    />
                  )}
                </Col>
              </Row>
              {this.state.products?.length > 0 && <Divider />}
              {this.state.totalDues && (
                <React.Fragment>
                  {this.state.labelCost !== 0 && (
                    <p>
                      <FormattedMessage
                        id="app.visitor.payment.pendingLabelFee"
                        description="Message regarding the pending dues"
                        defaultMessage="Label Fee: "
                      />
                      {this.state.labelCost}{' '}
                      <b>{this.props.orderData.currency}</b>
                    </p>
                  )}
                  <p>
                    <FormattedMessage
                      id="app.visitor.payment.pendingDues"
                      description="Message regarding the pending dues"
                      defaultMessage="The following payment is due: "
                    />
                    {this.state.totalDues}{' '}
                    <b>{this.props.orderData.currency}</b>
                  </p>
                  <p>
                    <FormattedMessage
                      id="app.visitor.payment.inquire"
                      description="Question regarding the pending dues to pay"
                      defaultMessage="Ready to complete the transaction?"
                    />
                  </p>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: this.props.brandColorButton,
                      marginRight: 20,
                    }}
                    onClick={this.handlePayments}
                  >
                    <span
                      style={{
                        color: this.props.brandColorButtonText,
                        fontStyle: this.props.brandFontStyle,
                      }}
                    >
                      Yes
                    </span>
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => this.props.history.push('/return/status')}
                    style={{ backgroundColor: this.props.brandColorButton }}
                  >
                    <span
                      style={{
                        color: this.props.brandColorButtonText,
                        fontStyle: this.props.brandFontStyle,
                      }}
                    >
                      No
                    </span>
                  </Button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    )
  }
}
export default Payment