/**
 * Component to Feature-Gate certain parts of the Application.
 * Allows for embedding a YouTube video for better demonstration
 * of the feature in question.
 * 
 * @component
 */

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Divider, Button, Row, Col, Typography, Alert } from 'antd';

const { Title } = Typography;

export class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <React.Fragment>
        <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
          <Col span={16}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="top" style={{ marginBottom: 20, textAlign: "center" }}>
          <Col span={16}>
            <Title level={2}>{this.props.title}</Title>
            <p>{this.props.description}</p>
            <Button type="primary"><Link to="/dashboard/account">See plans</Link></Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
          <Col span={16}>
            <Divider />
          </Col>
        </Row>
        {
          this.props.videoId
            ? (
              <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
                <Col span={24}>
                  <Title level={2}>See the feature in action.</Title>
                  {
                    this.props.faqLink
                      ?   (
                        <p>Click <a href={this.props.faqLink} target="_blank" rel="noopener noreferrer">here</a> for the most recent list of all available rules</p>
                      )
                      : null
                  } 
                  <iframe width="560" height="315" src={`https://www.youtube.com/embed/${this.props.videoId}?showinfo=0&autoplay=0&rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
                </Col>
              </Row>
            )
            : null
        }

        {
          this.props.devDocs
            ? (
              <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
                <Col span={12}>
                  <Title level={2}>API Reference.</Title>
                                    
                  <Alert
                    message="Looking for documentation?"
                    description={
                      <span>We cover the API <a href="https://support.apphub.com/en/articles/6367662-introduction-to-our-api" target="_blank" rel="noopener noreferrer">
                                                here in our Developer Docs
                      </a>
                      </span>
                    }
                    type="info"
                    showIcon
                  />                                    
                </Col>
              </Row>
            )
            : null
        }
                
      </React.Fragment>
    )
  }
}

export default Upgrade;
