/**
 * Component for our Analytics section displaying top 10 returned customers.
 *
 * @component
 */

import React from 'react';
import { message, Row, Col } from "antd";
import axios from "axios";
import MostReturnedTable from './MostReturnTable';
class MostReturn extends React.Component {
  constructor() {
    super()
    this.state = {
      mostReturns: []
    }
  }
  async componentDidMount() {
    try {
      const response = await axios.get("/api/analytics/customer/most-returned");
      if (response?.data.status === "error") {
        return message.error("Error while fetching records of most returned customers .");
      }
      const returnData = response.data
      this.setState({
        mostReturns: returnData
      })
    } catch (err) {
      return message.error("Error while fetching records of most  returned customers .");
    }
  }
  render() {
    return (
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="top"
          style={{ paddingBottom: 35 }}
        >
          <Col>
            <h1 className="pageTitle">Customers with Most Returns</h1>
            <p>List of top 10 Customers with most Returns</p>
          </Col>
        </Row>
        <MostReturnedTable
          mostReturns={this.state.mostReturns}
        />
      </React.Fragment>
    )
  }
}
export default MostReturn;