/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our  EasyPost
 * integrations for the user.
 *
 * @component
 */
import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Row,
  Col,
  Select,
  Divider,
  Input,
  Icon,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import VideoPopup from "../../../../../components/VideoPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UpgradeButton from "../../../../../components/UpgradeButton";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
import BuyerAddress from "./BuyerAddressModal"
const { Option } = Select;

class EasyPost extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      carrierListEasyPost: this.props.carrierListEasyPost,
      showBuyerAddressModal: false,
    };
  }

  reloadCarrierList = async () => {
    if (this.props.labelApiKey == null || this.props.labelApiKey === "") {
      return message.error("Please add your API key and API secret first.", 4);
    }

    this.setState({
      loading: true,
    });

    // save entered API keys
    await this.props.saveSettings();

    try {
      const response = await axios.get("/api/integrations/easypost/carriers");
      if (response.data.status === "error") {
        this.setState({
          loading: false,
        });
        return message.error(
          "Error requesting carriers from EasyPost. Please try again.",
          5
        );
      }

      const carrierListEasyPost = response.data.carrierListEasyPost;

      return this.setState({
        carrierListEasyPost,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error(
        "Error requesting carriers from EasyPost. Please try again.",
        5
      );
    }
  };

  showAddressModal = () => {
    this.setState({
      showBuyerAddressModal: !this.state.showBuyerAddressModal
    });
  }
  render() {
    const reloadCarrierListButtonDisabled =
      this.props.labelApiKey == null || this.props.labelApiKey === ""
        ? true
        : false;

    let carriersList;
    if (this.state.carrierListEasyPost) {
      carriersList = this.state.carrierListEasyPost.map((carrier) => {
        const carrierName = carrier.readable;
        const carrierCode = carrier.id;
        return (
          <Option value={carrierCode} key={carrierCode}>
            {carrierName}
          </Option>
        );
      });
    }
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>API Key</h4>
            <span>
              EasyPost requires the 'Production API Key'{" "}
              <a
                href="https://www.easypost.com/account/api-keys"
                target="_blank"
                rel="noopener noreferrer"
              >
                you can find here in your EasyPost account
              </a>
              . The 'Test API Key' will not work.
            </span>
          </Col>
          <Col span={10}>
            <a
              href="https://www.easypost.com/account/api-keys"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find here in your EasyPost account
            </a>
            <br />
            {this.props.inputFieldValidation === false &&
              <p style={{ color: "#f00000" }}>API key cannot contain "API", "test", "sandbox" or special characters</p>
            }
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange("labelApiKey", e.target.value)
              }
              value={this.props.labelApiKey}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Create dedicated Return-Labels</h4>
            <span>
              If this option is enabled, we request a dedicated Return-Label
              from the carrier. Otherwise we create a regular Shipping-Label.
              Note that not all carriers support dedicated Return-Labels and
              prices may vary for your carrier between regular Shipping-Labels
              and Return-Labels.
            </span>
          </Col>
          <Col span={10}>
            <Checkbox
              checked={this.props.easypostEnableReturnLabels}
              onChange={(e) =>
                this.props.handleChange(
                  "easypostEnableReturnLabels",
                  e.target.checked
                )
              }
            >
              {" "}
              Enable{" "}
            </Checkbox>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Enable QR-Code Labels</h4>
            <span>
              This option is only available for USPS. Enabling this will request
              a printer-less QR-Code Label from USPS. The printable label will
              be requested as a backup as well. We'll add support for more
              carriers using QR code labels as they become available on
              EasyPost.
            </span>
          </Col>
          <Col span={10}>
            {this.context.featureLabelQRcode ? (
              <Checkbox
                checked={this.props.labelEnableQRcode}
                onChange={(e) =>
                  this.props.handleChange("labelEnableQRcode", e.target.checked)
                }
              >
                Enable
              </Checkbox>
            ) : (
              <UpgradeButton />
            )}
          </Col>
        </Row>

        {
          this.context.featureAutoTracking && (
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Auto Tracking</h4>
                  <span>
                    Automatically update the status of a return for both
                    in-transit ("Shipped") and delivered ("Received") events.
                    <br />
                    See our setup tutorial here:{" "}
                    <VideoPopup videoId="K7vulNuapys" />
                    <br /> Copy the Link and add it{" "}
                    <a
                      href="https://www.easypost.com/account/webhooks-and-events"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{" "}
                    in your EasyPost account.
                  </span>
                </Col>
                <Col span={10}>
                  <Checkbox
                    checked={this.props.autoTracking}
                    name="autoTracking"
                    onChange={(e) =>
                      this.props.handleChange("autoTracking", e.target.checked)
                    }
                  >
                    Enable
                  </Checkbox>
                  {this.props.autoTracking && (
                    <React.Fragment>
                      <CopyToClipboard
                        text={
                          "https://api.richcommerce.co/returns/webhook/" +
                          this.props.labelService +
                          "/" +
                          this.props.autoTrackingWebhookSecret
                        }
                      >
                        <Button type="primary">
                          <Icon type="copy" />
                          Copy Link
                        </Button>
                      </CopyToClipboard>{" "}
                      <span>
                        Watch Tutorial: <VideoPopup videoId="K7vulNuapys" />
                      </span>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          )
        }
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Automatically choose the cheapest rate</h4>
            <span>
              You can either let us pick the cheapest rate among all available
              rates for a shipment automatically <strong>or</strong> specify a
              default carrier and rate below.
            </span>
          </Col>
          <Col span={10}>
            <Checkbox
              checked={this.props.labelSelectCheapestRate}
              name="labelSelectCheapestRate"
              onChange={(e) =>
                this.props.handleChange(
                  "labelSelectCheapestRate",
                  e.target.checked
                )
              }
            >
              Enable
            </Checkbox>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={10}>
            <h4>Buyer Address</h4>
            <span>
              Add buyer address for the label
            </span>
          </Col>
          <Col span={10}>
            <Checkbox
              checked={this.props.enableBuyerAddress}
              name="enableBuyerAddress"
              onChange={(e) =>
                this.props.handleChange(
                  "enableBuyerAddress",
                  e.target.checked
                )
              }
            >
              Enable
            </Checkbox>
            {this.props.enableBuyerAddress &&
              <Button onClick={this.showAddressModal}>{(this.props.editOrUpdate) ? "Add Buyer Address" : "Update Buyer Address"}</Button>
            }
            {this.state.showBuyerAddressModal &&
              <BuyerAddress 
                showBuyerAddressModal={this.state.showBuyerAddressModal} 
                showAddressModal={this.showAddressModal} 
                state={this.props.state} 
                buyerAddressStatus={this.props.buyerAddressStatus}
              />
            }
            {this.props.editOrUpdate && (
              <React.Fragment>
                <br></br>
                <label style={{color: "red"}}>Please add buyer address</label>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>

        {
          this.props.labelSelectCheapestRate ? null : (
            <React.Fragment>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10}></Col>
                <Col span={10}>
                  {reloadCarrierListButtonDisabled ? (
                    <Tooltip title="Enter API key and API secret and save at the bottom of the dialog first.">
                      <Button
                        type="primary"
                        disabled={reloadCarrierListButtonDisabled}
                      >
                        <Icon type="reload" /> Refresh carrier list
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="primary"
                      onClick={this.reloadCarrierList.bind(this)}
                      loading={this.state.loading}
                      disabled={reloadCarrierListButtonDisabled}
                    >
                      <Icon type="reload" /> Refresh carrier list
                    </Button>
                  )}
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="middle">
                <Col span={10}>
                  <h4>Carrier</h4>
                  <p>Select a carrier from your account for your labels.</p>
                </Col>
                <Col span={10}>
                  <Select
                    defaultValue={this.props.labelCarrier}
                    labelSelectCheapestRate
                    style={{ width: "66%" }}
                    onSelect={(value) =>
                      this.props.handleChange("labelCarrier", value)
                    }
                    placeholder="Select your carrier contract"
                  >
                    {carriersList}
                  </Select>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10}>
                  <h4>Service</h4>
                  <span>
                    Select a default rate. This rate will be used for all
                    prepaid-labels.
                  </span>
                </Col>
                <Col span={10}>
                  <p>
                    <strong>Tip:</strong> Find the name of your rate{" "}
                    <a
                      href="https://www.easypost.com/service-levels-and-parcels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    . Select your carrier on this page and copy the exact name of
                    your rate under 'Service Levels' to this field here.
                  </p>
                  <Input
                    name="labelServicelevel"
                    onChange={(e) =>
                      this.props.handleChange("labelServicelevel", e.target.value)
                    }
                    value={this.props.labelServicelevel}
                    placeholder="Enter the rate we should use for labels"
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
            </React.Fragment>
          )
        }
      </React.Fragment >
    );
  }
}

export default EasyPost;
