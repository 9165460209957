/**
 * Form Component for our Return details sidebar that
 * lets users edit the details of a customer like
 * email, name and address.
 * 
 * @component
 */

import React, { Component } from "react";
import axios from "axios";
import { Form, Input, Row, Col, Select, Modal, Button, message, Popconfirm, Icon } from "antd";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
import { trackFSEvent } from "../../../../../helpers/fullstory";

const FormItem = Form.Item;

class ReturnMultipleAddress extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      countriesList: [],
      storeAddress: [],
      editStoreAddress: null,
      isSelectable: null,
      defaultAddress: null,
      locationName: undefined,
      fullName: undefined,
      company: undefined,
      streetAddress: undefined,
      country: undefined,
      optional: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      email: undefined,
      phone: undefined

    };
  }

  async getStoreAddresses() {
    try {
      const response = await axios.get("/api/returnSettings/multipleAddress");
      const storeAddress = response.data.getStoreAddress;
      const filterAddress = storeAddress.find(e => { return e.defaultActive === true });
      this.setState({
        storeAddress,
        isSelectable: filterAddress,
        defaultAddress: filterAddress.id
      });

    } catch (err) {
      console.log("Error, Unable to get get store Address.Please try again.");
    }
  }

  async componentDidMount() {
    this.getStoreAddresses();
    this.setState({
      countriesList: this.props.countriesList,
    });
  }

  async componentWillReceiveProps() {
    if (this.props.updateProps) {
      this.getStoreAddresses();
    }
  }

  handleCancel = () => {
    this.props.showAddressModal();
    this.props.handleChangeMultipleAddress(this.state.defaultAddress, this.state.storeAddress);
    this.setState({
      locationName: null,
      fullName: null,
      company: null,
      streetAddress: null,
      country: null,
      optional: null,
      city: null,
      zipCode: null,
      state: null,
      email: null,
      phone: null
    });
    
  };

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          return
        }
        const response = await axios.post("/api/returnSettings/multipleAddress/create", {
          values
        });
        if (response.data.status === "error") {
          return message.error("Error, Unable to add store address, Please try again.", 5);
        }

        await this.context.updateContext();

        if (response.data.status === "Success") {
          this.props.showAddressModal();;
          this.getStoreAddresses();
          trackFSEvent("Add multiple store address", {
            feature: "Return Settings",
            numberOfStoreAddress: this.state.storeAddress ? this.state.storeAddress.length : 1,
            storeAddressName: this.state.locationName || "" 
          });
          return message.success("Store Address Added Successfully.", 5)
        }
      });
    } catch (err) {
      message.error("Error, Unable to add store address, Please try again.")
    }
  }

  editStoreAddress = async (e, id) => {
    try {
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          return
        }
        const response = await axios.post(`/api/returnSettings/multipleAddress/edit/${id}`, {
          values
        });
        if (response.data.status === "error") {
          return
        }

        this.handleCancel();
        this.props.handleChangeMultipleAddress(this.state.defaultAddress, this.state.storeAddress);
        this.getStoreAddresses();
        
        trackFSEvent("Edit multiple store address", {
          feature: "Return Settings",
          numberOfStoreAddress: this.state.storeAddress ? this.state.storeAddress.length : 1,
          storeAddressName: this.state.locationName || "" 
        });
        return message.success("Store Address Added Successfully.", 5);

      });
    } catch (err) {
      message.error("Error, Unable to add store address, Please try again.")
    }
  }


  saveDefaultStoreAddress = async (e) => {
    try {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) {
        }
        values.defaultActive = true;
        const response = await axios.post("/api/returnSettings/multipleAddress/create", {
          values
        });
        if (response.data.status === "error") {
          return message.error("Error, Unable to add store address, Please try again.", 5);
        }
        await this.context.updateContext();

        if (response.data.status === "Success") {
          this.props.showAddressModal();
          this.props.getReturnSettings();
          this.getStoreAddresses();
          return message.success("Store Address Added Successfully.", 5)
        }
      });
    } catch (err) {
      message.error("Error, Unable to add store address, Please try again.")
    }
  }

  handleEditStoreAddress = async (id) => {
    this.props.showAddressModal();

    const storeAddress = this.state.storeAddress.filter(e => {
      return e.id === id
    })

    this.setState({
      isSelectable: id,
      editStoreAddress: id
    })

    storeAddress && 
    storeAddress.map((address) => {
      this.setState({
        locationName: address.locationName,
        fullName: address.fullName,
        company: address.company,
        streetAddress: address.streetAddress,
        country: address.country,
        optional: address.optional,
        city: address.city,
        zipCode: address.zipCode,
        state: address.state,
        email: address.email,
        phone: address.phone
      });
    })

  }

  deleteStoreAddress = async (id) => {
    try {
      const response = await axios.post(`/api/returnSettings/multipleAddress/remove/${id}`);

      if (response.data.status === "error") {
        return message.error("Error, Unable to remove store address, Please try again.", 5);
      }
      trackFSEvent("Delete multiple store address", {
        feature: "Return Settings",
        numberOfStoreAddress: this.state.storeAddress && this.state.storeAddress.length > 1 ? this.state.storeAddress.length - 1 : 0,
        storeAddressName: this.state.locationName || "" 
      });
      await this.context.updateContext();
      this.getStoreAddresses();
      this.props.getReturnSettings();
      this.props.handleChangeMultipleAddress(this.state.defaultAddress, this.state.storeAddress);
      return message.success("Store Address remove successfully.", 5);

    } catch (err) {
      message.error("Error, unable to delete store address.", 5);
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Select
          placeholder="Select Address"
          value={this.props.defaultActiveStoreAddress ?
            this.props.defaultActiveStoreAddress :
            this.state.isSelectable &&
            this.state.isSelectable.id
          }
          onChange={(e) => this.props.handleChangeMultipleAddress(e, this.state.storeAddress)}
        >
          {
            this.state.storeAddress &&
            this.state.storeAddress.length > 0 &&
            this.state.storeAddress.map((address) => (
              <Option
                value={address.id}
                key={address.id}
              >
                {address.locationName}

                {this.props.defaultActiveStoreAddress !== address.id && !address.defaultActive &&
                  <div style={{ float: 'right' }}>
                    <Icon type="edit" theme="twoTone" onClick={() => this.handleEditStoreAddress(address.id)} />
                    &#160;
                    &#160;
                    <Popconfirm
                      title="Are you sure delete this store address?"
                      onConfirm={() => this.deleteStoreAddress(address.id)}
                      onCancel={() => this.handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon type="delete" theme="twoTone" twoToneColor="#FF0000" />
                    </Popconfirm>
                  </div>
                }

              </Option>
            ))
          }
        </Select>
        {this.props.visible &&
          <Modal
            title={this.state.editStoreAddress ? "Update Customer shipping details" : "Add Customer shipping details" }
            visible={true}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.loading}
                onClick={this.state.editStoreAddress ?
                  (e) => this.editStoreAddress(e, this.state.editStoreAddress) :
                  (e) => this.handleSubmit(e)
                }
              >
                Save
              </Button>,
              !this.state.editStoreAddress &&
              <Button key="defaultSave" type="primary" onClick={this.saveDefaultStoreAddress}>Save as default</Button>
            ]}
          >
            <Form>
              <Row>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator('country', {
                      initialValue: this.state.country,
                      rules: [{ required: true }]
                    })(
                      <Select
                        placeholder="Select country"
                      >
                        {this.state.countriesList}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('locationName', {
                      initialValue: this.state.locationName,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="Store Name" placeholder="Store name" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('fullName', {
                      initialValue: this.state.fullName,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="Full name" placeholder="Full name" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('company', {
                      initialValue: this.state.company,
                    })(
                      <Input addonBefore="Company name" placeholder="(Optional)" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('streetAddress', {
                      initialValue: this.state.streetAddress,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="Street address" placeholder="Street and number, P.O. box, c/o." />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('optional', {
                      initialValue: this.state.optional,
                    })(
                      <Input addonBefore="(optional)" placeholder="Apartment, suite, unit, building, floor, etc." />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('city', {
                      initialValue: this.state.city,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="City" placeholder="City" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('state', {
                      initialValue: this.state.state,
                      rules: [
                        { validator: this.handleStateValidator }
                      ]
                    })(
                      <Input addonBefore="State" placeholder="State / Province / Region" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('zipCode', {
                      initialValue: this.state.zipCode,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="Zip Code" placeholder="Zip Code" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('phone', {
                      initialValue: this.state.phone,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="Phone number" placeholder="May be used to assist delivery" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('email', {
                      initialValue: this.state.email,
                      rules: [{ required: true }]
                    })(
                      <Input addonBefore="E-Mail" placeholder="May be used to assist delivery" />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Modal>
        }
      </React.Fragment>

    );

  }
};

const WrappedReturnDetailsCustomerForm = Form.create()(ReturnMultipleAddress);
export default WrappedReturnDetailsCustomerForm;