/**
 * Form Component for our Return details sidebar that
 * lets users edit the details of a customer like
 * email, name and address.
 * 
 * @component
 */

import React, { Component } from "react";
import axios from "axios";
import { Form, Input, Row, Col, Select, Modal, Checkbox, Button, message } from "antd";
import { nonFrequentlyUsedCountries, frequentlyUsedCountries, USStateNames } from "../../../../constants/constants"

const { Option } = Select;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ReturnDetailsCustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      loading: false,
      updateDetailsOnShopify: true,
    };
  }

  componentDidMount() {

    this.setState({
      ...this.props.returnObject,
    });

  }

  handleCancel = () => {
    this.props.toggleModal();
  };

  handleCountryChange = (countryCode) => {
    this.setState({
      shippingCountryCode: countryCode,
    });
  };

  toggleUpdateDetailsOnShopify = () => {
    this.setState({
      updateDetailsOnShopify: !this.state.updateDetailsOnShopify,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields( async (err, values) => {
      if (err) {
        return message.error("There is something wrong with the data you entered. Please check again.", 5);
      }

      this.setState({
        loading: true,
      });
      try {
        const formValues = values;
        const response = await axios.put("/api/returns/customer", {
          returnId: this.state.id,
          updateDetailsOnShopify: this.state.updateDetailsOnShopify,
          formValues,
        });

        if (response.data.status === "error") {
          this.setState({
            loading: false,
          });
          return message.error(response.data.error, 10);
        }

        this.setState({
          loading: false,
        });
        this.props.handleGetReturn();
        this.props.toggleModal();
        return message.success("Customer details updated successfully.", 5);

      } catch (err) {
        this.setState({
          loading: false,
        });
        return message.error("Error updating Customer details. Please try again.", 5);
      }
    });
  }

  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code}>{country.name}</Option>);
    });

    const disabledoption = <Option value="disabled" disabled={true}>---------</Option>;
    countriesList.push(disabledoption)
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code}>{country.name}</Option>);
    });
    
    return countriesList;
  }

  getUSStateList = () => {
    const USStateList = USStateNames.map((state) => {
      return (
        <Option value={state.code}>{state.name}</Option>
      );
    });
    
    return USStateList;
  }


  render() {

    const { getFieldDecorator } = this.props.form;

    return(

      <Modal
        title="Update customer shipping details"
        visible={true}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
            Update
          </Button>,
        ]}
      >

        <Form>

      
          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
                  
              <FormItem>
                {getFieldDecorator('customerEmail', {
                  initialValue: this.state.customerEmail,
                  rules: [
                    { required: true, message: "Please add the customer's email"  }
                  ]
                })(
                  <Input addonBefore="E-Mail" placeholder="For delivering instructions via email" />
                )}
              </FormItem>
              <FormItem>
                {
                  getFieldDecorator("shippingFirstName", {
                    initialValue: this.state.shippingFirstName,
                    rules: [
                      { required: true, message: "Please add the customer's first name" }
                    ]
                  })(
                    <Input addonBefore="First name" />
                  )
                }
              </FormItem>
              <FormItem>
                {
                  getFieldDecorator("shippingLastName", {
                    initialValue: this.state.shippingLastName,
                    rules: [
                      { required: true, message: "Please add the customer's last name"  }
                    ]
                  })(
                    <Input addonBefore="Last name" />
                  )
                }
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingCompany', {
                  initialValue: this.state.shippingCompany,
                })(
                  <Input addonBefore="Company" placeholder="(optional)" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress1', {
                  initialValue: this.state.shippingAddress1,
                  rules: [
                    { required: true, message: "Please add the customer's street address"  }
                  ]
                })(
                  <Input addonBefore="Street address" placeholder="Street and number, P.O. box, c/o." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress2', {
                  initialValue: this.state.shippingAddress2,
                })(
                  <Input addonBefore="(optional)" placeholder="Apartment, suite, unit, building, floor, etc." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingCity', {
                  initialValue: this.state.shippingCity,
                  rules: [
                    { required: true, message: "Please add the customer's city"  }
                  ]
                })(
                  <Input addonBefore="City" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingZip', {
                  initialValue: this.state.shippingZip,
                  rules: [
                    { required: true, message: "Please add the customer's zip code"  }
                  ]
                })(
                  <Input addonBefore="Zip Code" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingPhone', {
                  initialValue: this.state.shippingPhone,
                })(
                  <Input addonBefore="Phone number" placeholder="(optional) May be used to assist delivery" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingCountryCode', {
                  initialValue: this.state.shippingCountryCode,
                  rules: [
                    { required: true, message: 'Please choose the country of the customer'  }
                  ]
                })(
                  <Select
                    placeholder="Select country"
                    onChange={ this.handleCountryChange.bind(this) }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    { this.getCountriesList() }
                  </Select>
                )}
              </FormItem>
              {
                this.state.shippingCountryCode !== "US"
                  ? (
                    <FormItem>
                      {
                        getFieldDecorator('shippingProvinceCode', {
                          initialValue: this.state.shippingProvinceCode,
                        })(
                          <Input addonBefore="State" placeholder="(optional)" />
                        )
                      }
                    </FormItem>
                  )
                  : (
                    <FormItem label="State" {...formItemLayout}>
                      {getFieldDecorator('shippingProvinceCode', {
                        initialValue: this.state.shippingProvinceCode,
                        rules: [
                          { required: true, message: 'Please choose a state'  }
                        ]
                      })(
                        this.state.shippingCountryCode === "US"
                          ? (
                            <Select
                              placeholder="State / Province / Region"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.getUSStateList()}
                            </Select>
                          )
                          : (
                            <Select placeholder="State / Province / Region" onChange={ this.handleChange.bind(this) }>
                              <Option value="" key="disabled" disabled>State only needed for US based customers.</Option>
                            </Select>
                          )
                      )}
                    </FormItem>
                  )
              }
              <FormItem>
                {
                  getFieldDecorator('updateDetailsOnShopify')(
                    <React.Fragment>
                      <Checkbox checked={this.state.updateDetailsOnShopify} onChange={this.toggleUpdateDetailsOnShopify} /> <span>Update customer shipping details in Shopify order</span>
                    </React.Fragment>
                  )
                }
              </FormItem>
            </Col>
          </Row>

        </Form>

      </Modal>

    );

  }
};

const WrappedReturnDetailsCustomerForm = Form.create()(ReturnDetailsCustomerForm);
export default WrappedReturnDetailsCustomerForm;