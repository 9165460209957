/**
 * Component for Subscriptions Page in the Dashboard. Loads
 * a page with our plans and details on the subscription
 * such as current number of Returns in the billing period.
 * 
 * @component
 */

import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Plans from "./components/Plans";

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    return(
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Your Account</h1>
          </Col>
        </Row>

        <Plans />
        
      </React.Fragment>
    )
  }

}

export default UserAccount;
