/**
 * Status: BETA
 * 
 * Component for Refunds settings page in the Dashboard
 * that is only available to selected users.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Button, Divider, Form, Checkbox, message, Select, Icon, Spin, Alert } from "antd";

const FormItem = Form.Item;
const { Option } = Select;

class SettingsRefunds extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      refundSettingsRefundShippingCosts: undefined,
      refundSettingsRefundToGiftCardLast: undefined,
      refundSettingsRestock: undefined,
      refundSettingsRestockLocation: undefined,
      refundSettingsRestockLocationId: undefined,
      refundSettingsRestockLocationName: undefined,
      refundSettingsUseGiftCards: undefined,
      refundSettingsNotificationsCheck: undefined,
      refundSettingsGatewayCheck: undefined,
      locations: undefined,
      restockLocation: undefined,
      restockLocationName: undefined,
      loadingIndicatorLocations: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/returnSettings");
      if (response.data == null) {
        return;
      }

      return this.setState({
        ...response.data,
      });

    } catch (err) {
      message.error("Error retrieving your refund settings. Try again.", 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields( async (err) => {
      if (err) {
        return message.error("There is something wrong with the data you entered. Please check again.", 5);
      }
      try {
        const response = await axios.post("/api/returnSettings/update", {
          refundSettingsRestockLocationId: `${this.state.restockLocation}`,
          refundSettingsRefundToGiftCardLast: this.state.refundSettingsRefundToGiftCardLast,
          refundSettingsRestockLocationName: this.state.restockLocationName,
          refundSettingsRefundShippingCosts: this.state.refundSettingsRefundShippingCosts,
          refundSettingsRestock: this.state.refundSettingsRestock,
          refundSettingsRestockLocation: this.state.refundSettingsRestockLocation,
          refundSettingsUseGiftCards: this.state.refundSettingsUseGiftCards,
          refundSettingsNotificationsCheck: this.state.refundSettingsNotificationsCheck,
          refundSettingsGatewayCheck: this.state.refundSettingsGatewayCheck,
        });
        if (response.data.status === "error") {
          return message.error("Error updating your refund settings. Please try again.", 5);
        }
        this.setState({
          ...response.data,
        });
        return message.success("Refund settings updated successfully.", 5);
      } catch (err) {
        return message.error("Error updating your refund settings. Please try again.", 5);
      }
    });
  }

  loadRestockLocations = async (e) => {

    const enableRestockLocation = e.target.checked;
    const target = e.target.id;

    if (!enableRestockLocation) {
      return this.setState({
        restockLocation: null,
        restockLocationName: null,
        [target]: enableRestockLocation,
      });
    }

    this.setState({
      loadingIndicatorLocations: true,
    });

    try {
      const response = await axios.get("/api/refunds/locations");

      if (response.data.status === "error") {

        this.setState({
          loadingIndicatorLocations: false,
        });

        return message.error("Error finding locations. Please try again.", 5);
      }

      const { locations } = response.data;
      let restockLocation = undefined;
      if (this.state.refundSettingsRestockLocationId) {
        restockLocation = parseInt(this.state.refundSettingsRestockLocationId);
      } else if (locations != null && locations.length === 1) {
        restockLocation = locations[0].id;
      }

      return this.setState({
        ...response.data,
        restockLocation,
        [target]: enableRestockLocation,
        loadingIndicatorLocations: false,
      });

    } catch(err) {
      message.error("Error finding locations. Please try again.", 5);
      this.setState({
        loadingIndicatorLocations: false,
      });
    }
  }

  updateLocation = locationId => {
    const locations = this.state.locations;
    let locationName = "";

    locations.map( (location) => {
      if (location.id === locationId) {
        locationName = location.name;
      }
    });
    
    this.setState({
      restockLocation: locationId,
      restockLocationName: locationName,
    });
  }

  handleChange  = (e) => {
    const target = e.target.id;
    const restockDisabled = target === "refundSettingsRestock" && e.target.checked === false;

    if (restockDisabled) {
      this.setState({
        refundSettingsRestockLocation: false,
        restockLocation: null,
        restockLocationName: null,
        [target]: e.target.checked,
      });
    } else {
      this.setState({
        [target]: e.target.checked,
      });
    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return(
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Return settings - Refunds</h1>
            <p>Seamlessly refund returns from within your Rich Returns dashboard.</p>
          </Col>
        </Row>
        {
          (
            <React.Fragment>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col md={12} xs={24}>
                  <Alert
                    message="Important: multi-currency orders and label-/ restocking fees"
                    description="If you use a multi-currency checkout, we DO NOT support deducting fees at the moment for orders with currencies other than your store's default currency. If this is of interest to you, please reach out to us."
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider>General</Divider>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col>
                
                  <Form onSubmit={ this.handleSubmit }>
                  
                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{ marginBottom: 20 }}>
                      <Col span={10}>
                        <h4>Payment gateway capability</h4>
                        <span>Have you confirmed that all payment gateways you use on Shopify actually support refunds? Otherwise the customer will not receive the refund.</span>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsGatewayCheck', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsGatewayCheck } onChange={ (e) => this.handleChange(e) }>Confirmed</Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row> 

                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{ marginBottom: 20 }}>
                      <Col span={10}>
                        <h4>Refund to Gift-Cards last</h4>
                        <span>This setting is only relevant if multiple payment sources have been used for a single order. For example a Gift-Card and a Credit-Card. If the setting is enabled we will calculate the refund such that it makes use of other payment sources first and Gift-Cards last. If other payment sources cannot cover the entire refund then the remaining part is calculated for the Gift-Card. Can be manually changed later in the dialog itself.</span>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsRefundToGiftCardLast', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsRefundToGiftCardLast } onChange={ (e) => this.handleChange(e) }>
                          Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  
                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{ marginBottom: 20 }}>
                      <Col span={10}>
                        <h4>Refund outbound shipping costs by default (if available)</h4>
                        <span>This will mark the checkbox active for refunding the full initial outbound shipping costs in the refund dialog. Can be manually changed later in the dialog itself.</span>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsRefundShippingCosts', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsRefundShippingCosts } onChange={ (e) => this.handleChange(e) }>
                          Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{ marginBottom: 20 }}>
                      <Col span={10}>
                        <h4>Restock items by default</h4>
                        <span>This will attempt to restock items at the specified location. Can be disabled manually later in the dialog itself.</span>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsRestock', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsRestock } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>  

                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content" style={{ marginBottom: 20 }}>
                      <Col span={10}>
                        <h4>Set a default restock location</h4>
                        <span>By default, we will choose the stock location that is set on the original order. You can set a different default location for restocking items here. This can be changed manually later in the dialog itself.</span>
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsRestockLocation', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsRestockLocation } onChange={ (e) => this.loadRestockLocations(e) } disabled={!this.state.refundSettingsRestock || this.state.loadingIndicatorLocations}>Enable</Checkbox>
                          )}
                        </FormItem>
                        {
                          this.state.loadingIndicatorLocations
                            ? <React.Fragment>
                              <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} /> <span>Loading locations...</span>
                            </React.Fragment>
                            : null
                        }
                        {
                          this.state.refundSettingsRestockLocation && (this.state.locations != null && this.state.locations.length > 0)
                            ? (
                              <FormItem>
                                <Select
                                  defaultValue={(this.state && this.state.restockLocation != null) ? this.state.restockLocation : undefined}
                                  style={{ width: 400 }}
                                  onChange={this.updateLocation.bind(this)}
                                  placeholder="Select restock location"
                                >
                                  {
                                    
                                    this.state.locations.map( (location) => {
                                      return <Option key={location.id} value={location.id}>{location.name}</Option>
                                    })
                                  }
                                </Select>
                              </FormItem>
                            )
                            : null
                        }
                      </Col>
                    </Row>

                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                      <Col span={10}>
                        <h4>Send Shopify's refund notification email by default</h4>
                        <p>Refunds to the original payment method will trigger the default refund notification email you have set in your Shopify store. This message can be disabled later in the dialog</p>
                        { /* <p>Store Credit will trigger an email with the Store Credit code and amount. You can customize this email under E-Mail Settings / Your customers / Select the Store Credit template</p> */ }
                      </Col>
                      <Col span={10}>
                        <FormItem>
                          {getFieldDecorator('refundSettingsNotificationsCheck', {
                          })(
                            <Checkbox checked={ this.state.refundSettingsNotificationsCheck } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    { /* 

                  <Row type="flex" justify="space-around" align="top">
                    <Col span={22}>
                      <Divider>Store Credit</Divider>
                    </Col>
                  </Row>

                  <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                    <Col span={10}>
                      <h4>Use gift-cards instead of coupon codes for Store Credit (only Shopify Plus)</h4>
                      <span>By default we create a one-time usable coupon code for the customer to use as Store Credit. If you are on Shopify Plus you can use gift-cards instead. Note: Unfortunately, Shopify does not allow creating gift-cards through their API for plans other than Shopify Plus.</span>
                    </Col>
                    <Col span={10}>
                      <FormItem>
                        {getFieldDecorator('refundSettingsUseGiftCards', {
                        })(
                        <Checkbox checked={ this.state.refundSettingsUseGiftCards } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                        )}
                      </FormItem>
                    </Col>
                  </Row> 

                        */ } 

                    <Row type="flex" justify="space-around" align="top">
                      <Col span={22}>
                        <Divider />
                      </Col>
                    </Row>

                    <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                      <Col>
                        <FormItem>
                          <Button type="primary" htmlType="submit">
                          Save
                          </Button>
                        </FormItem>
                      </Col>
                    </Row>

                  </Form>

                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

const WrappedSettingsRefunds = Form.create()(SettingsRefunds);
export default WrappedSettingsRefunds;
