/**
 * Component for Variant-Exchanges settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import { Link } from "react-router-dom";
import { Select} from "antd";
const { Option } = Select;
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Divider,
  Form,
  Checkbox,
  message,
  Tooltip,
  Alert,
} from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";
import { trackFSEvent } from "../../../../helpers/fullstory";

const FormItem = Form.Item;

class SettingsExchanges extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      exchangesEnableVariants: undefined,
      exchangesEnableSamePrice: undefined,
      exchangesEnableOverselling: undefined,
      exchangeSameVariant: true,
      exchangesEnableVariantsWithLocations: false,
      inventoryLocationIds: [],
      shopifyInventoryLocations: []
    };
  }

  async componentDidMount() {
    this.getInventoryLocation();
    try {
      const response = await axios.get("/api/returnSettings");
      const data = response.data;

      if (!data) {
        return;
      }

      if (!data.inventoryLocationIds || Object.keys(data.inventoryLocationIds).length == 0) {
        data.inventoryLocationIds = [];
      }
      this.setState({
        ...data
      });
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  async getInventoryLocation() {
    try {
      const response = await axios.get("/api/returnSettings/getInventoryLocation");
      const shopifyInventoryLocations = response.data.response;
      this.setState({
        shopifyInventoryLocations
      });
    } catch(err) {
      console.log("Error retrieving your inventory location, Try again.")
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          "There is something wrong with the data you entered. Please check again.",
          5
        );
      }
      
      if(values.exchangesEnableVariantsWithLocations && this.state.inventoryLocationIds.length == 0 ) {
        return message.error(
          "Please select atleast one location.",
          5
        );
      }
      values.inventoryLocationIds = this.state.inventoryLocationIds;
      trackFSEvent("Edit Variant Exchanges", {
        feature: 'Exchanges',
        enableVariantExchanges: values.exchangesEnableVariants || this.state.exchangesEnableVariants || false,
        enableVariantExchangesWithLocations: values.exchangesEnableVariantsWithLocations || this.state.exchangesEnableVariantsWithLocations || false,
        forceOverselling: values.exchangesEnableOverselling || this.state.exchangesEnableOverselling || false,
        enableSamePriceExchangesOnly: values.exchangesEnableSamePrice || this.state.exchangesEnableSamePrice || false,
        enableVariantExchangesWithSameVariant: values.exchangeSameVariant || this.state.exchangeSameVariant ||false
      })
      try {
        const response = await axios.post("/api/returnSettings/update", values);
        if (response.data.status === "error") {
          return message.error(
            "Error updating your exchange settings. Please try again.",
            5
          );
        }
        this.setState({
          ...response.data,
        });
        return message.success("Return settings successfully updated.", 5);
      } catch (err) {
        return message.error(
          "Error updating your exchange settings. Please try again.",
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  selectLocation = (value) => {
    this.setState({
      inventoryLocationIds: value
    })
  }

  render() {
    let locationList = [];
    if(this.state.shopifyInventoryLocations) {
      locationList = this.state.shopifyInventoryLocations.map((location) => {
        const locationId = location.id;
        const locationName = location.name;
        return (
          <Option key={locationName} value={locationId}>
            {locationName}
          </Option>
        );
      });
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Variant Exchanges</h1>
            <p>Let customers exchange for another variant (e.g. size).</p>
          </Col>
        </Row>

        {this.context.featureExchangeSelection ? (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Alert
                  message="Important"
                  description={
                    <React.Fragment>
                      <span>
                          Make sure to enable the 'Exchange product'
                          option in the general{" "}
                        <Link to="/dashboard/settings">
                            Return-Settings tab
                        </Link>{" "}
                          or in the Advanced Rules.
                      </span>

                      <br/><br/>

                      <span>
                        Good to know:
                        <br/>
                        - Variant Exchanges are always assumed as even exchanges which means that the customer is not charged for a price difference
                        <br/>
                        - We are checking the live inventory levels for the selected product on your Shopify-store when a customer chooses a Variant-Exchange
                      </span>

                      <br/><br/>

                      <span>
                          Read{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.apphub.com/en/articles/6324979-how-is-inventory-handled-variant-exchanges-one-click-exchange-orders"
                        >
                            here in our Docs when and how inventory is handled.
                        </a>
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit}>
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Enable Variant Exchanges</h4>
                  <span>
                    This will show your customer variants from the same item
                    to choose from as an exchange. If this option is
                    disabled your customer will be presented with a
                    mandatory notes-field instead to fill out with details
                    on the exchange.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangesEnableVariants",
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangesEnableVariants}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                        <Tooltip
                          placement="bottom"
                          title="Using the product-variants will work for 99% of Shopify stores. If you have custom or unusual product-pages on your store with a multitude of product-options for the customer to select from then test the returns process after activating this option and verify it is displaying variants correctly or deactivate this option."
                        >
                          <strong> (Read requirements)</strong>
                        </Tooltip>
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              {this.state.exchangesEnableVariants && 
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  className="dashboard-content"
                >
                  <Col span={10}>
                    <h4>Enable Variant Exchanges with locations</h4>
                    <span>
                      This option will show products variants on the basis of shopify locations.
                    </span>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator(
                        "exchangesEnableVariantsWithLocations",
                        {}
                      )(
                        <Checkbox
                          checked={this.state.exchangesEnableVariantsWithLocations}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable
                        </Checkbox>
                      )}
                    </FormItem>

                    {
                      this.state.exchangesEnableVariantsWithLocations &&
                      <Select
                        mode="multiple"
                        placeholder="Please select locations"
                        showArrow={true}
                        value={this.state.inventoryLocationIds}
                        onChange={(value, key) => {
                          this.selectLocation(value)
                        }}
                        style={{ width: '100%' }}
                      >
                        {locationList}
                      </Select>
                    }
                  </Col>
                </Row>
              }
              
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Enable Variant Exchanges with same variant</h4>
                  <span>
                    This option will allow customer to exchange a product for a variant of the same size.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangeSameVariant",
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeSameVariant}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col span={10}>
                  <h4>Enable Same-Price exchanges only</h4>
                  <span>
                    Only allow variants with the same price. Other variants
                    will be disabled from selection.
                  </span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator(
                      "exchangesEnableSamePrice",
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangesEnableSamePrice}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
                <Col span={10}>
                  <h4>Force overselling</h4>
                  <span>By default we look at each product variant and check if the option "Continue selling when out of stock" is enabled in your Shopify store. Only then is a variant shown as being available when there is no inventory. Enabling 'Force overselling' will disregard this setting in Shopify and always show variants as available for exchange.</span>
                </Col>
                <Col span={10}>
                  <FormItem>
                    {getFieldDecorator('exchangesEnableOverselling', {
                    })(
                      <Checkbox checked={ this.state.exchangesEnableOverselling } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
            
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="dashboard-content"
              >
                <Col>
                  <FormItem>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </FormItem>
                </Col>
              </Row>

            </Form>
          </React.Fragment>
        ) : (
          <Upgrade
            videoId="LHXrc_e9lP0"
            title="Let customers select exchanges of another variant directly."
            description="Upgrade to the Pro Plan to enable your customers to make a choice for another variant based on your live inventory."
          />
        )}
      </React.Fragment>
    );
  }
}

const WrappedSettingsExchanges = Form.create()(SettingsExchanges);
export default WrappedSettingsExchanges;
