/**
 * Component for Advanced-Rule Conditions settings
 *
 * @component
 */

import React, { Component } from "react";
import { Row, Col, Select, Card, Typography, Checkbox } from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import RuleConditionSection from "./RuleConditionSection"

const { Option } = Select;
const { Text } = Typography;

class RuleCondition extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col md={12} xs={24}>
            <Card
              title={
                <React.Fragment>
                  <span>Rule Condition</span>
                  <Text type="secondary">
                    {" "}
                    - What has to be true for the rule to trigger?
                  </Text>
                </React.Fragment>
              }
              headStyle={{ textAlign: "center", backgroundColor: "#F8F8F8" }}
              style={{ marginBottom: 20 }}
            >
              <Row
                type="flex"
                align="top"
                style={{ marginBottom: 20, textAlign: "center", justifyContent: 'space-evenly' }}
              >
                <RuleConditionSection props={this.props} />
              </Row>
              <Row
                type="flex"
                align="top"
                style={{ marginBottom: 20, textAlign: "center", justifyContent: 'space-evenly' }}
              >
                <Col>
                  <Checkbox checked={this.props.selectedRule.enableMultipleRuleCondition} onChange={(e) => this.props.updateInput(e, "enableMultipleRuleCondition")}>Enable checkbox to add multiple conditions</Checkbox>
                </Col>

                {this.props.selectedRule.enableMultipleRuleCondition &&
                  <React.Fragment>
                    <Col>
                      <Select
                        defaultValue={
                          this.props.selectedRule?.secondaryConditionOperator
                            ? this.props.selectedRule.secondaryConditionOperator
                            : "AND"
                        }
                        style={{ marginBottom: 20 }}
                        onChange={(e) => this.props.updateInput(e, "secondaryConditionOperator")}
                      >
                        <Option value="AND">AND</Option>
                        <Option value="OR">OR</Option>
                      </Select>
                    </Col>
                    <RuleConditionSection props={this.props} secondary={true} />
                  </React.Fragment>

                }
              </Row>         
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default RuleCondition;
