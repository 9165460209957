import React, { Component } from "react";
import { Row, Col } from "antd";
import "../styles/login.css";

import LoginForm from "../pages/dashboard/login/login";

class LoginScreen extends Component {
  render() {
    return (
      <div id="loginScreen">
        <Row type="flex" justify="center">
          <Col>
            <div className="logo" />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <LoginForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginScreen;
