import React, { Component } from 'react'
import { Row, Col, Button, Tooltip, Badge } from 'antd'
class StripeWebhookDetails extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <React.Fragment>
        {this.props.paymentError ? (
          <React.Fragment>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
                <strong>Payment Error</strong>
              </Col>
              <Col span={15}>
                <Badge status="error" text="Stripe Webhook Error" />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}></Col>
              <Col span={15}>
                <Tooltip
                  placement="bottom"
                  title={this.props.returnObject.paymentErrorMessage}
                >
                  <Button size="small">View details</Button>
                </Tooltip>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Row
            type="flex"
            justify="start"
            align="top"
            className="editReturnDetailsRow"
          >
            <Col span={9}>
              <strong>{this.props.headingText}</strong>
            </Col>
            <Col span={15}>
              <Badge status="success" text={`${this.props.text}`} />
            </Col>
          </Row>
        )}
      </React.Fragment>
    )
  }
}
export default StripeWebhookDetails;