/**
 * Component for API settings page in the Dashboard.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col, Button, Divider, Alert, Popconfirm, Table, Input, Modal, message } from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import Upgrade from "../../../../components/Upgrade";
import WebhooksTable from './WebhooksTable'

class SettingsApi extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      keyName: undefined,
      apiKeys: undefined,
      token: undefined,
      visible: false,
    };
  }

  async componentDidMount() {
    this.loadApiKeys();
  }

  async loadApiKeys() {
    try {
      const response = await axios.get("/api/token");
      if (response.data && response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }
      this.setState({
        apiKeys: response.data,
      });
    } catch (err) {
      message.error("Error retrieving your API keys. Try again.", 5);
    }
  }

  handleSubmit = async () => {

    const { keyName } = this.state;
    const invalidKeyName = keyName == null || keyName === "";

    if (invalidKeyName) {
      return message.error("Please give the token a name to continue.", 5);
    }

    try {
      const response = await axios.post("/api/token", {
        keyName,
      });

      if (response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }

      const { token } = response.data;

      this.setState({
        keyName: undefined,
        token,
      });
      this.loadApiKeys();

      return message.success("API key created successfully.", 5);

    } catch (err) {
      return message.error("Error creating key. Please try again.", 5);
    }

  }

  deleteKey = async (id) => {

    try {
      const response = await axios.delete("/api/token", {
        data: {
          id,
        }
      });

      if (response.data && response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }

      this.loadApiKeys();

      return message.success("API Token deleted successfully.", 5);

    } catch (err) {
      return message.error("Error deleting API token. Please try again.", 5);
    }

  }

  updateKeyName = (e) => {
    const keyName = e.target.value;

    this.setState({
      keyName,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
      keyName: undefined,
      token: undefined,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      keyName: undefined,
      token: undefined,
    });
  };

  render() {

    return (
      <React.Fragment>
        <Row type="flex" justify="start" align="top">
          <Col>
            <h1 className="pageTitle">Return settings - API keys</h1>
            <p>Access and manage the Rich Returns API here to automate returns.</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={22}>
            <Divider />
          </Col>
        </Row>
        {
          this.context.featureApi
            ? (
              <React.Fragment>
                <Row type="flex" justify="center" align="top" style={{ marginBottom: 20 }}>
                  <Col>
                    {
                      this.state.apiKeys && this.state.apiKeys.length > 2
                        ? (
                          <Button
                            type="primary"
                            disabled={true}
                          >
                            Max. limit of keys reached
                          </Button>
                        )
                        : (
                          <Button
                            type="primary"
                            disabled={this.context.featureRulesQuota === 0 ? true : false}
                            onClick={this.showModal}
                          >
                            Create Secret Key
                          </Button>
                        )
                    }
                  </Col>
                </Row>

                {
                  this.state.visible
                    ? (
                      <Modal
                        title="Create a new API token?"
                        visible={this.state.visible}
                        onOk={this.handleSubmit.bind(this)}
                        okButtonProps={this.state.token ? { disabled: true } : { disabled: false }}
                        onCancel={this.handleCancel}
                        cancelText="Close"
                      >
                        {
                          this.state.token
                            ? (
                              <Alert
                                message="API Token created successfully"
                                description={
                                  <React.Fragment>
                                    <p>Write the token down in a secure place. You will not be able to access it later.</p>
                                    <p>API Token: {this.state.token}</p>
                                  </React.Fragment>
                                }
                                type="success"
                                showIcon
                              />
                            )
                            : (
                              <Input
                                addonBefore="Token Name"
                                placeholder="Give the token a name"
                                defaultValue={this.state.keyName}
                                onChange={this.updateKeyName.bind(this)}
                              />
                            )
                        }
                      </Modal>
                    )
                    : null
                }

                <Row type="flex" justify="center" align="top" style={{ textAlign: "center" }}>
                  <Col>
                    <Alert
                      message="Looking for documentation?"
                      description={
                        <span>We cover the API <a href="https://support.apphub.com/en/articles/6367662-introduction-to-our-api" target="_blank" rel="noopener noreferrer">
                          here in our Developer Docs
                        </a>
                        </span>
                      }
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="top">
                  <Col md={22} xs={24}>
                    <Divider />
                  </Col>
                </Row>

                <ApiKeysTable apiKeys={this.state.apiKeys} deleteKey={this.deleteKey.bind(this)} />

                {/* <Row type="flex" justify="center" align="top" style={{ marginTop: 20, marginBottom: 20 }}> */}
                <Divider />
                <h3>Webhooks</h3>

                <WebhooksTable />
                <Divider />
                <Row type="flex" justify="center" align="top" style={{ marginTop: 20, marginBottom: 20 }}>
                  <Col>
                    <p>
                      You should never expose your Live Token. PLEASE STORE SECURELY.
                    </p>
                  </Col>
                </Row>
              </React.Fragment>
            )
            : (
              <Upgrade
                title="Integrate the Rich Returns platform into your Systems."
                description="Choose a Plan with API access (Plus & Custom Plans) to build comprehensive automated post-purchase processes."
                devDocs={true}
              />
            )
        }
      </React.Fragment>
    );
  }
}


class ApiKeysTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.columns = [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: (text, record) => {
          return (
            `name-${record.id}`
          )
        },
        width: "25%"
      },
      {
        title: 'TOKEN',
        dataIndex: 'last4',
        key: (text, record) => {
          return (
            `token-${record.id}`
          )
        },
        render: (text, record) => {
          return (
            <span>****************{record.last4}</span>
          )
        },
        width: "25%"
      },
      {
        title: 'CREATED',
        dataIndex: 'createdAt',
        key: (text, record) => {
          return (
            `createdAt-${record.id}`
          )
        },
        render: (text, record) => {
          return (
            moment(record.createdAt).format("MMM DD, YYYY")
          )
        },
        width: "25%"
      },
      {
        title: 'DELETE',
        dataIndex: 'delete',
        key: (text, record) => {
          return (
            `delete-${record.id}`
          )
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete this token?"
              onConfirm={() => this.props.deleteKey(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          )
        },
        width: "25%"
      }
    ];
  }

  render() {
    return (
      <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
        <Col span={22}>
          <Table
            size="small"
            columns={this.columns}
            dataSource={this.props.apiKeys}
            rowKey={(record) => record.last4}
          />
        </Col>
      </Row>
    );
  }
}

export default SettingsApi;

