/**
 * Component for user's Subscription with Shopify Billing.
 * 
 * @component
 */

import React, { Component } from 'react';
import axios from "axios";
import { Button, Row, Col, Card, Icon, Divider, Rate, Checkbox, Progress, Tag, Alert, Slider, Typography, message, Anchor, Popover } from 'antd';
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
import PackageLicenses from '../../../../../components/PackageLicenses';
import moment from "moment";
import PlanOptions from '../../../integrations/plans/Plans';
const { Link } = Anchor;
const { Title } = Typography;

export class Plans extends Component {

  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      featureCustomerPhotoUpload: false,
      visible: false,
      plusPlanSelectedTier: undefined,
      durationLimitInIntervals: null,
      discountPercentage: undefined,
      plans: [], // (for new plans flow)
      checkedAddOns: [], // for checked Add On (for new plans flow)
      plusPlanPricing: {
        1000: {
          included: 1000,
          price: 199,
        },
        1500: {
          included: 1500,
          price: 299,
        },
        2000: {
          included: 2000,
          price: 399,
        },
        2500: {
          included: 2500,
          price: 499,
        },
        3000: {
          included: 3000,
          price: 599,
        },
        3500: {
          included: 3500,
          price: 699,
        },
        4000: {
          included: 4000,
          price: 799,
        },
        4500: {
          included: 4500,
          price: 899,
        },
        5000: {
          included: 5000,
          price: 999,
        },
      },
      plusPlanAddOnPricing: {
        1000: {
          included: 1000,
          price: 40,
        },
        1500: {
          included: 1500,
          price: 60,
        },
        2000: {
          included: 2000,
          price: 80,
        },
        2500: {
          included: 2500,
          price: 100,
        },
        3000: {
          included: 3000,
          price: 120,
        },
        3500: {
          included: 3500,
          price: 140,
        },
        4000: {
          included: 4000,
          price: 160,
        },
        4500: {
          included: 4500,
          price: 180,
        },
        5000: {
          included: 5000,
          price: 200,
        },
      },
    };
  }

  toggleVisibilityOfLicenseModal() {
    this.setState({ visible: !this.state.visible })
  }

  async componentDidMount() {
    this.getDiscount();
    this.getPlans(); // call function to fetch plans data
    this.setState({
      featureCustomerPhotoUpload: this.context.featureCustomerPhotoUpload,
    });
  }

  // Fetch plans relevant data from API
  async getPlans() {
    try {
      const response = await axios.get('/api/plans');
      const plans = response.data.plans;
      this.setState({
        plans,
      });
    } catch (err) {
      console.log("Error getting the plans. Please try again.");
    }
  }

  async getDiscount() {
    try {
      const response = await axios.get('/api/connections/discountPlan');
      this.setState({
        durationLimitInIntervals: response.data.durationLimitInIntervals,
        discountPercentage: response.data.discountPercentage ? response.data.discountPercentage : null
      });
    } catch (err) {
      console.log("Error retrieving the billing discount");
    }
  }

  async handleSubmit(e) {
    const selectedPlan = e.target.value;
    const selectedAddOn = this.state.featureCustomerPhotoUpload ? "addOnCustomerPhotoUpload" : null;
    const plusPlanSelectedTier = this.state.plusPlanSelectedTier;
    const response = await axios.post("/api/shop/shopify/billing/existing", {
      selectedPlan,
      selectedAddOn,
      plusPlanSelectedTier,
    });
    if (response.data.status === "error") {
      return message.error("Error updating your plan. Please try again or contact support (top right corner of your screen).")
    }
    return window.location.href = response.data.redirectUrl;
  }

  async handleSubmitNewPlanFlow(planId) {
    const addOns = this.state.checkedAddOns
      .filter(checkedAddOn => checkedAddOn.planId == planId)
      .map(checkedAddOn => checkedAddOn.addOnId);

    let planSelectedTier;
    this.state.plans.map((plan)=> {
      if (plan.id == planId) {
        planSelectedTier = plan.tier
      }
    });

    try {
      const response = await axios.post("/api/shop/shopify/billing/existing/newPlanFlow", {
        planId,
        addOns,
        planSelectedTier
      });

      if (response.data.status === "error") {
        return message.error("Error updating your plan. Please try again or contact support (top right corner of your screen).")
      }
      return window.location.href = response.data.redirectUrl;
    } catch (err) {
      message.error("Error updating your plan. Please try again or contact support.", 5)
    }
  }

  _onChangeAddOn = (planId, addOnId) => {
    let checkedAddOnsCopy = this.state.checkedAddOns;
    const condition = cond => cond.planId === planId && cond.addOnId === addOnId;

    const included = checkedAddOnsCopy.some(checkedAddon => condition(checkedAddon));
    if (included) {
      checkedAddOnsCopy = checkedAddOnsCopy.filter(checkedAddon => !condition(checkedAddon))
    } else {
      checkedAddOnsCopy.push({
        planId,
        addOnId
      });
    }

    this.setState({
      checkedAddOns: checkedAddOnsCopy
    });
  }

  onChangePlanTier = (tier, plan) => {

    const selectedPlan = this.state.plans.map((_plan) => {
      if (_plan.id == plan.id) {
        _plan.price =  plan.metadata.slider.steps[tier]
        _plan.tier =  tier
      } 
      return _plan;
    });

    this.setState({plans: [...selectedPlan]})

  }

  onChangeAddon = (e) => {
    const selectedAddOn = e.target.value;
    const addOnEnabled = e.target.checked;

    this.setState({
      [selectedAddOn]: addOnEnabled,
    });
  }

  onChangePlusPlanTier = (e) => {
    this.setState({
      plusPlanSelectedTier: e,
    });
  }

  planPrice = (subscriptionPrice) => {
    return (
      <React.Fragment>
        {this.state.discountPercentage
          ? (
            <React.Fragment>
              <s>${subscriptionPrice}</s>
              &nbsp;
              ${(subscriptionPrice - ((subscriptionPrice / 100) * this.state.discountPercentage * 100)).toFixed(2)}
            </React.Fragment>
          )
          : (
            <React.Fragment>
              ${subscriptionPrice}
            </React.Fragment>
          )
        } per month
      </React.Fragment>
    )
  }

  renderSelectablePlanAddons = (plan) => {
    const selectablePlanAddons = plan.PlanAddOns.filter(planAddOn => planAddOn.isSelectable);
    if (selectablePlanAddons.length === 0) {
      return null;
    }

    return (
      <Card title={`Optional Add-Ons for ${plan.name} Plan`} style={{ marginTop: 20 }} key={`card-plans-${plan.id}`}>
        <p>To add an Add-On to your plan click on enable and then click "Choose plan" on your desired plan. Shopify will handle the correct pro-rated billing for you.</p>
        {selectablePlanAddons.map((planAddOn) => (
          <React.Fragment key={`selectable-${plan.id}-addon-${planAddOn.id}`}>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title={this.context[planAddOn.AddOn.featureAddOnName] === true && plan.name === this.context.planName ?
                <Tag color="gold">Active</Tag> :
                null
              }>
              <Row>
                <Col span={2}>
                  <Popover
                    content={
                      <React.Fragment>
                        <p key={`PlanAddOn-${planAddOn.AddOn.id}`}>
                          <Icon type="check" /> {planAddOn.AddOn.description}
                        </p>
                        <Divider />
                        {planAddOn.AddOn.metadata && planAddOn.AddOn.metadata.features && planAddOn.AddOn.metadata.features.length > 0 &&
                          planAddOn.AddOn.metadata?.features.map((feature, index) => (
                            <p key={index}>
                              <Icon type="check" /> {feature}
                            </p>
                          ))
                        }
                        <Divider />
                        <p key={planAddOn.AddOn.id}>
                          <Icon type="check" /> {planAddOn.AddOn.footerNotes}
                        </p>
                      </React.Fragment>
                    }
                    style={{
                      maxWidth: '300px'
                    }}
                  >
                    <Icon type="info-circle" style={{ position: "relative", right: "8px" }} />
                  </Popover>
                </Col>
                <Col span={15}>
                  <span style={{ fontWeight: 'bold' }}>
                    {planAddOn.AddOn.name}
                  </span>
                </Col>
                <Col span={7}>
                  <Checkbox
                    onChange={() => this._onChangeAddOn(plan.id, planAddOn.AddOn.id)}
                    defaultValue={this.context[planAddOn.AddOn.featureAddOnName] === true && plan.name === this.context.planName}
                  >
                    Enable
                  </Checkbox>
                </Col>
                <Col span={24} offset={2}>
                  {`$ ${planAddOn.price ? planAddOn.price : planAddOn.AddOn.price}`}
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        ))}
      </Card>
    )
  }

  renderPlanSlider = (plan) => {
    if (!plan.metadata &&
      !plan.metadata.slider &&
      !plan.metadata.slider.step &&
      !plan.metadata.slider.min &&
      !plan.metadata.slider.max &&
      !plan.metadata.slider.marks) {
      return
    }
    return (
      <React.Fragment>
        <p>Returns included per month:</p>
        <Slider
          key={`plan-returns-slider-${plan.id}`}
          min={plan.metadata.slider.min}
          max={plan.metadata.slider.max}
          step={plan.metadata.slider.step}
          marks={plan.metadata.slider.marks}
          tipFormatter={(value) => `${value} returns`}
          onChange={(tier) => this.onChangePlanTier(tier, plan)}
        />
      </React.Fragment>
    )
  }

  renderPlans = (plans) => {
    if (!plans || plans.length === 0) return null;

    return (
      plans.map((plan, index) => (
        <Col span={8} key={`col-plans-${plan.id}`}>
          <Card
            title={
              <React.Fragment>
                <p>
                  <span>{plan.name}
                    {plan.name === this.context.planName &&
                      <Tag color="gold">Active</Tag>
                    }
                  </span>
                </p>
                <p>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.handleSubmitNewPlanFlow(plan.id)}
                  >
                    Choose plan<Icon type="right" />
                  </Button>
                </p>
              </React.Fragment>
            }
            hoverable={true}
          >
            {this.renderPlanSlider(plan)}
            <h1>
              {plan.tier || this.state.checkedAddOns.length > 0 ? `$ ${plan.price} + ` : `$ ${plan.price}`}
            </h1>
            {plan.metadata && plan.metadata.planUsage && <p>${plan.metadata.planUsage} per extra return</p>}
            <Divider />
            {plan.metadata && plan.metadata.features && plan.metadata.features.length > 0 && plan.metadata.features.map((feature, index) => (
              <p key={`plan-${plan.id}-features-${index}`}>
                <Icon type="check" /> {feature}
              </p>
            ))}


            {plan.PlanAddOns && plan.PlanAddOns.length > 0 && plan.PlanAddOns.map((planAddOn, index) => {
              return !planAddOn.isSelectable ?
                (
                  <p key={`plan-${plan.id}-addon-features-${index}`}>
                    <Icon type="check" /> {planAddOn.AddOn.name}
                  </p>
                ) : null

            })}
            <p>
              <Icon type="check" /> {plan.tier ? plan.tier : plan.monthlyQuota} returns included per month
            </p>
          </Card>
          {this.renderSelectablePlanAddons(plan)}
        </Col>
      ))
    )
  }

  render() {
    const marks = {
      1000: '1k',
      1500: '',
      2000: '2k',
      2500: '',
      3000: '3k',
      3500: '',
      4000: '4k',
      4500: '',
      5000: '5k',
    }
    const contextIntervalTime = this.context.intervalTime;
    const contextUsageQuota = contextIntervalTime === 'EVERY_30_DAYS' ? this.context.monthlyQuota : this.context.monthlyQuota * 12 || 0;
  
    let percentOfQuota = 0;
    if (contextUsageQuota === 0) {
      // this is for legacay plans with no monthly quota
      percentOfQuota = 100;
    } else if (this.context.usageChargeCount === 0) {
      percentOfQuota = 0;
    } else if (this.context.usageChargeCount > contextUsageQuota) {
      percentOfQuota = 100;
    } else {
      percentOfQuota = (this.context.usageChargeCount / contextUsageQuota * 100).toFixed(0);
    }
    // TODO: remove oldText
    const oldText = <p><strong>Your current plan: {this.context.planTitle} Plan</strong> {this.context.inTrial ? "(Free trial)" : `($${this.context.amountPerMonth} / month)`}</p>
    const newText = <p><strong>Your current plan: {this.context.planTitle} Plan</strong> {this.context.inTrial ? "(Free trial)" : `($${this.context.price} / ${this.context.intervalTime === 'EVERY_30_DAYS' ? 'month' : 'year'})`}</p>
    return (
      <div style={{ padding: '30px' }}>

        <Row type="flex" justify="space-around" align="top" style={{ textAlign: "center", paddingBottom: 30 }}>
          <Col span={18}>
            { this.context.intervalTime ? newText : oldText }
            <p>You have used {(this.context.usageChargeCount > contextUsageQuota) ? "all" : this.context.usageChargeCount} of your {contextUsageQuota} included returns in this billing period.</p>
            <p>Additional returns are billed at ${this.context.amountPerUsage} per return ({(this.context.usageChargeCount > contextUsageQuota) ? (this.context.usageChargeCount - contextUsageQuota) : 0} used). You can process a maximum of {parseInt(this.context.balanceRemaining) === 0 ? 0 : (this.context.balanceRemaining / this.context.amountPerUsage).toFixed(0)} additional returns in this billing period.</p>
            <p>
              <b>
                Next Billing On:  
                {
                  this.context.billingStartDate 
                    ? moment(this.context.billingStartDate).format("DD.MMM.YYYY")
                    : null
                }
              </b>
            </p>
            <Progress percent={parseFloat(percentOfQuota)} status="active" />
            {
              this.context.inTrial
                ? (
                  <Alert
                    message="You are currently on your 14-day free trial"
                    description="Note that on changing your plan during the free trial the billing cycle for the new plan will start immediately."
                    type="info"
                    showIcon
                    style={{ marginTop: 20 }}
                  />
                )
                : null
            }
            {
              parseInt(this.context.balanceRemaining) === 0
                ? (
                  <Alert
                    message="Your current plan has reached its limit for this billing period"
                    description="Customers cannot start new returns. Upgrade your plan to accomodate your monthly returns volume."
                    type="warning"
                    showIcon
                    style={{ marginTop: 20 }}
                  />
                )
                : null
            }
          </Col>
        </Row>

        <Divider />

        <PlanOptions existingPlan={true}/>
      </div>
    )
  }
}

export default Plans;
