import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Divider } from "antd";
import { Helmet } from "react-helmet";

import AuthRouter from "../../routes/Auth";
//styles
import "../../styles/App.css";
import Logo from '../../assets/Logo.png';

const { Content, Footer } = Layout;

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-129175672-1"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
      
            gtag('config', 'UA-129175672-1', { 'anonymize_ip': true });
          `}
          </script>
        </Helmet>
        <Layout>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: 600,
            }}
          >
            <span style={{ marginLeft: 30 }}>
              <Link to="/">
                <img src={Logo} style={{ maxHeight: 50 , maxWidth: 170}} />
              </Link>
            </span>
            <AuthRouter />
          </Content>
          <Footer className="mainFooter">
            <div>
              <a
                href="https://www.richcommerce.co/legal/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
              <Divider type="vertical" />
              <a
                href="https://www.richcommerce.co/imprint/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Imprint
              </a>
              <Divider type="vertical" />
              <a
                href="https://www.richcommerce.co/legal/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>
              <Divider type="vertical" />
              <a
                href="https://www.richcommerce.co/legal/acceptable-use-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Acceptable use
              </a>
            </div>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default Auth;
