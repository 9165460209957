import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios'
import App from './routes/App';
import * as Sentry from '@sentry/browser';
import { IntercomProvider } from 'react-use-intercom';


import 'antd/dist/antd.less';

if (process && process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  // error tracking
  Sentry.init({
    dsn: "https://de76d30cda4c4bde84e56d1effe0643d@sentry.io/1329457"
  });
}

const targetElement = document.getElementById('root');
const INTERCOM_APP_ID = 'honm34jo'

class AuthWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <App />
    )
  }
}

axios.interceptors.response.use(
  async response => {
    // Check if skipInterceptor option is true, then skip the logic
    if (response.config.skipInterceptor) {
      return response;
    }
    if (response.data.status === "unauthorized") {
      const currentUrl = (window.location).toString();
      if (currentUrl.includes("/error-404")) {
        return response;
      }
      if (!currentUrl.includes("/login")) {
        return window.location = '/login';
      }
    } else if (response.data.subscription === "inactive-apphub-subscripton") {
      return window.location = `/dashboard/connect/apphub/signin?subscriptionPending=true&origin_app=${response.data.originApp}`;
    } else if (response.data.subscription === "inactive-shopify") {
      // ADDING: redirect to "select a plan page"
      return window.location = "/dashboard/connect/plan";
    } else {
      return response;
    }
  }, error => {
    return Promise.reject(error);
  });

ReactDOM.render(
  <BrowserRouter>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <AuthWrapper />
    </IntercomProvider>
  </BrowserRouter>,
  targetElement
);

if (module.hot) {
  module.hot.accept();
}
