/**
 * Component for our Automatic Shipping-Labels
 * for users to view, track and resend them.
 * 
 * @component
 */

import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm,
  Dropdown,
  Icon,
  Menu,
  Badge,
  message,
} from "antd";
import EditCustomerDetails from "./ReturnDetailsCustomer";
import axios from "axios";

class ReturnDetailsLabelAutomatic extends Component {
  constructor(props) {
    super(props);
    this.state= {
      loadingRecreateLabel: false,
    }
  }
  recreatePrepaidLabel = async (returnObject) => {
    try {
      this.setState({
        loadingRecreateLabel: true,
      });

      const response = await axios.post("/api/returns/recreateLabel", {
        returnObject,
      });

      if (response.data.status === "error") {
        this.setState({
          loadingRecreateLabel: false,
        });

        return message.error("Error while attempting to create a new label. Try again.", 4);
      }

      this.setState({
        loadingRecreateLabel: false,
      });
      this.props.handleGetReturn()

      return message.success("Label created successfully.", 5);

    } catch(err) {
      this.setState({
        loadingRecreateLabel: false,
      });
      return message.error("Error while attempting to create a new label. Try again.", 2);
    }
  }

  resendLabelEmail = async (returnObject) => {
    const returnId = returnObject.id;
    try {
      const response = await axios.post("/api/returns/resendLabelEmail", {
        returnId,
      });

      const errorStatus = response.data.status === "error" ? true : false;
      if (errorStatus) {
        return message.error("There was an error resending the label. Please check the error message and try again.", 5);
      }

      return message.success("Label sent successfully.", 5);
    } catch(err) {
      return message.error("There was an error resending the label. Please check the error message and try again.", 5);
    }
  }
  render() {
    const menu= (
      <Menu >
        <Menu.Item key="1">
          <a
            href={this.props.returnObject.labelLabelUrl}
            target="_blank">Printable Label
          </a>
        </Menu.Item>
        { this.props.returnObject.labelCommercialInvoiceUrl ?
          <Menu.Item key="2">
            <a
              href={this.props.returnObject.labelCommercialInvoiceUrl}
              target="_blank">Show commercial invoice
            </a>
          </Menu.Item> :
          null
        }
        { this.props.returnObject.labelQRcodeUrl ?
          <Menu.Item key="3">
            <a
              size="small"
              href={this.props.returnObject.labelQRcodeUrl}
              target="_blank"
            >
              QR-Code Label
            </a>
          </Menu.Item> :
          null
        }
      </Menu>
    )
    return(
      <React.Fragment>
        { this.props.returnObject.labelStatus === "SUCCESS" ?
          (
            <React.Fragment>
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                  <strong>Prepaid-label</strong>
                </Col>
                <Col span={15}>
                  <Badge status="success" text="Label created & sent to customer" />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                </Col>
                <Col span={15}>
                  { this.props.returnObject.labelCommercialInvoiceUrl || this.props.returnObject.labelQRcodeUrl ?
                    <Dropdown overlay={menu}>
                      <Button size="small">
                        Download <Icon type="down" />
                      </Button>
                    </Dropdown>
                    :
                    <Button
                      size="small"
                      href={this.props.returnObject.labelLabelUrl}
                      target="_blank">Show label
                    </Button>
                  }
                  {" "}
                  <Button
                    size="small"
                    href={this.props.returnObject.labelTrackingUrlProvider}
                    target="_blank"
                  >
                    Track shipment
                  </Button>
                  {" "}
                  <Popconfirm
                    title="Resend your default email template to the customer that includes the prepaid-label?"
                    onConfirm={() => this.resendLabelEmail(this.props.returnObject)}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button size="small">Resend label</Button>
                  </Popconfirm>
                </Col>
              </Row>
            </React.Fragment>
          ) :

          null
        }
        { this.props.returnObject.labelStatus === "ERROR"
          ? (
            <React.Fragment>
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                  <strong>Prepaid-label</strong>
                </Col>
                <Col span={15}>
                  <Badge status="error" text="Error creating label" />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="start"
                align="top"
                className="editReturnDetailsRow"
              >
                <Col span={9}>
                </Col>
                <Col span={15}>
                  <Tooltip
                    placement="bottom"
                    title={
                      this.props.returnObject.labelErrorTransactionSource
                        ?
                        this.props.returnObject.labelErrorTransactionSource
                        +
                        ": "
                        +
                        this.props.returnObject.labelErrorTransactionText
                        +
                        " (label could not be created & sent to customer)"
                        :
                        this.props.returnObject.labelErrorTransactionText
                        +
                        " (label could not be created & sent to customer)"
                    }
                  >
                    <Button size="small">View details</Button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title="This will attempt to generate a new label and send the Approved-Email including the label to the customer"
                  >
                    <Popconfirm
                      title="Create a new label and send it to the customer?"
                      onConfirm={() => this.recreatePrepaidLabel(this.props.returnObject)}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <Button
                        style={{ marginLeft: 5, marginRight: 5 }}
                        size="small"
                        disabled={this.state.loadingRecreateLabel}
                        loading={this.state.loadingRecreateLabel}
                      >
                        Create new label
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                  <EditCustomerDetails
                    buttonType="default"
                    buttonText="Update address"
                    returnObject={this.props.returnObject}
                    handleGetReturn={this.props.handleGetReturn}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )
          : null
        }
        { this.props.returnObject.labelRateAmount
          ? (
            <Row
              type="flex"
              justify="start"
              align="top"
              className="editReturnDetailsRow"
            >
              <Col span={9}>
              </Col>
              <Col span={15}>
                Actual label cost: { `${this.props.returnObject.labelRateAmount} ${this.props.returnObject.labelRateCurrency}` }
              </Col>
            </Row>
          )
          : null
        }
      </React.Fragment>
    )
  }
}

export default ReturnDetailsLabelAutomatic;