/**
 * Component to detect the language.
 *
 * @component
 */

import React, { Component } from 'react';
import axios from 'axios';

// i18n
import { IntlProvider } from 'react-intl';
// setup i18n
import messages_de from '../translations/de.json';
import messages_da from '../translations/da.json';
import messages_en from '../translations/en.json';
import messages_es from '../translations/es.json';
import messages_fr from '../translations/fr.json';
import messages_fi from '../translations/fi.json';
import messages_hi from '../translations/hi.json';
import messages_hr from '../translations/hr.json';
import messages_it from '../translations/it.json';
import messages_pl from '../translations/pl.json';
import messages_nl from '../translations/nl.json';
import messages_no from '../translations/no.json';
import messages_sl from '../translations/sl.json';
import messages_sv from '../translations/sv.json';
import messages_tr from '../translations/tr.json';
import { availableTranslations } from '../constants/constants';
import { getLocale, setLocale } from '../helpers/localePortal';
import { TranslateContext } from '../contexts/TranslationContext';
// ATTENTION: no default language loaded when not available
let messages = {
  de: messages_de,
  da: messages_da,
  en: messages_en,
  es: messages_es,
  fr: messages_fr,
  fi: messages_fi,
  hi: messages_hi,
  hr: messages_hr,
  it: messages_it,
  nl: messages_nl,
  no: messages_no,
  pl: messages_pl,
  sl: messages_sl,
  sv: messages_sv,
  tr: messages_tr,
};

let language;
class ExternalPortalLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language,
    };
  }
  static contextType = TranslateContext;

  getSettingLanguage = async (locale) => {
    const url = window.location.href;
    const urlObject = new URL(url);
    const companyIdentifier = urlObject.searchParams.get('c');
    const workspace = urlObject.searchParams.get('workspace');

    language = locale || getLocale();
    try {
      const response = await axios.post('/api/visitors/settings/language', {
        companyIdentifier,
        workspace,
        language,
      });
      const errorStatus = response.data.status === 'error' ? true : false;
      if (errorStatus) {
        // TODO: error tracking
      }
      const {
        customTranslationsEnabled,
        customTranslations,
        enableTranslation,
      } = response.data;
      return {
        customTranslationsEnabled,
        customTranslations,
        enableTranslation,
      };
    } catch (err) {
      // TODO: error tracking
    }
  };

  componentDidMount = async () => {
    const { customTranslationsEnabled, customTranslations, enableTranslation } =
      await this.getSettingLanguage();
    if (customTranslationsEnabled) {
      messages = {...messages, ...customTranslations};
    }
    if (enableTranslation) {
      this.context.setEnableTranslation(enableTranslation);
    }
    this.props.handleCloseLoader();
  };

  async componentWillReceiveProps(nextProps) {
    // Hadle logic update language
    if (
      nextProps.language !== this.state.language
    ) {
      setLocale(nextProps.language);
      const { customTranslationsEnabled, customTranslations } =
        await this.getSettingLanguage(nextProps.language);
      if (customTranslationsEnabled) {
        messages = {...messages, ...customTranslations};
      }
      this.setState({ language: nextProps.language });
    }

    const title = 'app.visitor.page.metaTitle';
    if (messages[language] && messages[language][title]) {
      document.title = messages[language][title];
    } else {
      if (this.props.brandName) {
        document.title = this.props.brandName + ' | Returns';
      } else {
        document.title = 'Returns';
      }
    }
  }

  render() {
    const language = this.state.language;
    return (
      <React.Fragment>
        <IntlProvider locale={language} messages={messages[language]}>
          {this.props.children}
        </IntlProvider>
      </React.Fragment>
    );
  }
}

export default ExternalPortalLanguage;
